//Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    history?: any
    t: (val: string) => string;
}

interface S {
    enableField: boolean;
    openteammember: boolean;
    opendashboard: boolean;
    opencomplexBuilding: boolean;
    openrealEstateCompanies: boolean;
    opencommunityManagement: boolean;
    openactivities: boolean;
    openmanageFAQ: boolean;
    opennominations: boolean;
    openleasemanagement: boolean;
    openmanageSub: boolean;
    openclientTicket: boolean;
    opendocuments: boolean;
    selectedIndex: any;
}
interface SS {
    id: any;

}
export default class CECOOSidebarController extends CommonApiCallForBlockComponent<Props, S, SS> {
    location: string = !window.location.pathname.toLowerCase().includes("comingsoon") ? window.location.pathname.split('/')[2] : ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            enableField: false,

            openrealEstateCompanies: this.checkPath(['realestate-companies', 'realestate-register-company', 'realestate-company-details', 'realestate-edit-company', 'ComplexMainBlock', 'BuildingMainBlock']),
            opencommunityManagement: this.checkPath(['communityManagementProfile',"RequestManagement","TotalSentInvite", "PendingJoinRequest", "AwaitAccept"]),
            openactivities: this.checkPath(['cooincidentListing','CompEmpAnnouncement', 'cooincident-details', 'CEMeetingMinutes', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList', 'CEMeetingDetail', 'CEPollSurveyDetails', 'CEPollSurveyReports', 'CEScheduleMeeting']),
            openmanageFAQ: this.checkPath(['CompEmpFaq']),
            opennominations: this.checkPath(['CENomination','CENominationDetails']),
            opendashboard: this.checkPath(['Dashboard','jurisdication', 'ViewJurisdiction', 'CEDashboardAnalytics', 'DashboardsGranted', "ActionAssignMe", "ActionAssignTeam"]),
            openteammember: this.checkPath(['CEMyTeam', 'CEMyTeamMember']),
            opencomplexBuilding: this.checkPath(['ComplexListing', 'ComplexBuildingMainBlock', 'ComplexDetails', 'complex', 'UnitDetails', 'BuildingDetailsPage']),
            openleasemanagement: this.checkPath(['LeaseManagment', 'IssueNewLease', "IssueNewContract", "IssueNewContractTemplateDetails", "IssueNewContractReview", "IssueNewContractAddTerm", "IssueNewContractForm", "ReviewDraft", "ContractDetails", "DraftContractDetails"]),
            openmanageSub: this.checkPath(['SubsciptionListing', 'SubsciptionDetails','AddSubscription']),
            openclientTicket: false,            
            opendocuments: this.checkPath(['CompEmpDocument', 'ReportsStatistics', 'LoginReports', 'InvitationReport', "LeaseReport", 'PropertyManagerReport', 'PropertyManagerReportDetails','SubscriptionReports']),
            selectedIndex: "",
        };

        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    checkPath = (path: string[]) => path.map((item) => item.toLowerCase()).includes(window.location.pathname.split('/')[2].toLowerCase());

    isActiveMenu = (path: string[]) => this.location ? path.map(pathItem => pathItem.toLowerCase()).includes(this.location.toLowerCase()) : false;

    getTabData = (name: string) => {
        
        switch(name){
            case 'LeaseManagment':
                return (this.isActiveMenu(['LeaseManagment','IssueNewLease',"IssueNewContract","IssueNewContractTemplateDetails","IssueNewContractReview","IssueNewContractAddTerm","IssueNewContractForm","ReviewDraft","ContractDetails","DraftContractDetails","SavedTemplateDetails"]) || this.state.openleasemanagement);
            case 'dashboard':
                return (this.isActiveMenu(['Dashboard', 'jurisdication', 'ViewJurisdiction', 'CEDashboardAnalytics', 'DashboardsGranted']) || this.state.opendashboard);
            case 'document':
                return (this.isActiveMenu(['CompEmpDocument', 'ReportsStatistics', 'LoginReports', 'InvitationReport', "LeaseReport", 'PropertyManagerReport','SubscriptionReports']) || this.state.opendocuments);
            case 'team':
                return (this.isActiveMenu(['CEMyTeam', 'CEMyTeamMember']) || this.state.openteammember);
            case 'complex':
                return (this.isActiveMenu(['ComplexListing', 'ComplexBuildingMainBlock', 'ComplexDetails', 'complex', 'UnitDetails']) || this.state.opencomplexBuilding);
            case 'faq':
                return (this.isActiveMenu(['CompEmpFaq']) || this.state.openmanageFAQ);
            case 'subscription':
                return (this.isActiveMenu(['SubsciptionListing', 'SubsciptionDetails','AddSubscription']) || this.state.openmanageSub);
            case 'realEstate':
                return (this.isActiveMenu(['realestate-companies', 'realestate-register-company', 'realestate-company-details', 'realestate-edit-company', 'ComplexMainBlock', 'BuildingMainBlock']) ||this.state.openrealEstateCompanies)
            case 'activities':
                return (this.isActiveMenu(['cooincidentListing','CompEmpAnnouncement', 'cooincident-details', 'CEMeetingMinutes', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList', 'CEMeetingDetail', 'CEPollSurveyDetails', 'CEPollSurveyReports']) || this.state.openactivities);
            case 'activities':
                return (this.isActiveMenu(["userProfile"]) || this.state.opencommunityManagement);
            case 'nomination':
                return (this.isActiveMenu(['CENomination','CENominationDetails']) || this.state.opennominations);
            default:
                return false;
        }  
    }
    
    HandleDashboardClick = () => {
        this.setState({ selectedIndex: "", opendashboard: !this.state.opendashboard, openteammember: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })
    }

    HandleteamMember = () => {
        this.setState({ selectedIndex: "", openteammember: !this.state.openteammember, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })

    }
    HandlecomplexBuilding = () => {
        this.setState({ selectedIndex: "", opencomplexBuilding: !this.state.opencomplexBuilding, openteammember: false, opendashboard: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })
    }
    HandlerealEstateCompany = () => {
        this.setState({ selectedIndex: "", openrealEstateCompanies: !this.state.openrealEstateCompanies, openteammember: false, opendashboard: false, opencomplexBuilding: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })
    }

    Handlecommunity = () => { this.setState({ selectedIndex: "", opencommunityManagement: !this.state.opencommunityManagement, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    Handleactivities = () => { this.setState({ selectedIndex: "", openactivities: !this.state.openactivities, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    HandledocumentReport = () => { this.setState({ selectedIndex: "", opendocuments: !this.state.opendocuments, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, }) }
    Handlenominations = () => { this.setState({ selectedIndex: "", opennominations: !this.state.opennominations, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }

    HandlemanageFAQ = () => { this.setState({ selectedIndex: "", opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: !this.state.openmanageFAQ, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    Handlelease = () => { this.setState({ selectedIndex: "", opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: !this.state.openleasemanagement, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    Handlemanagesub = () => { this.setState({ selectedIndex: "", openmanageSub: !this.state.openmanageSub, opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openclientTicket: false, opendocuments: false, }) }
    Handleclient = () => { this.setState({ selectedIndex: "", opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openmanageSub: false, openclientTicket: !this.state.openclientTicket, opendocuments: false, }) }
    getColor1 = (isOpen: any) => (isOpen ? "#2b6fed" : "#000000");
   
    HandleItemClick = (index: any) => {
        this.setState({
            selectedIndex: index,
        });
    };
}
//Customizable Area End


