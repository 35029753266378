// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string;
  navigation:typeof navigation
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  generateContractModal: boolean;
  saveTemplateModal: boolean;
  activateContract: boolean;
  showError:boolean;
  error:string;
  showSuccessMessage:boolean;
  successMessage:string;
  createContractPayloadData:CreateContractPayloadDataItem;
  templateDetails:TemplateItem
  tenantName: string;
  downloadContractAlert: boolean;
  downloadContractMessage: string;
  // Customizable Area End
}

// Customizable Area Start
export interface CreateContractPayloadDataItem {
  templateId: string;
  country: string;
  city: string;
  complex: string;
  buildingName: string;
  unitName: string;
  complexName: string;
  tenant_name: TenantNameInterface;
  landlord_name: string;
  building_management_id: string;
  society_management_id: string;
  tenant_id: string;
  expires_on: string;
  apartment_management_id: string;
  agreement_duration: string;
  start_date: string;
  term_ids: Array<number>;
  lease_template_id: string;
  rent_amount: string;
  currency: string;
  condition_ids: Array<number>;
  custom_contract: boolean;
  penanlty_late_payment: boolean;
  contract_template: string;
  owner:string|null;
  ownerNumber: string | null;
  ownerEmail: string | null;
  accountId: Account_id;
  status: string;
  contractLink: string;
};

type TenantNameInterface = string | number | null
type Account_id = string | number | null

export interface ApiPayloadType {
  endPoint: string;
  method: string;
  baseURL?: string;
  body?: object;
  contentType?: string;
  type?: string;
};

export interface GetCreateContractResponse{
  code: number,
  contract: {
      data: {
          id: string,
          type: string,
          attributes: {
              apartment_management_id: number,
              accountId: null,
              building_management_id: number,
              society_management_id: number,
              landlord_name: string,
              country: null,
              region: null,
              city: null,
              agreement_duration: string,
              start_date: string,
              rent_amount: number,
              currency: string,
              expires_on: string,
              state: null,
              tenant_name: string,
              duration: null,
              lease_template_id: number,
              status: string,
              penanlty_late_payment: boolean,
              custom_contract: boolean,
              contract_template: string,
              created_at: string,
              updated_at: string,
              owner_name: null,
              owner: null,
              contract_template_pdf: {
                  url: string
              },
              lease_template_pdf: {
                  url: string
              },
              lease_template_text: string,
              contract_type: null,
              expire_on: number,
              building_name: string,
              society_management: {
                  id: number,
                  name: string,
                  description: string,
                  created_at: string,
                  updated_at: string,
                  complex_area: string,
                  maintenance_per_square_feet: number,
                  measurement_unit: string,
                  ticket_days_configuration: number,
                  contigency: number,
                  zone_multiplier: number,
                  late_charge_percentage: string,
                  latitude: null,
                  longitude: null,
                  value_added_tax: string,
                  is_building: boolean,
                  real_estate_company_id: null,
                  total_floor: null,
                  total_unit: null,
                  currency_id: null,
                  account_manager_id: null,
                  language: null,
                  status: null
              },
              unit_name: string,
              terms: [
                  {
                      id: number,
                      text: string,
                      created_at: string,
                      updated_at: string,
                      text_ar: string
                  }
              ],
              conditions: [
                  {
                      id: number,
                      text: string,
                      created_at: string,
                      updated_at: string,
                      text_ar: string
                  }
              ],
              custom_term_condition: null,
              custom_term_condition_added: boolean,
              tenant: {
                  id: number,
                  first_name: null,
                  last_name: null,
                  full_phone_number: string,
                  country_code: null,
                  phone_number: null,
                  email: string,
                  activated: boolean,
                  device_id: null,
                  unique_auth_id: null,
                  password_digest: string,
                  created_at: string,
                  updated_at: string,
                  user_name: null,
                  platform: null,
                  user_type: null,
                  app_language_id: null,
                  last_visit_at: null,
                  is_blacklisted: boolean,
                  suspend_until: null,
                  status: string,
                  stripe_id: null,
                  stripe_subscription_id: null,
                  stripe_subscription_date: null,
                  role_id: null,
                  full_name: string,
                  gender: string,
                  date_of_birth: null,
                  age: null,
                  is_paid: boolean,
                  company_name: null,
                  manager_full_name: null,
                  owner_full_name: null,
                  owner_email: null,
                  owner_phone_number: null,
                  public_field: null,
                  disable_chat: boolean,
                  building_management_id: null,
                  language_type: null,
                  ticket_days_configuration: number
              },
              penanlty_late_payments: null,
              custom_contract_image: null
          }
      }
  },
  message: string
};

export interface SaveContractAsDraftSuccess {
  contract: {
    data: {
      id: string;
      type: string;
      attributes: {
        apartment_management_id: number;
        account_id: number;
        building_management_id: number;
        society_management_id: number;
        landlord_name: string;
        country: string | null;
        region: string | null;
        city: string | null;
        agreement_duration: string;
        start_date: string;
        rent_amount: number;
        currency: string;
        state: string | null;
        tenant_name: string;
        duration: string | null;
        lease_template_id: number;
        status: string;
        penanlty_late_payment: boolean;
        custom_contract: boolean;
        contract_template: string;
        created_at: string;
        updated_at: string;
        owner_name: string | null;
        owner: {
          id: number;
          last_name: string | null;
          first_name: string | null;
          country_code: string | null;
          full_phone_number: string;
          email: string;
          phone_number: number | null;
          device_id: string | null;
          activated: boolean;
          password_digest: string;
          unique_auth_id: string | null;
          updated_at: string;
          created_at: string;
          platform: string | null;
          user_type: string | null;
          user_name: string | null;
          last_visit_at: string | null;
          app_language_id: number | null;
          suspend_until: string | null;
          is_blacklisted: boolean;
          stripe_id: string | null;
          status: string;
          stripe_subscription_date: string | null;
          age: number | null;
          stripe_subscription_id: string | null;
          full_name: string;
          role_id: number | null;
          date_of_birth: string | null;
          gender: string | null;
          is_paid: boolean;
          manager_full_name: string | null;
          company_name: string | null;
          owner_email: string | null;
          owner_full_name: string | null;
          public_field: string | null;
          owner_phone_number: string | null;
          ticket_days_configuration: number;
          disable_chat: boolean;
          building_management_id: number | null;
          language_type: string | null;
        };
        contract_template_pdf: {
          url: string;
        };
        lease_template_pdf: {
          url: string;
        };
        lease_template_text: string;
        contract_type: string;
        expire_on: number;
        building_name: string;
        society_management: {
          id: number;
          name: string;
          description: string;
          created_at: string;
          updated_at: string;
          complex_area: string;
          maintenance_per_square_feet: number;
          measurement_unit: string;
          ticket_days_configuration: number;
          contigency: number;
          zone_multiplier: number;
          late_charge_percentage: string;
          latitude: number | null;
          longitude: number | null;
          value_added_tax: string;
          is_building: boolean;
          real_estate_company_id: number | null;
          total_floor: number | null;
          total_unit: number | null;
          currency_id: number | null;
          account_manager_id: number | null;
          language: string | null;
          status: string | null;
        };
        unit_name: string;
        terms: string | null;
        conditions: string | null;
        custom_term_condition: string | null;
        custom_term_condition_added: boolean;
        tenant: {
          id: number;
          first_name: string | null;
          last_name: string | null;
          full_phone_number: string;
          country_code: string | null;
          phone_number: number | null;
          email: string;
          activated: boolean;
          device_id: string | null;
          unique_auth_id: string | null;
          password_digest: string;
          created_at: string;
          updated_at: string;
          user_name: string | null;
          platform: string | null;
          user_type: string | null;
          app_language_id: number | null;
          last_visit_at: string | null;
          is_blacklisted: boolean;
          suspend_until: string | null;
          status: string;
          stripe_id: string | null;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          role_id: number | null;
          full_name: string;
          gender: string | null;
          date_of_birth: string | null;
          age: number | null;
          is_paid: boolean;
          company_name: string | null;
          manager_full_name: string | null;
          owner_full_name: string | null;
          owner_email: string | null;
          owner_phone_number: string | null;
          public_field: string | null;
          disable_chat: boolean;
          building_management_id: number | null;
          language_type: string | null;
          ticket_days_configuration: number;
        };
        penanlty_late_payments: string | null;
        custom_contract_image: string | null;
      };
    };
  };
  code: number;
  message: string;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  errors:Array<ApiFailureResponseErrorItem>;
};

export interface ApiFailureResponseErrorItem {
  errors:string;
}

export interface ApiFailureErrorResponse {
  errors:string;
};

export interface ApiFailureResponseError{
  contract:string
}

type ApiFailureRespnseType = ApiFailureResponse & ApiFailureResponseError & ApiFailureErrorResponse

export interface TemplateItem{
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      title: string,
      title_ar: string,
      description: string,
      template_type_id: string,
      template: string,
      template_ar: string,
      template_pdf: {
        url: string
      }
    }
  }
}
export interface TemplatesListApiSuccessResponse{
  data: TemplateItem
}

export type TFunction = (value: string) => string;

export interface CreateContractfailureResponse {
  errors: []
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  templateDetailsApiCallId : string = "";
  saveContractTemplate : string = "";
  saveContractAsDraft : string = "";
  createContractApiCallId : string = "";
  saveContractAsDraftApiCallId : string = ""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      generateContractModal: false,
      saveTemplateModal: false,
      activateContract: false,
      showError:false,
      error:"",
      showSuccessMessage:false,
      successMessage:"",
      templateDetails:{
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            title: "",
            title_ar: "",
            description: "",
            template_type_id: "",
            template: "",
            template_ar: "",
            template_pdf: {
              url: ""
            }
        }
        }
      },
      tenantName: "",
      createContractPayloadData: {
        templateId: "",
        complex: "",
        city: "",
        unitName: "",
        buildingName: "",
        society_management_id: "",
        complexName: "",
        tenant_name: "",
        country: "",
        landlord_name: "",
        building_management_id: "",
        apartment_management_id: "",
        tenant_id: "",
        expires_on: "",
        term_ids: [],
        agreement_duration: "",
        start_date: "",
        custom_contract: false,
        lease_template_id: "",
        rent_amount: "",
        currency: "",
        condition_ids: [],
        penanlty_late_payment: false,
        owner: "",
        ownerNumber: "",
        contract_template: "",
        accountId: "",
        ownerEmail: "",
        status: "create",
        contractLink: ""
      },
      downloadContractAlert: false,
      downloadContractMessage: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.reviewContractResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.reviewContractResponseFailureCall(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractdata = message.getData(getName(MessageEnum.CreateContractPayloadData));
      if (createContractdata) {
        this.setState({ createContractPayloadData: createContractdata }, () => {
          this.getContractTemplateDetails(this.state.createContractPayloadData.lease_template_id);
        })
      }
    }
  }

  reviewContractApiCall = async (data: ApiPayloadType) => {
    let { method, endPoint, body, contentType } = data;
    let token = await localStorage.getItem("loginSuccessToken")
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    let leaseManagmentMainrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
      leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(leaseManagmentMainrequestMessage.id, leaseManagmentMainrequestMessage);
    return leaseManagmentMainrequestMessage.messageId;
  };

  reviewContractResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson:TemplateItem & GetCreateContractResponse & SaveContractAsDraftSuccess) => {
      if (apiRequestCallId === this.templateDetailsApiCallId) {
        this.contractTemplateDetailsSucessCallBack(responseJson);
      }
    if (apiRequestCallId === this.createContractApiCallId) {
      this.createContractSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.saveContractTemplate) {
      this.saveCreateContractSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.saveContractAsDraftApiCallId) {
      this.saveContractAsDraftSucessCallBack(responseJson);
    }

  };

  reviewContractResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureRespnseType) => {
    if (apiRequestCallId === this.templateDetailsApiCallId) {
      this.contractTemplateDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.createContractApiCallId) {
      this.createContractFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.saveContractTemplate) {
      this.saveCreateContractFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.saveContractAsDraftApiCallId) {
      this.ssaveContractAsDraftFailureCallBack(responseJson);
    }
  };

  getContractTemplateDetails = async (contractId: string) => {
    this.templateDetailsApiCallId = await this.reviewContractApiCall({
      method: configJSON.templateListMethod,
      endPoint: `${configJSON.templateListEndPoint}/${this.state.createContractPayloadData.lease_template_id}`,
    });
  };

  contractTemplateDetailsSucessCallBack = (response: TemplateItem) => { 
        this.setState({
      templateDetails: response, createContractPayloadData: this.state.createContractPayloadData});
  };


  contractTemplateDetailsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };
  
  createContract = async () => {
    let formdata: FormData = new FormData();
    let updateContractFormData :FormData = new FormData();    
    let endpoint = this.state.createContractPayloadData.status === "renew"? `${configJSON.updateContractExpiry}${this.state.createContractPayloadData.lease_template_id}` : configJSON.createContractEndPoint;
    let method = this.state.createContractPayloadData.status === "renew"? configJSON.terminateContractMethod : configJSON.inviteMemberMethod;
    let payload = this.state.createContractPayloadData.status === "renew"? updateContractFormData : formdata;
    updateContractFormData.append("contract[expires_on]", this.state.createContractPayloadData.expires_on);
    formdata.append("[contract][apartment_management_id]", this.state.createContractPayloadData.apartment_management_id);
    formdata.append("[contract][society_management_id]", this.state.createContractPayloadData.society_management_id);
    formdata.append("[contract][building_management_id]", this.state.createContractPayloadData.building_management_id);
    formdata.append("[contract][agreement_duration]", this.state.createContractPayloadData.agreement_duration);
    formdata.append("[contract][tenant_name]", this.state.createContractPayloadData.tenant_name as string);
    formdata.append("[contract][rent_amount]", this.state.createContractPayloadData.rent_amount);
    formdata.append("[contract][tenant_id]", this.state.createContractPayloadData.tenant_id);
    formdata.append("[contract][start_date]", this.state.createContractPayloadData.start_date)
    formdata.append("[contract][landlord_name]", this.state.createContractPayloadData.landlord_name);
    formdata.append("[contract][expires_on]", this.state.createContractPayloadData.expires_on);
    formdata.append("[contract][custom_contract]", String(false));
    formdata.append("[contract][term_ids][]", String(this.state.createContractPayloadData.term_ids));
    formdata.append("[contract][lease_template_id]", this.state.createContractPayloadData.lease_template_id);
    formdata.append("[contract][contract_template]", this.state.createContractPayloadData.contract_template);
    formdata.append("[contract][penanlty_late_payment]", String(this.state.createContractPayloadData.penanlty_late_payment));
    formdata.append("[contract][currency]", this.state.createContractPayloadData.currency);
    formdata.append("[contract][account_id]", this.state.createContractPayloadData.accountId as string)
    formdata.append("[contract][condition_ids][]", String(this.state.createContractPayloadData.condition_ids));
    formdata.append("[contract][country]", this.state.createContractPayloadData.country);
    formdata.append("[contract][city]", this.state.createContractPayloadData.city);

    this.createContractApiCallId = await this.reviewContractApiCall({
      method: method,
      endPoint: endpoint,
      body: payload,
      type:"formData"
    });
  };

  createContractSucessCallBack = (response: GetCreateContractResponse) => {
    this.setState({ showSuccessMessage: true, successMessage: response.message, activateContract:true, generateContractModal:false })
  };

  createContractFailureCallBack = (response: ApiFailureRespnseType) => {
    if (response.errors[0].contract) {
      this.setState({ showError: true, error: response.errors[0].contract });
    } else if (response.errors[0]) {
      this.setState({ showError: true, error: response.errors[0].errors });
    } else {
      this.setState({ showError: true, error: response.errors });
    }
  };

  saveCreateContract = async () => {
    let formdata : FormData = new FormData();
    formdata.append("[template][building_management_id]", this.state.createContractPayloadData.building_management_id);
    formdata.append("[template][apartment_management_id]", this.state.createContractPayloadData.apartment_management_id);
    formdata.append("[template][society_management_id]", this.state.createContractPayloadData.society_management_id);
    formdata.append("[template][tenant_name]", this.state.createContractPayloadData.tenant_name as string);
    formdata.append("[template][tenant_id]", this.state.createContractPayloadData.tenant_id);
    formdata.append("[template][agreement_duration]", this.state.createContractPayloadData.agreement_duration);
    formdata.append("[template][landlord_name]", this.state.createContractPayloadData.landlord_name);
    formdata.append("[template][currency]", this.state.createContractPayloadData.currency);
    formdata.append("[template][start_date]", this.state.createContractPayloadData.start_date);
    formdata.append("[template][term_ids][]", String(this.state.createContractPayloadData.term_ids));
    formdata.append("[template][rent_amount]", this.state.createContractPayloadData.rent_amount);
    formdata.append("[template][expires_on]", this.state.createContractPayloadData.expires_on);
    formdata.append("[template][contract_template]", this.state.createContractPayloadData.contract_template);
    formdata.append("[template][lease_template_id]", this.state.createContractPayloadData.lease_template_id);
    formdata.append("[template][condition_ids][]", String(this.state.createContractPayloadData.condition_ids));
    formdata.append("[template][penanlty_late_payment]", String(this.state.createContractPayloadData.penanlty_late_payment));
    formdata.append("[template][custom_contract]", String(false));
    formdata.append("[template][account_id]", this.state.createContractPayloadData.accountId as string);
    formdata.append("[template][template_name]", this.state.tenantName);
    formdata.append("[template][country]", this.state.createContractPayloadData.country);
    formdata.append("[template][city]", this.state.createContractPayloadData.city);
    this.saveContractTemplate = await this.reviewContractApiCall({
      method: "POST",
      endPoint: "society_managements/5/bx_block_contract/saved_lease_templates",
      body: formdata
    });
  };

  saveCreateContractSucessCallBack = (response: GetCreateContractResponse) => {
    this.setState({showSuccessMessage:true, successMessage: response.message,activateContract:true,generateContractModal:false },()=>{
      this.handleCloseContractActivatedModal()
    })
  };

  saveCreateContractFailureCallBack = (response: ApiFailureRespnseType) => {
    if(response.errors[0].contract){
      this.setState({showError:true,error:response.errors[0].contract});
    }else if(response.errors[0]){
      this.setState({showError:true,error:response.errors[0].errors });
    }else{
      this.setState({showError:true,error:response.errors});
    }
  };

  handleSaveContractAsDraft = async () => {
        let formdata : FormData = new FormData();
    let status = "Draft";
    if (this.state.createContractPayloadData.status === "active") {
      status = "Active"
    }
    formdata.append("[contract][society_management_id]", this.state.createContractPayloadData.society_management_id);
    formdata.append("[contract][apartment_management_id]", this.state.createContractPayloadData.apartment_management_id);
    formdata.append("[contract][tenant_name]", this.state.createContractPayloadData.tenant_name as string);
    formdata.append("[contract][building_management_id]", this.state.createContractPayloadData.building_management_id);
    formdata.append("[contract][agreement_duration]", this.state.createContractPayloadData.agreement_duration);
    formdata.append("[contract][landlord_name]", this.state.createContractPayloadData.landlord_name);
    formdata.append("[contract][tenant_id]", this.state.createContractPayloadData.tenant_id);
    formdata.append("[contract][start_date]", this.state.createContractPayloadData.start_date);
    formdata.append("[contract][currency]", this.state.createContractPayloadData.currency);
    formdata.append("[contract][rent_amount]", this.state.createContractPayloadData.rent_amount);
    formdata.append("[contract][expires_on]", this.state.createContractPayloadData.expires_on);
    formdata.append("[contract][term_ids][]", String(this.state.createContractPayloadData.term_ids));
    formdata.append("[contract][lease_template_id]", this.state.createContractPayloadData.lease_template_id);
    formdata.append("[contract][contract_template]", this.state.createContractPayloadData.contract_template);
    formdata.append("[contract][penanlty_late_payment]", String(this.state.createContractPayloadData.penanlty_late_payment));
    formdata.append("[contract][condition_ids][]", String(this.state.createContractPayloadData.condition_ids));
    formdata.append("[contract][account_id]", this.state.createContractPayloadData.accountId as string);
    formdata.append("[contract][custom_contract]", String(false));
    formdata.append("[contract][status]", status);
    formdata.append("[contract][country]", this.state.createContractPayloadData.country);
    formdata.append("[contract][city]", this.state.createContractPayloadData.city);
    let method = "POST";
    let endPoint = configJSON.createContractEndPoint;
    if (this.state.createContractPayloadData.status === "Draft") {
      method = "PUT";
      endPoint = `${configJSON.saveContractAsDraft}${this.state.createContractPayloadData.templateId}`
    }
      this.saveContractAsDraftApiCallId = await this.reviewContractApiCall({
      method: method,
      endPoint: endPoint,
      body: formdata
    });
  };

  saveContractAsDraftSucessCallBack = (response: SaveContractAsDraftSuccess) => {
    this.setState({ successMessage: response.message, },()=>{
      this.handleCloseContractActivatedModal();
    });
  };

  ssaveContractAsDraftFailureCallBack = (response: ApiFailureResponse & ApiFailureResponseError & ApiFailureErrorResponse) => {
    if(response.errors[0].contract){
      this.setState({showError:true,error:response.errors[0].contract});
    }else if(response.errors[0]){
      this.setState({showError:true,error:response.errors[0].errors });
    }else{
      this.setState({showError:true,error:response.errors});
    }
  };

  handleOpenGenerateModal=()=>{
    this.setState({generateContractModal:true})
  };

  handleCloseGenerateModal=()=>{
    this.setState({generateContractModal:false})
  };
  
  handleOpenSaveTemplateModal=()=>{
    this.setState({saveTemplateModal:true})
  };

  handleCloseSaveTemplateModal=()=>{
    this.setState({saveTemplateModal:false})
  };

  handleTenantChange = (event:ChangeEvent<HTMLInputElement>) => { 
    this.setState({ tenantName: event.target.value });
  };

  handleCloseContractActivatedModal=()=>{
    const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "LeaseManagment");
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgData);
  };
  
  handleCloseAlertError = () => {
    this.setState({ showError: false, error: "" }, () => {
      this.handleCloseContractActivatedModal();
    });
  };

  hanldeCloseSuccessAlert = () => {this.setState({showSuccessMessage:false,successMessage:""})}

  handleExistRecordNavigation = (path:string) => {
    const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadData);
    this.send(msgData);
  };

  handleEditDocument = () => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractForm");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadData);
    this.send(msgData);
  };

  handleDownloadContract = () => {
    if (this.state.createContractPayloadData.contractLink === "" || this.state.createContractPayloadData.contractLink === undefined || this.state.createContractPayloadData.contractLink === null) {
      this.setState({ downloadContractAlert: true, downloadContractMessage: "Contract is not availalble" });
    } else {
      const downloadContract = document.createElement('a');
      downloadContract.href = this.state.createContractPayloadData.contractLink;
      downloadContract.download = 'document.pdf';
      downloadContract.target = '_blank';
      document.body.appendChild(downloadContract);
      downloadContract.click();
      document.body.removeChild(downloadContract);
    }
  };

  handleCancelMeetingDialog = () => {
    this.setState({ downloadContractAlert: false, downloadContractMessage: "" });
  };
  // Customizable Area End
}