// Customizable Area Start
import React from "react";
import DraftContractDetailsController, { Props, TFunction } from "./LeaseManagementDraftContractDetailsController.web";
import {
    Box, styled, Breadcrumbs,
    Button,
    Divider,
    Grid,
    Popover,
    Typography,
    IconButton,
    Paper,
    Modal,
    MenuItem,
} from "@material-ui/core";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import MenuIcon from "@material-ui/icons/MoreVert";
import { BuildingIcon, CalenderIcon, DownloadIcon, InfoIcon2, ProfileIcon, UnitIcon } from "./assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementDraftContractDetails extends DraftContractDetailsController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }

    // Customizable Area Start 
    renderTerminateContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.deleteContractModal}>
            <ContractDetailsDeleteDraftModal >
                <Paper className='DeleteDraftModalChild'>
                    <img src={InfoIcon2} alt="" className="terminateModalIcon" />
                    <Typography className='DeleteDraftModalChildHeading'>{t("Delete Draft")}</Typography>
                    <Typography className='DeleteDraftModalChildText'>{t("Are you sure you want to delete the draft?")}</Typography>
                    <Box className='DeleteDraftModalChildButton'>
                        <Button variant='outlined' className='DeleteDraftModalChildButton1' onClick={this.handleCloseTerminateContractModal} data-testId={"cancelBtnTestId"}>{t("Cancel")}</Button>
                        <Button variant='contained' className='DeleteDraftModalChildButton2' onClick={this.deleteDraftContractDetails} data-testId={"modalCnfirmBtnTestId"}>{t("Confirm")}</Button>
                    </Box>
                </Paper>
            </ContractDetailsDeleteDraftModal>
        </Modal>
    }
    renderDraftContractDetailsContainer=()=>{
        const { t }: { t: TFunction } = this.props;
        return <>
        <Box className="DraftContractDetailsContent">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsComplexBox">
                                            <img
                                                src={BuildingIcon}
                                                className="DraftContractDetailsIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsHeader">
                                                    {t("Complex")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsComplexHeader2">
                                                    {t(this.state.contractDetails.attributes.society_management.name)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsBuildingBox">
                                            <img
                                                src={BuildingIcon}
                                                className="DraftContractDetailsBuildingIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsBuildingHeader">
                                                {t("Building")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsBuildingHeader2">
                                                    {t(this.state.contractDetails.attributes.building_name)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsUnitBox">
                                            <img
                                                src={UnitIcon}
                                                className="DraftContractDetailsUnitIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsUnitHeader">
                                                {t("Unit")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsUnitHeader2">
                                                    {t("A-105")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsOwnerBox">
                                            <img
                                                src={ProfileIcon}
                                                className="DraftContractDetailsOwnerIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsOwnerHeader">
                                                {t("Owner")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsOwnerHeader2">
                                                {this.state.contractDetails.attributes.landlord_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsTenantBox">
                                            <img
                                                src={ProfileIcon}
                                                className="DraftContractDetailsTenantIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsTenantHeader">
                                                {t("Tenant")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsTenantHeader2">
                                                {t(this.state.contractDetails.attributes.tenant_name)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsContractTimingBox">
                                            <img
                                                src={CalenderIcon}
                                                className="DraftContractDetailsContractTimingIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsContractTimingHeader">
                                                {t("Contract Duration")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsContarctTimingHeader2">
                                                    {this.state.contractDetails.attributes.agreement_duration}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Box className="DraftContractDetailsExpiryDateBox">
                                            <img
                                                src={CalenderIcon}
                                                className="DraftContractDetailsExpiryDateIcons"
                                            />
                                            <Box>
                                                <Typography className="DraftContractDetailsExpirayDateHeader">
                                                {t("Expiry Date")}
                                                </Typography>

                                                <Typography className="DraftContractDetailsExpiryDateHeader2">
                                                    {this.state.contractDetails.attributes.expires_on}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="DraftContractDetailsContentBox">
                                <Typography className="DraftContractDetailsContentHeading">
                                    Recident Rental Lease Agreement
                                </Typography>
                                <Typography className="DraftContractDetailsContentHeadingText">
                                <div dangerouslySetInnerHTML={{ __html: this.state.contractDetails.attributes.contract_template}}></div>
                                </Typography>
                            </Box>
        </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { anchor } = this.state;
        const open = Boolean(anchor);
        const id = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <ContactDetailsHeader>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography id="LeaseManagementId" onClick={() => this.handleExistRecordNavigation("LeaseManagment",)} className="DraftContactDetailsBreadcrumbsChild1" data-testId={"draftContractDetailsHeadingTestId"}>
                                {t("Lease Management")}
                            </Typography>
                            <Typography className="DraftContactDetailsBreadcrumbsChild2" data-testId={"contractDetailsHeadingTestId"}>
                                {t("Contract Details")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="DraftContactDetailsHeading" data-testId={"draftContractDetailsTestID"}>
                        {t("Contract Details")}
                        </Typography>
                        <Paper className="DraftContactDetailsHeadingContainer">
                            <Box className="DraftContactDetailsHeadingBox">
                                <Typography className="DraftContactDetailsHeadingText">
                                    Contract 1
                                </Typography>
                                <Box className="DraftContractDetailsBoxIcons">
                                    <img
                                        className="DraftContractDetailDownloadIconStyled"
                                        src={DownloadIcon}
                                        alt={DownloadIcon}
                                        onClick={() => this.handleDownloadFile(this.state.contractDetails.attributes.contract_template_pdf.url)}
                                        data-testId={"downloadPdfTestId"}
                                    />
                                    <IconButton onClick={this.handleClick} data-testId={"menuIconTestId"}>
                                        <MenuIcon />
                                    </IconButton>
                                    <Popover
                                        id={id}
                                        open={open}
                                        onClose={this.handleClose}
                                        data-testId={"menuPopoverCloseTestId"}
                                        anchorEl={anchor}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <MenuItem onClick={this.handleOpenTerminateContractModal} data-testId={"deleteBtnTestID"}>
                                        {t("Delete Draft")}
                                        </MenuItem>
                                    </Popover>
                                </Box>
                            </Box>
                            <Divider />
                            {this.renderDraftContractDetailsContainer()}                        
                        </Paper>
                    </Box>
                    <Box className="DraftContractDetailsButtonGroup">
                        <Button variant="contained" className="DraftContractDetailsButton2" onClick={this.handleUseDraft} data-testId={"useDraftTestId"}>
                        {t("Use Draft")}
                        </Button>
                    </Box>
                    {this.renderTerminateContractModal()}
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTestId"} />
                    <SuccessAlert show={this.state.showSuccessMessage} handleClose={this.handleCloseSuccessAlert} message={this.state.successAlertMessage} data-testId={"successAlertCloseBtnTestId"}/>
                </ContactDetailsHeader>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementDraftContractDetails);
export { LeaseManagementDraftContractDetails };
const ContactDetailsHeader = styled(Box)({
    padding: 30,
    background: "#f4f7ff",
    overflow: "scroll",
    "& .DraftContactDetailsHeading": {
        fontWeight: 800,
        fontSize: "30px",
        paddingBottom: 20,
        marginTop: 10,
    },
    "& .DraftContactDetailsHeadingContainer": {
        borderRadius: 10,
    },
    "& .DraftContactDetailsBreadcrumbsChild1": {
        color: "#676972",
    },
    "& .DraftContactDetailsBreadcrumbsChild2": {
        color: "#6d84bc",
    },
    "& .DraftContactDetailsHeadingBox": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingLeft: 20,
        paddingTop: 15,
    },
    "& .DraftContactDetailsHeadingText": {
        fontSize: "25px",
        fontWeight: 800,
    },
    "& .DraftContractDetailDownloadIconStyled": {
        height: "30px",
        width: "33px",
        marginRight: 15,
    },

    "& .DraftContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
        padding: "6px 40px"
    },
    "& .DraftContractDetailsContentHeading": {
        fontWeight: 800,
    },
    "& .DraftContractDetailsContentHeadingText": {
        color: "gray",
        textAlign: "justify",
        marginTop: 10,
    },
    "& .DraftContractDetailsButtonGroup": {
        float: "right",
        paddingTop: 50,
    },
    "& .ContractDetailPopover": {
        top: "300px",
        right: "700px",
        position: "absolute",
    },
    "& .DraftContractDetailsHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsExpirayDateHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsBuildingHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsUnitHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsOwnerHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsTenantHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsContractTimingHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsExpiryDateHeader": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .DraftContractDetailsHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsComplexHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsBuildingHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsUnitHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsOwnerHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsTenantHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsContarctTimingHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsExpiryDateHeader2": {
        fontWeight: "bold",
    },
    "& .DraftContractDetailsContent": {
        display: "flex",
        flexWrap: "wrap",
        padding: 20,
    },
    "& .DraftContractDetailsComplexBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsBuildingBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsUnitBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsOwnerBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsTenantBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsContractTimingBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsExpiryDateBox": {
        display: "flex",
        gap: "10px",
    },
    "& .DraftContractDetailsContentBox": {
        padding: 20,
    },
    "& .DraftContractDetailsBoxIcons": {
        display: "flex",
        alignItems: "center",
    },
    "& .DraftContractDetailsIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsBuildingIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsUnitIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsOwnerIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsTenantIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsContractTimingIcons": {
        width: 25,
        height: 25,
    },
    "& .DraftContractDetailsExpiryDateIcons": {
        width: 25,
        height: 25,
    },
});

const ContractDetailsDeleteDraftModal = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& .DeleteDraftModalChild': {
        padding: "50px",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: '50px',
        paddingLeft: '50px',
    },
    '& .DeleteDraftModalChildHeading': {
        fontWeight: 800,
        fontSize: 25,
        marginBottom: 10
    },
    '& .DeleteDraftModalChildText': {
        textAlign: 'center',
        color: '#5f6062',
        width: "420px"
    },
    '& .DeleteDraftIcon': {
        color: '#f77f37',
        fontSize: 50
    },
    '& .DeleteDraftModalChildButton': {
        marginTop: 40
    },
    '& .DeleteDraftModalChildButton1': {
        border: '1px solid #2f6ded',
        color: '#2f6ded',
        padding: '15px 40px 15px 40px',
        fontWeight: 700,
        borderRadius: 10
    },
    '& .DeleteDraftModalChildButton2': {
        background: '#2f6ded',
        color: 'white',
        marginLeft: 15,
        padding: '15px 40px 15px 40px',
        fontWeight: 700,
        borderRadius: 10
    },
    "& .terminateModalIcon": {
        marginBottom: "20px"
    },
})


// Customizable Area End