import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accountSaleValue: string;
    accountManagerDataSave: Array<AssignedAccountManagerItem>;
    anchorEl: HTMLButtonElement | null;
    sortBy: string;
    searchText: string;
    pageNumber: number;
    paginationData: PaginationDataInterface | null;
    showError: boolean;
    errorMessage: string;
    loader: boolean;
    // Customizable Area End
}
// Customizable Area Start

export type TFunction = (value: string) => string;

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
    contract: string
};

export interface AssignedAccountManagerResponse {
    data: Array<AssignedAccountManagerItem>;
    meta: {
        pagination: PaginationDataInterface | null;
    }
}

export interface AssignedAccountManagerItem {
    id: string;
    attributes: {
        id: number;
        full_name: string;
        email: string;
        assigend_complexes: number;
        assigend_buildings: number;
        assigend_owners: number;
        assigend_property_developers: number;
    }
}

export interface PaginationDataInterface {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface AssignedAccountManagerErrorResponse {
    errors: Array<string>;
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AccountManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    assignedAccountManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start 
            sortBy: "",
            searchText: "",
            pageNumber: 0,
            accountManagerDataSave: [],
            anchorEl: null,
            accountSaleValue: "", 
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },  
            showError: false,
            errorMessage: "",
            loader: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson.errors) || responseJson.contracts) {
                this.assignedAccountManagerApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.assignedAccountManagerApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getAssignedAccountManagerList("1");
    };

    assignedAccountManagerApiCall = async (apiPayloadData: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = apiPayloadData;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let assignedAccountManagerMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        assignedAccountManagerMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        assignedAccountManagerMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        assignedAccountManagerMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        assignedAccountManagerMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? assignedAccountManagerMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : assignedAccountManagerMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(assignedAccountManagerMessage.id, assignedAccountManagerMessage);
        return assignedAccountManagerMessage.messageId;
    };

    assignedAccountManagerApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: AssignedAccountManagerResponse) => {
        if (apiRequestCallId === this.assignedAccountManagerApiCallId) {
            this.assignedAccountManagerListSucessCallBack(responseJson);
        }
    };

    assignedAccountManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse & AssignedAccountManagerErrorResponse) => {
        if (apiRequestCallId === this.assignedAccountManagerApiCallId) {
            this.assignedAccountManagerListFailureCallBack(responseJson);
        }
    };

    getAssignedAccountManagerList = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.assignAccountManagerEndpoint}page=${page}`;
        let {sortBy,searchText } = this.state;
        if (sortBy) {
            endPoint += `&sort_by=${sortBy}`;
        }
        if (searchText) {
            endPoint += `&search_term=${searchText}`;
        }
        this.assignedAccountManagerApiCallId = await this.assignedAccountManagerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    assignedAccountManagerListSucessCallBack = (response: AssignedAccountManagerResponse) => {
        this.setState({ accountManagerDataSave: response.data, paginationData: response.meta.pagination, loader: false });
    };

    assignedAccountManagerListFailureCallBack = (response: AssignedAccountManagerErrorResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0],loader:false });
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    sortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string }, () => {
            this.getAssignedAccountManagerList("1");
        });
    };

    searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getAssignedAccountManagerList("1");
        });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
            this.getAssignedAccountManagerList(String(value));
    };

    handleRedirection = (path: string) => {        
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        this.send(msgData);
    };

    handleAlertError = () => {
        this.setState({ errorMessage: "", showError: false });
    };
    // Customizable Area End
}
