// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";

import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import { checkPhoneValidation, handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId?: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface SelectOption {
  label: string;
  value: string;
}

interface IFormValue {
  logo: any;
  company: string;
  salesManager: string;
  accountManager: string;

  primaryPhoneCountry: string;
  primaryPhone: string;
  primaryEmail: string;
  primaryContact: string;

  secondaryPhoneCountry: string;
  secondaryPhone: string;
  secondaryEmail: string;
  secondaryContact: string;

  manageBy: string;
  country: string;
  region: string;
  city: string;
  zipCode: string;
  mapLocation: string;
  address: string;
  registrationNo: string;
  certificate: any;
}

interface S {
  accountType: string;
  loading: boolean;

  createConfirmModal: boolean;
  editConfirmModal: boolean;

  countriesList: SelectOption[];
  cityList: SelectOption[];
  regionList: SelectOption[];
  accountManagerList: SelectOption[];
  salesManagerList: SelectOption[];

  formValue: IFormValue;

  showError: boolean;
  error: string;
}

interface SS {
  id: any;
}

export default class RealEstateCompanyFormController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCountriesFilterList: string = "";
  GetRegionsFilterList: string = "";
  GetCityFilterList: string = "";
  GetAccountFilterList: string = "";
  GetSalesFilterList: string = "";

  CreateCompanyCallId: string = "";
  UpdateCompanyCallId: string = "";
  GetCompanyDetailsCallId: string = "";

  [key: string]: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleCompanyData = this.handleCompanyData.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      accountType: "",
      loading: false,

      createConfirmModal: false,
      editConfirmModal: false,

      countriesList: [],
      cityList: [],
      regionList: [],
      accountManagerList: [],
      salesManagerList: [],

      formValue: {
        logo: "",
        company: "",
        salesManager: "",
        accountManager: "",

        primaryPhoneCountry: "+971",
        primaryPhone: "",
        primaryEmail: "",
        primaryContact: "",

        secondaryPhoneCountry: "+971",
        secondaryPhone: "",
        secondaryEmail: "",
        secondaryContact: "",

        manageBy: "",
        country: "",
        region: "",
        city: "",
        zipCode: "",
        mapLocation: "",
        address: "",
        registrationNo: "",
        certificate: "",
      },

      showError: false,
      error: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCountriesFilterList:
          this.handleCountriesFilterList(responseJson);
          break;
        case this.GetCityFilterList:
          this.handleCitiesFilterList(responseJson);
          break;
        case this.GetRegionsFilterList:
          this.handleRegionFiltersList(responseJson);
          break;
        case this.GetAccountFilterList:
          this.handleAccountFilterList(responseJson);
          break;
        case this.GetSalesFilterList:
          this.handleSalesFilterList(responseJson);
          break;
        case this.CreateCompanyCallId:
          this.handleCreateSuccess(responseJson);
          break;
        case this.UpdateCompanyCallId:
          this.handleUpdateCompany(responseJson);
          break;
        case this.GetCompanyDetailsCallId:
          this.handleCompanyData(responseJson);
          break;
        default:
          break;
      }
      this.setState({ loading: false });

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    const accountRole = localStorage.getItem("role");
    if (accountRole === "coo") {
      this.setState({ accountType: accountRole }, () => {
        this.getFilterListForm("GetAccountFilterList", `bx_block_real_estate_companies/account_manager_list`);
        this.getFilterListForm("GetSalesFilterList", `bx_block_real_estate_companies/sales_manager_list`);
      });
    }

    this.getFilterListForm("GetCountriesFilterList", `bx_block_address/country_list`);

    if (this.props.companyId) {
      this.getCompanyDetails(this.props.companyId);
    }
  }

  getFilterListForm = (method: string, endPoint: string) => {
    this.setState({ loading: true }, async () => {
      this[method] = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint,
      });
    });
  };

  handleCountriesFilterList = (responseJson: { data: { countries: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        countriesList: responseJson.data.countries.map((item: string) => ({ label: item, value: item })),
      });
    }
  };

  handleCitiesFilterList = (responseJson: { data: { cities: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ cityList: responseJson.data.cities.map((item: string) => ({ label: item, value: item })) });
    }
  };

  handleRegionFiltersList = (responseJson: { data: { regions: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ regionList: responseJson.data.regions.map((item: string) => ({ label: item, value: item })) });
    }
  };

  handleAccountFilterList = (responseJson: { account_managers: [] }) => {
    if (responseJson && responseJson.account_managers) {
      this.setState({
        accountManagerList: responseJson.account_managers.map((item: any) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  handleSalesFilterList = (responseJson: { sales_managers: [] }) => {
    if (responseJson && responseJson.sales_managers) {
      this.setState({
        salesManagerList: responseJson.sales_managers.map((item: any) => ({
          label: item.name,
          value: item.id + "",
        })),
      });
    }
  };

  handleSubmit = (values: IFormValue) => {
    const formData = new FormData();
    formData.append("company_name", values.company);
    formData.append("managed_by", values.manageBy);
    formData.append("logo", values.logo);
    formData.append("country", values.country);
    formData.append("region", values.region);
    formData.append("city", values.city);
    formData.append("zipcode", values.zipCode);
    formData.append("sales_manager_id", values.salesManager);
    formData.append("account_manager_id", values.accountManager);
    formData.append("google_map_location", values.mapLocation);
    formData.append("hq_location_address", values.address);
    formData.append("commercial_registration_number", values.registrationNo);
    if (this.state.accountType === "coo") {
      formData.append("status", "approved");
    } else {
      formData.append("status", "pending_approval");
    }
    formData.append("contact_name", values.primaryContact);
    formData.append("email_address", values.primaryEmail);
    formData.append("phone_number", values.primaryPhoneCountry + "-" + values.primaryPhone);
    formData.append("[point_of_contacts][contact_name]", values.secondaryContact);
    formData.append("[point_of_contacts][email_address]", values.secondaryEmail);
    formData.append("[point_of_contacts][phone_number]", values.secondaryPhoneCountry + "-" + values.secondaryPhone);
    formData.append("employee_account_id", JSON.parse(localStorage.getItem("user_id") as string));
    formData.append("registration_certificate", values.certificate);

    if (this.props.companyId) {
      this.updateCompanyDetails(formData);
    } else {
      this.submitCompanyDetails(formData);
    }
  };

  submitCompanyDetails = (data: any) => {
    this.setState({ loading: true }, async () => {
      this.CreateCompanyCallId = await apiCall({
        method: "POST",
        body: data,
        endPoint: `/bx_block_real_estate_companies/real_estate_companies`,
      });
    });
  };

  handleCreateSuccess = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      this.setState({ showError: true, error: responseJson.message });
    } else {
      this.setState({ createConfirmModal: true });
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ createConfirmModal: false }, () => {
      this.props.navigation.navigate("RealEstateCompanyEmployee");
    });
  };

  updateCompanyDetails = (data: any) => {
    this.setState({ loading: true }, async () => {
      this.UpdateCompanyCallId = await apiCall({
        method: "PUT",
        body: data,
        endPoint: `/bx_block_real_estate_companies/real_estate_companies/${this.props.companyId}`,
      });
    });
  };

  handleUpdateCompany = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      this.setState({ showError: true, error: responseJson.message });
    } else {
      this.setState({ editConfirmModal: true });
    }
  };

  handleCloseEditModal = () => {
    this.setState({ editConfirmModal: false }, () => {
      this.props.navigation.navigate("RealEstateCompanyDetail", { id: this.props.companyId });
    });
  };

  handleCancel = () => {
    this.props.navigation.navigate("RealEstateCompanyEmployee");
  };

  getCompanyDetails = (companyId: number | string) => {
    this.setState({ loading: true }, async () => {
      this.GetCompanyDetailsCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
      });
    });
  };

  handleCompanyData = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const company = responseJson.data.attributes;

      let logo: any = null;
      if (company.logo) {
        const logos = await handleImages([company.logo]);
        logo = logos[0];
      }

      let certificate: any = null;
      if (company.registration_certificate) {
        const certificates = await handleImages([company.registration_certificate]);
        certificate = certificates[0];
      }

      const primaryPhone = company.phone_number.split("-");
      const secondaryPhone = company.point_of_contacts_secondary.phone_number.split("-");

      this.setState(
        {
          formValue: {
            logo: logo,
            company: company.company_name,
            salesManager: company.sales_manager_id,
            accountManager: company.account_manager_id,

            primaryPhoneCountry: primaryPhone[0],
            primaryPhone: primaryPhone[1],
            primaryEmail: company.email_address,
            primaryContact: company.contact_name,

            secondaryPhoneCountry: secondaryPhone[0],
            secondaryPhone: secondaryPhone,
            secondaryEmail: company.point_of_contacts_secondary.email_address,
            secondaryContact: company.point_of_contacts_secondary.contact_name,

            manageBy: company.managed_by,
            country: company.country,
            region: company.region,
            city: company.city,
            zipCode: company.zipcode,
            mapLocation: company.google_map_location,
            address: company.hq_location_address,
            registrationNo: company.commercial_registration_number,
            certificate: certificate,
          },
        },
        () => {
          this.getFilterListForm("GetCityFilterList", `bx_block_address/city_list?country=${company.country}`);
          this.getFilterListForm("GetRegionsFilterList", `bx_block_address/region_list?country=${company.country}`);
        }
      );
    }
  };

  handleChangeLabel = (value: any, t: any) => {
    return value ? t("Change Logo") : t("Add Logo");
  };

  validationSchema = Yup.object().shape({
    logo: Yup.mixed().required("Required"),
    company: Yup.string().required("Required"),

    primaryPhone: Yup.string().required("Required").max(10, "Must be at most 10 characters long"),
    primaryEmail: Yup.string().required("Required"),
    primaryContact: Yup.string().required("Required"),

    secondaryPhone: Yup.string().required("Required").max(10, "Must be at most 10 characters long"),
    secondaryEmail: Yup.string().required("Required"),
    secondaryContact: Yup.string().required("Required"),

    manageBy: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    region: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    zipCode: Yup.string().required("Required").max(6, "Must be at most 6 characters long"),
    mapLocation: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    registrationNo: Yup.string().required("Required"),
    certificate: Yup.mixed().required("Required"),
  });

  handleFormSubmit = (values: IFormValue, resetForm: () => void) => {
    if (!checkPhoneValidation(values.primaryPhone, values.primaryPhoneCountry)) {
      this.setState({ error: "Please enter valid phone number (Primary)", showError: true });
    } else  if (!checkPhoneValidation(values.secondaryPhone, values.secondaryPhoneCountry)) {
      this.setState({ error: "Please enter valid phone number (Secondary)", showError: true });
    } else {
      this.setState({ loading: true }, () => {
        this.handleSubmit(values);
        resetForm();
      });
    }
  };

  handleTitle = () => this.props.companyId ? "Edit Real Estate Company" : "Register Real Estate Company";
}
// Customizable Area End
