//Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";




import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    t?:any;
}
interface S {
    enableField: boolean;
    openteammember: boolean;
    opendashboard: boolean;
    opencomplexBuilding: boolean;
    openrealEstateCompanies: boolean;
    opencommunityManagement: boolean;
    openactivities: boolean;
    openmanageFAQ: boolean;
    opennominations: boolean;
    openleasemanagement: boolean;
    openmanageSub: boolean;
    openclientTicket: boolean;
    opendocuments: boolean;
    selectedIndex:any;
}
interface SS {
    id: any;

}
export default class CESalesManSidebarController extends CommonApiCallForBlockComponent<Props, S, SS> {
    location: string = !window.location.pathname.toLowerCase().includes("comingsoon") ? window.location.pathname.split('/')[2] : ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            enableField: false,
            opendashboard: this.checkPath(['Dashboard','jurisdication', 'ViewJurisdiction', 'CEDashboardAnalytics', 'DashboardsGranted', "ActionAssignMe", "ActionAssignTeam"]),
            openteammember: this.checkPath(['CEMyTeam', 'CEMyTeamMember']),
            opencomplexBuilding: this.checkPath(['ComplexListing', 'ComplexBuildingMainBlock', 'ComplexDetails', 'complex', 'BuildingDetailsPage']),
            openmanageFAQ: this.checkPath(['CompEmpFaq']),
            opennominations: this.checkPath(['CENomination','CENominationDetails']),
            openleasemanagement: false,
            openmanageSub: this.checkPath(['SubsciptionListing', 'SubsciptionDetails','AddSubscription']),
            openclientTicket: false,
            opendocuments: this.checkPath(['CompEmpDocument','ReportsStatistics','LoginReports','InvitationReport', 'PropertyManagerReport','SubscriptionReports']),
            openrealEstateCompanies: this.checkPath(['realestate-companies', 'realestate-register-company', 'realestate-company-details', 'realestate-edit-company', 'ComplexMainBlock', 'BuildingMainBlock']),
            opencommunityManagement: this.checkPath(['communityManagementProfile', "PendingJoinRequest", "AwaitAccept"]),
            openactivities: this.checkPath(['cooincidentListing','CompEmpAnnouncement', 'cooincident-details','CEMeetingMinutes', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList','CEPollSurveyDetails', 'CEPollSurveyReports', 'CEScheduleMeeting']),
            selectedIndex:"",
            
        };

        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    checkPath = (path: string[]) => path.map((item) => item.toLowerCase()).includes(window.location.pathname.split('/')[2].toLowerCase());

    isActiveMenu = (pathname: string[]) => this.location ? pathname.map(pathItem => pathItem.toLowerCase()).includes(this.location.toLowerCase()) : false;

    handleeteamMember = () => {
        this.setState({  selectedIndex:"", openteammember: !this.state.openteammember, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })

    }
    handleecomplexBuilding = () => {
        this.setState({  selectedIndex:"", opencomplexBuilding: !this.state.opencomplexBuilding, openteammember: false, opendashboard: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })
    }
    handleerealEstateCompany = () => {
        this.setState({  selectedIndex:"",openrealEstateCompanies: !this.state.openrealEstateCompanies, openteammember: false, opendashboard: false, opencomplexBuilding: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })
    }
    handleeDashboardClick = () => {
        this.setState({  selectedIndex:"",opendashboard: !this.state.opendashboard, openteammember: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, })

    }

    handleecommunity = () => { this.setState({  selectedIndex:"",opencommunityManagement: !this.state.opencommunityManagement, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }


    handleemanageFAQ = () => { this.setState({  selectedIndex:"",opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: !this.state.openmanageFAQ, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    handleelease = () => { this.setState({  selectedIndex:"",opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: !this.state.openleasemanagement, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    handleemanagesub = () => { this.setState({  selectedIndex:"",openmanageSub: !this.state.openmanageSub, opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openclientTicket: false, opendocuments: false, }) }
    handleeclient = () => { this.setState({  selectedIndex:"",opennominations: false, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openmanageSub: false, openclientTicket: !this.state.openclientTicket, opendocuments: false, }) }
    getColor2 = (isOpen: any) => (isOpen ? "#2b6fed" : "#000000");
    handleeactivities = () => { this.setState({  selectedIndex:"",openactivities: !this.state.openactivities, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    handleedocumentReport = () => { this.setState({  selectedIndex:"",opendocuments: !this.state.opendocuments, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, opennominations: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, }) }
    handleenominations = () => { this.setState({  selectedIndex:"",opennominations: !this.state.opennominations, openteammember: false, opendashboard: false, opencomplexBuilding: false, openrealEstateCompanies: false, opencommunityManagement: false, openactivities: false, openmanageFAQ: false, openleasemanagement: false, openmanageSub: false, openclientTicket: false, opendocuments: false, }) }
    handleeItemClick = (index:any) => {
        this.setState({
          selectedIndex: index,
        });
      };
    getTabData = (name: string) => {
        switch(name){
            case 'LeaseManagment':
                return (this.isActiveMenu(['LeaseManagment','IssueNewLease',"IssueNewContract","IssueNewContractTemplateDetails","IssueNewContractReview","IssueNewContractAddTerm","IssueNewContractForm","ReviewDraft","ContractDetails","DraftContractDetails","SavedTemplateDetails"]) || this.state.openleasemanagement);
            case 'dashboard':
                return (this.isActiveMenu(['Dashboard','jurisdication', 'ViewJurisdiction', 'CEDashboardAnalytics', 'DashboardsGranted']) || this.state.opendashboard);
            case 'team':
                return (this.isActiveMenu(['CEMyTeam', 'CEMyTeamMember']) || this.state.openteammember);
            case 'complex':
                return (this.isActiveMenu(['ComplexListing', 'ComplexBuildingMainBlock', 'ComplexDetails', 'complex']) || this.state.opencomplexBuilding);
            case 'document':
                return (this.isActiveMenu(['CompEmpDocument','ReportsStatistics','LoginReports','InvitationReport', 'PropertyManagerReport', 'PropertyManagerReportDetails','SubscriptionReports']) || this.state.opendocuments);
            case 'faq':
                return (this.isActiveMenu(['CompEmpFaq']) || this.state.openmanageFAQ);
            case 'realEstate':
                return (this.isActiveMenu(['realestate-companies', 'realestate-register-company', 'realestate-company-details', 'realestate-edit-company', 'ComplexMainBlock', 'BuildingMainBlock']) ||this.state.openrealEstateCompanies)
            case 'activities':
                return (this.isActiveMenu(['cooincidentListing','CompEmpAnnouncement', 'cooincident-details','CEMeetingMinutes', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList','CEPollSurveyDetails', 'CEPollSurveyReports']) || this.state.openactivities);
            case 'subscription':
                return (this.isActiveMenu(['SubsciptionListing', 'SubsciptionDetails','AddSubscription']) || this.state.openmanageSub);
            case 'nomination':
                return (this.isActiveMenu(['CENomination','CENominationDetails']) || this.state.openmanageFAQ);
            default:
                return false;
        }  
    }
}
//Customizable Area End


