import moment from "moment";
import { dataURLtoFile, toDataURL } from "./UtilFunction.web";

// Customizable Area Start
export const navigationFunc = (props: any, path: string, params?: any) => {
  if (params) {
    props.navigation.navigate(path, params);
  } else {
    props.navigation.navigate(path);
  }
};

export const handleDownloadLink = (link: any) => {
  const aTag = document.createElement("a");
  aTag.href = link;
  aTag.target = "_blank";
  document.body.appendChild(aTag);
  aTag.click();
  if (aTag.parentNode) {
    aTag.parentNode.removeChild(aTag);
  }
};

export const formatDate = (date: string | null, format: any = "DD MMM YYYY hh:mm" ) => {
  if (date) {
    return moment(date).format(format);
  }
  return "";
};

export const formatOnlyDate = (date: string | null) => {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  }
  return "";
};

export const handleDocumentFile = async (file: any) => {
  const imageUrlPromise: unknown[] = file.map(async (image: { images: { url: string }[] }) => {
    const url = image.images[0].url;
    return new Promise(async (resolve, reject) => {
      let blobString = await toDataURL(url);
      resolve(blobString);
    });
  });
  const imagesData = await Promise.allSettled(imageUrlPromise);
  return file.map((item: any, index: number) => ({
    file: dataURLtoFile(
      (imagesData[index] as { value: string }).value,
      item.file_name ? item.file_name : "File " + (index + 1)
    ),
    title: item.title ? item.title : "No title available",
  }));
};

export const debounce = (func: any, delay: any) => {
  let debounceTimer: any;
  return function(...args: any[]) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(args), delay);
  };
};
// Customizable Area End
