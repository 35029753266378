import React from "react";
// Customizable Area Start
import {
    Box,
    Breadcrumbs,
    Button,
    MenuItem,
    Select,
    Typography,
    TextField,
    InputAdornment,
    Grid,
    Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from "@material-ui/styles";
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import DetailIcon from '@material-ui/icons/ErrorOutlined';
import SelectArrowIcon from '@material-ui/icons/ExpandMore';
import { CooOpenTicketItem, TFunction,configJSON,Society,Category} from './OpenTicketsController.web';
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";

// Customizable Area End

import OpenTicketsController, { Props, } from "./OpenTicketsController.web";

class OpenTickets extends OpenTicketsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderMap=(cooOpenTicketItem: CooOpenTicketItem)=>{
        return(
            <MapDialog
            t={this.props.t}
            IsOpen={this.state.openBuildingMap}
            CloseDialog={this.handleCloseBuildingMap}
            lat={cooOpenTicketItem.attributes.latitude}
            long={cooOpenTicketItem.attributes.longitude}
            data-testId={"viewBuildingModal"}
        />
        )
    }
    renderHeading=()=>{
        const { t }: { t: TFunction } = this.props;
        return(
            <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography onClick={this.handleNavigationdashboard}  data-testId={"navigationDashboardTestId"} className="navigateLinkCoo">{t(configJSON.dashboardText)}</Typography>
                <Typography onClick={this.handleNavigationdashboard}  data-testId={"navigationGeneraldashboardTestId"} className="navigateLinkCoo">{t(configJSON.GeneralDashboard)}</Typography>
                <Typography className="tabActiveCoo">{t(configJSON.TotalOpenIncidents)}</Typography>
            </Breadcrumbs>
        </Box>
        )
    }
 
    renderCooTotalIncidents = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <OpenTicketCOO>
               {this.renderHeading()}
                <Box className="listHeadingCoo">
                    <Typography className="listParaCoo">
                        {t(configJSON.TotalOpenIncidents)}
                    </Typography>
                    <SearchTextField
                        variant="outlined"
                        placeholder="Search Building"
                        value={this.state.searchText}
                        onChange={this.handleSearchInputChange}
                        data-testId={"searchInputTestId"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className="searchNameIconCoo" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box className="headingContainerCoo">
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId={"complexSelectTestId"}
                        className="selectDataCoo"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.complexList.find(complexListcoo => complexListcoo.id === data) ? <MenuItem>{this.state.complexList.find(complexListcoo => complexListcoo.id === data)?.name}</MenuItem> : <span>{configJSON.SelectComplex}</span>)
                        }}
                        
                    >
                        {this.state.complexList?.map((complexListcoo:Society)=>{
                            return(
                                <MenuItem value={complexListcoo.id}>
                                {t(complexListcoo.name)}
                            </MenuItem>
                            )
                        })}
                      
                    </Select>

                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId={"buildingSelectTestId"}
                        className="selectDataCoo"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        disabled={ !this.state.complex}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.buildingList.find(buildingListcoo => String(buildingListcoo.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectBuilding}</span>)
                        }}
                    >
                       {this.state.buildingList?.map((buildingListcoo:Society)=>{
                            return(
                                <MenuItem value={buildingListcoo.id}>
                                {t(buildingListcoo.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.category}
                        onChange={this.handleCategoryChange}
                        data-testId={"categorySelectTestId"}
                        className="selectDataCoo"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.categoryList.find(categoryListcoo => String(categoryListcoo.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectCategory}</span>)
                        }}
                    >
                         {this.state.categoryList?.map((categoryListcoo:Category)=>{
                            return(
                                <MenuItem value={categoryListcoo.id}>
                                {t(categoryListcoo.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.factor}
                        onChange={this.handleFactorChange}
                        data-testId={"factorSelectTestId"}
                        className="selectDataCoo"
                        labelId="demo-simple-select-label"
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t(configJSON.IncidentFactor)}</em>
                        </MenuItem>
                    </Select>
                    <Select
                        value={this.state.month}
                        onChange={this.handleMonthChange}
                        data-testId={"monthSelectTestId"}
                        className="selectDataCoo"
                        labelId="demo-simple-select-label"
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.monthList.find(monthListcoo => String(monthListcoo) === String(data))? <MenuItem>{this.state.monthList.find(monthListcoo => String(monthListcoo) === String(data))}</MenuItem> : <span>{configJSON.SelectMonth}</span>)
                        }}
                    >
                              {this.state.monthList.map((monthListcoo: number, index: number) => {
                return <MenuItem key={index} value={monthListcoo}>{monthListcoo}</MenuItem>
              })}
                    </Select>
                    <Select
                        value={this.state.year}
                        onChange={this.handleYearChange}
                        data-testId={"yearSelectTestId"}
                        className="selectDataCoo"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.yearList.find(yearListcoo => String(yearListcoo) === String(data))? <MenuItem>{this.state.yearList.find(yearListcoo => String(yearListcoo) === String(data))}</MenuItem> : <span>{configJSON.SelectYear}</span>)
                        }}
                    >
                        {this.state.yearList.map((yearListcoo: number, index: number) => {
                return <MenuItem key={index} value={yearListcoo}>{yearListcoo}</MenuItem>
              })}
                    </Select>
                    <Select
                        value={this.state.sortBy}
                        onChange={this.handleSortByChange}
                        data-testId={"sortBySelectTestId"}
                        className="selectDataCoo"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (data? <span>{data as string}</span> : <span>{configJSON.sortBy}</span>)
                        }}
                    >
                        {this.state.sortList.map((sortListcoo: string, index: number) => {
                            return <MenuItem key={index} value={sortListcoo}>{sortListcoo}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        className="searchButtonCoo"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={this.handleSearchApi}
                    >
                        {t(configJSON.Search)}
                    </Button>
                </Box>
                <Box>
                   
                {this.state.cooOpenTicketData.length===0&&
                 <Grid container className="noDatafoundgridcoo">
                        <Grid >
                          <Typography className="noDatafoundcoo">No Data Found</Typography>
                            </Grid>
                            </Grid>}
                    <Grid container spacing={3}>
                        {this.state.cooOpenTicketData?.map((cooOpenTicketItem: CooOpenTicketItem, cooOpenTicketItemIndex: number) => {
                            return (
                                <Grid item lg={6} md={6} sm={12} xs={12} key={cooOpenTicketItemIndex}>
                                    <Box className="ticketContainerCoo">
                                        <Box className="incidentHeaderCoo">
                                            <Box className="buildingSectionCoo">
                                                <Typography className="ticketHeadingCoo"  data-testId={"ticketHeadingCoo"} >{configJSON.buildingHeader}</Typography>
                                                <Box className="buildingDataCoo">
                                                    <Typography className="ticketParaCoo">{cooOpenTicketItem.attributes.building_name}</Typography>
                                                    <LocationIcon  className="locationIconCoo" />
                                                    <Typography onClick={this.handleBuildingMap} data-testId={"viewBulidingmap"} className="locationIconCoo">{configJSON.location}</Typography>
                                                </Box>
                                                {this.renderMap(cooOpenTicketItem)}
                                               
                                                <Typography className="ticketHeadingCoo">{configJSON.openTicket}</Typography>
                                                <Typography className="ticketParaCoo">{cooOpenTicketItem.attributes.total_open_tickets}</Typography>
                                                <Typography className="ticketHeadingCoo">{configJSON.ticketMoreDays}</Typography>
                                                <Typography className="ticketParaCoo">{cooOpenTicketItem.attributes.tickets_open_30_days}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="ticketHeadingCoo">{configJSON.complexHeader}</Typography>
                                                <Typography className="ticketParaCoo">{cooOpenTicketItem.attributes.name}</Typography>
                                                <Typography className="ticketHeadingCoo">{configJSON.ticketDays}</Typography>
                                                <Typography className="ticketParaCoo">{cooOpenTicketItem.attributes.tickets_open_7_days}</Typography>
                                                <Box className="incidentPartCoo">
                                                    <Typography className="ticketHeadingCoo">{configJSON.incident}</Typography>
                                                    <DetailIcon className="detailIconCoo" />
                                                </Box>
                                                <Typography className="ticketParaCoo">33%</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography className="ticketHeadingCoo">{configJSON.topCategories}</Typography>
                                            {cooOpenTicketItem?.attributes.top_3_category?.map((category)=>{
                                                return(
                                            <Chip className="topCategoriesCoo" label={category.name} />
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </OpenTicketCOO>
        );
    };

    renderSalesTotalIncidents = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <SalesTotalIndividual>
                {this.renderHeading()}
                <Box className="listHeading">
                    <Typography className="listPara">
                        {t(configJSON.TotalOpenTickets)}
                    </Typography>
                    <SearchTextField
                        variant="outlined"
                        placeholder="Search building"
                        value={this.state.searchText}
                        onChange={this.handleSearchInputChange}
                        data-testId={"searchInputTestId"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className="searchNameIcon" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box className="headingContainersale">
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId={"complexSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectDatasale"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.complexList.find(each => each.id === data) ? <MenuItem>{this.state.complexList.find(each => each.id === data)?.name}</MenuItem> : <span>{configJSON.SelectComplex}</span>)
                        }}
                    >
                        {this.state.complexList?.map((complexList:Society)=>{
                            return(
                                <MenuItem value={complexList.id}>
                                {t(complexList.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId={"buildingSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectDatasale"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        disabled={ !this.state.complex}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.buildingList.find(each => String(each.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectBuilding}</span>)
                        }}
                    >
                        {this.state.buildingList?.map((buildingList:Society)=>{
                            return(
                                <MenuItem value={buildingList.id}>
                                {t(buildingList.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.category}
                        onChange={this.handleCategoryChange}
                        data-testId={"categorySelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectDatasale"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.categoryList.find(each => String(each.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectCategory}</span>)
                        }}
                    >
                      {this.state.categoryList?.map((categoryList:Category)=>{
                            return(
                                <MenuItem value={categoryList.id}>
                                {t(categoryList.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.factor}
                        onChange={this.handleFactorChange}
                        data-testId={"factorSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectDatasale"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t(configJSON.IncidentFactor)}</em>
                        </MenuItem>
                    </Select>
                    <Select
                        value={this.state.month}
                        onChange={this.handleMonthChange}
                        data-testId={"monthSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectDatasale"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.monthList.find(each => String(each) === String(data))? <MenuItem>{this.state.monthList.find(each => String(each) === String(data))}</MenuItem> : <span>{configJSON.SelectMonth}</span>)
                        }}
                    >
                           {this.state.monthList.map((monthList: number, index: number) => {
                return <MenuItem key={index} value={monthList}>{monthList}</MenuItem>
              })}
                    </Select>
                    <Select
                        value={this.state.year}
                        onChange={this.handleYearChange}
                        data-testId={"yearSelectTestId"}
                        className="selectDatasale"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.yearList.find(each => String(each) === String(data))? <MenuItem>{this.state.yearList.find(each => String(each) === String(data))}</MenuItem> : <span>{configJSON.SelectYear}</span>)
                        }}
                    >
                        {this.state.yearList.map((yearList: number, index: number) => {
                return <MenuItem key={index} value={yearList}>{yearList}</MenuItem>
              })}
                    </Select>
                    <Button
                        variant="contained"
                        className="searchButton"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={this.handleSearchApi}
                    >
                        {t(configJSON.Search)}
                    </Button>
                </Box>
                <Box>
                {this.state.cooOpenTicketData.length===0&&
                 <Grid container className="noDatafoundgrid">
                        <Grid >
                          <Typography className="noDatafound">No Data Found</Typography>
                            </Grid>
                            </Grid>}
                    <Grid container spacing={3}>
                        {this.state.cooOpenTicketData?.map((salesOpenTicketItem: CooOpenTicketItem, salesOpenTicketItemIndex: number) => {
                            return (
                                <>
                                    <Grid item xs={6}>
                                        <Box className="ticketContainersales" >
                                            <Box className="incidentHeader">
                                                <Box className="buildingSection">
                                                    <Typography className="ticketHeadingsales" data-testId={"ticketHeadingsales"}>{configJSON.buildingHeader}</Typography>
                                                    <Box className="buildingData">
                                                        <Typography className="ticketParasales">{salesOpenTicketItem.attributes.building_name}</Typography>
                                                        <LocationIcon className="locationIconsales" />
                                                        <Typography className="locationIconsales">{configJSON.location}</Typography>
                                                    </Box>
                                                    {this.renderMap(salesOpenTicketItem)}
                                                    <Typography className="ticketHeadingsales">{configJSON.openTicket}</Typography>
                                                    <Typography className="ticketParasales">{salesOpenTicketItem.attributes.total_open_tickets}</Typography>
                                                    <Typography className="ticketHeadingsales">{configJSON.ticketMoreDays}</Typography>
                                                    <Typography className="ticketParasales">{salesOpenTicketItem.attributes.tickets_open_30_days}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="ticketHeadingsales">{configJSON.complexHeader}</Typography>
                                                    <Typography className="ticketParasales">{salesOpenTicketItem.attributes.name}</Typography>
                                                    <Typography className="ticketHeadingsales">{configJSON.ticketDays}</Typography>
                                                    <Typography className="ticketParasales">{salesOpenTicketItem.attributes.tickets_open_7_days}</Typography>
                                                    <Box className="incidentPart">
                                                        <Typography className="ticketHeadingsales">{configJSON.incident}</Typography>
                                                        <DetailIcon className="detailIconsales" />
                                                    </Box>
                                                    <Typography className="ticketParasales">33%</Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography className="ticketHeadingsales">{configJSON.topCategories}</Typography>
                                                {salesOpenTicketItem?.attributes.top_3_category?.map((category)=>{
                                                return(
                                            <Chip className="topCategoriessales" label={category.name} />
                                                )
                                            })}
                                            </Box>
                                        </Box>

                                    </Grid>

                                </>
                            )
                        })}
                    </Grid>
                </Box>
            </SalesTotalIndividual>
        );
    };

    renderAccountIncidents = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <AccountOpenIncident>
                {this.renderHeading()}
                <Box className="listHeading">
                    <Typography className="listPara">
                        {t(configJSON.TotalOpenIncidents)}
                    </Typography>
                    <SearchTextField
                        variant="outlined"
                        placeholder="Search building"
                        value={this.state.searchText}
                        onChange={this.handleSearchInputChange}
                        data-testId={"searchInputTestId"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className="searchNameIcon" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box className="headingContainer">
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId={"complexSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (this.state.complexList.find(complexListacc => complexListacc.id === data) ? <MenuItem>{this.state.complexList.find(complexListacc => complexListacc.id === data)?.name}</MenuItem> : <span>{configJSON.SelectComplex}</span>)
                        }}
                    >
                         {this.state.complexList?.map((complexListacc:Society)=>{
                            return(
                                <MenuItem value={complexListacc.id}>
                                {t(complexListacc.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId={"buildingSelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        disabled={ !this.state.complex}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.buildingList.find(buildingListacc => String(buildingListacc.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectBuilding}</span>)
                        }}
                    >
                         {this.state.buildingList?.map((buildingListacc:Society)=>{
                            return(
                                <MenuItem value={buildingListacc.id}>
                                {t(buildingListacc.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.category}
                        onChange={this.handleCategoryChange}
                        data-testId={"categorySelectTestId"}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        renderValue={(data) => {
                            return (data ? <MenuItem>{this.state.categoryList.find(categoryListacc => String(categoryListacc.id) === String(data))?.name}</MenuItem> : <span>{configJSON.SelectCategory}</span>)
                        }}
                    >
                         {this.state.categoryList?.map((categoryListacc:Category)=>{
                            return(
                                <MenuItem value={categoryListacc.id}>
                                {t(categoryListacc.name)}
                            </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        value={this.state.factor}
                        onChange={this.handleFactorChange}
                        data-testId={"factorSelectTestId"}
                        className="selectData"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t(configJSON.IncidentFactor)}</em>
                        </MenuItem>
                    </Select>
                    <Button
                        variant="contained"
                        className="searchButton"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={this.handleSearchApi}
                    >
                        {t(configJSON.Search)}
                    </Button>
                </Box>
                <Box>
                {this.state.cooOpenTicketData.length===0&&
                 <Grid container className="noDatafoundgridacc">
                        <Grid >
                          <Typography className="noDatafoundacc">No Data Found</Typography>
                            </Grid>
                            </Grid>}
                    <Grid container spacing={3}>
                        {this.state.cooOpenTicketData?.map((accountOpenTicketItem: CooOpenTicketItem, accountOpenTicketItemIndex: number) => {
                            return (
                                <>
                                    <Grid item xs={6}>
                                        <Box className="ticketContainer" >
                                            <Box className="incidentHeader">
                                                <Box className="buildingSection">
                                                    <Typography className="ticketHeading" data-testId={"ticketHeading"}>{configJSON.buildingHeader}</Typography>
                                                    <Box className="buildingData">
                                                        <Typography className="ticketPara">{accountOpenTicketItem.attributes.building_name}</Typography>
                                                        <LocationIcon className="locationIcon" />
                                                        <Typography className="locationIcon">{configJSON.location}</Typography>
                                                    </Box>
                                                    {this.renderMap(accountOpenTicketItem)}

                                                    <Typography className="ticketHeading">{configJSON.openTicket}</Typography>
                                                    <Typography className="ticketPara">{accountOpenTicketItem.attributes.total_open_tickets}</Typography>
                                                    <Typography className="ticketHeading">{configJSON.ticketMoreDays}</Typography>
                                                    <Typography className="ticketPara">{accountOpenTicketItem.attributes.tickets_open_30_days}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="ticketHeading">{configJSON.complexHeader}</Typography>
                                                    <Typography className="ticketPara">{accountOpenTicketItem.attributes.name}</Typography>
                                                    <Typography className="ticketHeading">{configJSON.ticketDays}</Typography>
                                                    <Typography className="ticketPara">{accountOpenTicketItem.attributes.tickets_open_7_days}</Typography>
                                                    <Box className="incidentPart">
                                                        <Typography className="ticketHeading">{configJSON.incident}</Typography>
                                                        <DetailIcon className="detailIcon" />
                                                    </Box>
                                                    <Typography className="ticketPara">33%</Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography className="ticketHeading">{configJSON.topCategories}</Typography>
                                                {accountOpenTicketItem?.attributes.top_3_category?.map((category)=>{
                                                return(
                                            <Chip className="topCategories" label={category.name} />
                                                )
                                            })}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Box>
            </AccountOpenIncident>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.state.userRole === "coo" && this.renderCooTotalIncidents()}
                {this.state.userRole === "sales_manager" && this.renderSalesTotalIncidents()}
                {this.state.userRole === "account_manager" && this.renderAccountIncidents()}
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(OpenTickets);
export { OpenTickets }
const OpenTicketCOO = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "50px",
   
    "& .tabActiveCoo":{
        fontWeight: 400,
        fontSize: "16px",
        color: "#2b6fed",
    },
    "& .searchButtonCoo": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        marginLeft: "15px",
        marginTop: "15px",
        backgroundColor:"#2b6fed"
    },
    "& .headingContainerCoo": {
        display: "flex",
        flexWrap: "wrap",
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateLinkCoo": {
        fontWeight: 400,
        fontSize: "16px",
        color: "#3D424B",
        cursor: "pointer",
    },
    "& .noDatafoundgridcoo":{
        display:"flex",
        justifyContent:"center",
        margin:"10% 0%",
    },
    "& .noDatafoundcoo":{
    fontWeight: 400,
    fontSize: "16px",
    color: "#2b6fed",
    },
    "& .incidentPartCoo": {
        display: "flex"
    },
    "& .searchNameIconCoo": {
        color: "#808080"
    },
    "& .incidentHeaderCoo": {
        display: "flex"
    },
    "& .buildingDataCoo": {
        display: "flex"
    },
    "& .listParaCoo": {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27px",
    },
    "& .listHeadingCoo": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .buildingSectionCoo": {
        marginRight: "20px"
    },
    "& .ticketContainerCoo": {
        background: "white",
        borderRadius: "10px",
        padding: "15px",
        marginTop: "20px"
    },
    "& .ticketHeadingCoo": {
        color: "#c3c4c6",
        marginTop: "10px",
    },
    "& .ticketParaCoo": {
        color: "#686b70",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .selectDataCoo": {
        background: "white",
        width: "190px",
        display: "flex",
        marginRight: "15px",
        flexWrap: "wrap",
        marginTop: "15px",
        border: "1px solid #f2f2f2 !important",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
            border: "1px solid #f2f2f2 !important",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px",
            border: "1px solid #f2f2f2 !important",
        }
    },
    "& .locationIconCoo": {
        color: "#fd9855",
        fontWeight: 600,
        cursor: "pointer"
    },
    "& .detailIconCoo": {
        marginTop: "10px",
        color: "#9ba0a6",
        marginLeft: "5px",
        "&.MuiSvgIcon-root": {
            width: "17px"
        }
    },
    "& .topCategoriesCoo": {
        marginTop: "10px",
        marginRight: "10px",
        fontWeight: 600,
        "&.MuiChip-root": {
            color: "#6395f2",
            backgroundColor: "#e6edfc",
            height: "25px",
            width: "95px"
        }
    }
});

const AccountOpenIncident = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .noDatafoundgridacc":{
        display:"flex",
        justifyContent:"center",
        margin:"10% 0%",
    },
    "& .noDatafoundacc":{
    fontWeight: 400,
    fontSize: "16px",
    color: "#2b6fed",
    },
    "& .tabActive":{
        fontWeight: 400,
        fontSize: "16px",
        color: "#2b6fed",
    },
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        marginLeft: "15px",
        marginTop: "15px",
        backgroundColor:"#2b6fed"
    },
    "& .headingContainer": {
        display: "flex",
        flexWrap: "wrap"
    },
    "& .searchNameIcon": {
        color: "#808080"
    },
    "& .incidentHeader": {
        display: "flex"
    },
    "& .buildingData": {
        display: "flex"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateLink": {
        fontWeight: 400,
        fontSize: "16px",
        color: "#3D424B",
    },

    "& .incidentPart": {
        display: "flex"
    },
    "& .companiesText": {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "27px",
        marginTop: "25px",
        marginBottom: "25px",
    },
    "& .listPara": {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27px",
    },
    "& .complexSelect": {
        marginLeft: "15px",
    },
    "& .listHeading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .buildingSection": {
        marginRight: "20px"
    },
    "& .ticketContainer": {
        background: "white",
        borderRadius: "10px",
        padding: "15px",
        marginTop: "20px"
    },
    "& .selectData": {
        background: "white",
        width: "190px",
        display: "flex",
        marginTop: "15px",
        marginRight: "15px",
        flexWrap: "wrap",
        border: "1px solid #f2f2f2 !important",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
            border: "1px solid #f2f2f2 !important",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px",
            border: "1px solid #f2f2f2 !important",
        }
    },
    "& .ticketHeading": {
        color: "#c3c4c6",
        marginTop: "10px",
    },
    "& .ticketPara": {
        color: "#686b70",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .locationIcon": {
        color: "#fd9855",
        fontWeight: 600,
        cursor: "pointer"
    },
    "& .detailIcon": {
        marginTop: "10px",
        color: "#9ba0a6",
        marginLeft: "5px",
        "&.MuiSvgIcon-root": {
            width: "17px"
        }
    },
    "& .topCategories": {
        marginTop: "10px",
        marginRight: "10px",
        fontWeight: 600,
        "&.MuiChip-root": {
            color: "#6395f2",
            backgroundColor: "#e6edfc",
            height: "25px",
            width: "95px"
        }
    }
});

const SearchTextField = styled(TextField)({
    width: "260px",
    background: "white",
    borderRadius: "10px",
    border: "1px solid #f2f2f2",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 0px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "10px"
    }
});

const SalesTotalIndividual = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .noDatafoundgrid":{
        display:"flex",
        justifyContent:"center",
        margin:"10% 0%",
    },
    "& .noDatafound":{
    fontWeight: 400,
    fontSize: "16px",
    color: "#2b6fed",
    },
    "& .tabActive":{
        fontWeight: 400,
        fontSize: "16px",
        color: "#2b6fed",
    },
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        marginLeft: "15px",
        marginTop: "15px",
        backgroundColor:"#2b6fed"
    },
    "& .searchNameIcon": {
        color: "#808080"
    },
    "& .incidentHeader": {
        display: "flex"
    },
    "& .buildingData": {
        display: "flex"
    },
    "& .headingContainersale": {
        display: "flex",
        flexWrap: "wrap"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateLink": {
        fontWeight: 400,
        fontSize: "16px",
        color: "#3D424B",
    },
    "& .companiesText": {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "27px",
        marginTop: "25px",
        marginBottom: "25px",
    },
    "& .selectDatasale": {
        background: "white",
        width: "190px",
        display: "flex",
        marginRight: "15px",
        flexWrap: "wrap",
        marginTop: "15px",
        border: "1px solid #f2f2f2 !important",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
            border: "1px solid #f2f2f2 !important",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px",
            border: "1px solid #f2f2f2 !important",
        }
    },
    "& .incidentPart": {
        display: "flex"
    },
    "& .listPara": {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27px",
    },
    "& .complexSelect": {
        marginLeft: "15px",
    },
    "& .listHeading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .buildingSection": {
        marginRight: "20px"
    },
    "& .ticketContainersales": {
        background: "white",
        borderRadius: "10px",
        padding: "15px",
        marginTop: "20px"
    },
    "& .ticketHeadingsales": {
        marginTop: "10px",
        color: "#c3c4c6",
    },
    "& .detailIconsales": {
        color: "#9ba0a6",
        marginLeft: "5px",
        marginTop: "10px",
        "&.MuiSvgIcon-root": {
            width: "17px"
        }
    },
    "& .locationIconsales": {
        color: "#fd9855",
        fontWeight: 600,
        cursor: "pointer"
    },
    "& .ticketParasales": {
        fontSize: "16px",
        color: "#686b70",
        fontWeight: 600
    },
    "& .topCategoriessales": {
        marginTop: "10px",
        fontWeight: 600,
        marginRight: "10px",
        "&.MuiChip-root": {
            height: "25px",
            color: "#6395f2",
            width: "95px",
            backgroundColor: "#e6edfc",
        }
    }
});

// Customizable Area End