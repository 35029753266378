// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";

import React from "react";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import { dataURLtoFile, toDataURL } from "../../../../components/src/UtilFunction.web";
import toast from "react-hot-toast";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  companyId: string | number;
}

export type FileType = string | ArrayBuffer | null;

export interface FormValue {
  unitNumber: string;
  floorNumber: string;
  unitStatus: string;
  size: string;
  config: string;
  price: string;
  date: string;
  valuation: string;
  images: FileType[];

  rentStatus: string;
  rentAmount: string;
  rentFrom: string;
  rentTo: string;
}

interface FamilyFormValue {
  name: string;
  relation: string;
  idProof: string;
  idNumber: string;
}

export interface FamilyList {
  id: number;
  attributes: {
    name: string;
    id_number: string;
    relation: {
      name: string;
    };
  };
}

interface OwnerDetails {
  id: number;
  attributes: {
    apartment_name: string;
    floor_number: string;
    unit_type: string;
    size: string;
    configuration: string;
    purchase_price: string;
    purchase_date: string;
    photos: { url: string }[];
    current_valuation: string;
    status: string;
    rent_status: {
      rent_charges: string;
      contract_expiry: string;
      tenant_id: string | number;
    };
    owner: {
      id: string | number;
    };
    resident: {
      id: string | number;
    };
  };
}

interface SelectOption {
  label: string;
  value: string;
}

interface FindPersonSearch {
  id: string;
  passport: string;
  email: string;
  phone: string;
}

interface UserDetail {
  name: string;
  complex: string;
  idNumber: string | number;
  idUrl: string;
  email: string;
  address: string;
}

interface UserList {
  id: number;
  attributes: {
    full_name: string;
    full_phone_number: string;
    email: string;
  };
}

interface S {
  loading: boolean;
  ownerModal: boolean;
  isFamilyDialogOpen: boolean;

  showError: boolean;
  error: string;

  accountType: string;
  complexId: string;
  unitId: string;

  configList: SelectOption[];
  relationList: SelectOption[];
  idProofList: SelectOption[];

  ownerId: string | number;
  residentId: string | number;
  tenantId: string | number;
  formValue: FormValue;

  familyFormValue: FamilyFormValue;
  familyList: FamilyList[];

  findPersonSearch: FindPersonSearch;
  userList: UserList[];
  userDetail: UserDetail;
  isUserDetailOpen: boolean;
  selectedType: string;
}

interface SS {
  id: any;
}

export default class ComplexAddUnitFormController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetConfigurationListCallId: string = "";
  GetFamilyListCallId: string = "";
  DeleteFamilyMemberCallId: string = "";
  GetRelationListCallId: string = "";
  GetIDProofListCallId: string = "";
  GetFindPersonListCallId: string = "";
  GetFindPersonDetailCallId: string = "";
  AddNewUnitCallId: string = "";
  CreateFamilyCallId: string = "";
  GetUnitDetailsCallId: string = "";
  EditUnitCallId: string = "";

  uploadImages: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.uploadImages = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      loading: false,
      ownerModal: false,
      isFamilyDialogOpen: false,

      accountType: "",
      complexId: "",
      unitId: "",

      showError: false,
      error: "",

      configList: [],
      relationList: [],
      idProofList: [],

      ownerId: "",
      residentId: "",
      tenantId: "",
      formValue: {
        unitNumber: "",
        floorNumber: "",
        unitStatus: "",
        size: "",
        config: "",
        price: "",
        date: "",
        valuation: "",
        images: [],

        rentStatus: "",
        rentAmount: "",
        rentFrom: "",
        rentTo: "",
      },

      familyFormValue: {
        name: "",
        relation: "",
        idProof: "",
        idNumber: "",
      },
      familyList: [],

      findPersonSearch: {
        id: "",
        passport: "",
        email: "",
        phone: "",
      },
      userList: [],
      userDetail: {
        name: "",
        complex: "",
        idNumber: "",
        idUrl: "",
        email: "",
        address: "",
      },
      isUserDetailOpen: false,
      selectedType: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetConfigurationListCallId:
          return this.handleConfigurationListResponse(responseJson);
        case this.CreateFamilyCallId:
          return this.handleCreateFamilyListResponse();
        case this.DeleteFamilyMemberCallId:
          return this.handleDeleteFamilyMemberResponse();
        case this.GetFamilyListCallId:
          return this.handleGetFamilyListResponse(responseJson);
        case this.GetRelationListCallId:
          return this.handleGetRelationListResponse(responseJson);
        case this.GetIDProofListCallId:
          return this.handleGetIDProofListResponse(responseJson);
        case this.GetFindPersonListCallId:
          return this.handleGetFindPersonListResponse(responseJson);
        case this.GetFindPersonDetailCallId:
          return this.handleGetFindPersonDetailResponse(responseJson);
        case this.AddNewUnitCallId:
          return this.handleAddNewUnitResponse();
        case this.GetUnitDetailsCallId:
          return this.handleGetUnitDetailsResponse(responseJson);
        case this.EditUnitCallId:
          return this.handleEditUnitResponse();
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    const accountRole = localStorage.getItem("role") || "";
    const complex_id = this.props.navigation.getParam("id");
    const unit_id = this.props.navigation.getParam("uId");
    this.setState({ accountType: accountRole, complexId: complex_id, unitId: unit_id }, () => {
      this.getConfigurationList();
      this.getRelationList();
      this.getIDProofList();

      if (unit_id) {
        this.getUnitDetails(unit_id);
      }
    });
  }

  getUnitDetails = async (id: string | number) => {
    this.GetUnitDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/${id}`,
    });
  };

  openEditUnit = async (photos: { url: string }[]) => {
    this.setState({ loading: true });

    const imageUrlPromise: unknown[] = photos.map(async (image: { url: string }) => {
      return new Promise(async (resolve, reject) => {
        let blobString = await toDataURL(image.url);
        resolve(blobString);
      });
    });
    return await Promise.allSettled(imageUrlPromise);
  };

  handleGetUnitDetailsResponse = async (responseJson: { data: OwnerDetails }) => {
    if (responseJson && responseJson.data) {
      const unit = responseJson.data;
      const images: PromiseSettledResult<unknown>[] = await this.openEditUnit(unit.attributes.photos);
      this.handleSetFormValue(unit, images);
    }
  };

  handleSetFormValue = (unit: OwnerDetails, images: PromiseSettledResult<unknown>[]) => {
    this.setState(
      {
        loading: false,
        formValue: {
          unitNumber: unit.attributes.apartment_name,
          floorNumber: unit.attributes.floor_number,
          unitStatus: unit.attributes.unit_type,
          size: unit.attributes.size,
          config: unit.attributes.configuration,
          price: unit.attributes.purchase_price,
          date: unit.attributes.purchase_date,
          valuation: unit.attributes.current_valuation,
          images: images.map((image: unknown) => (image as { value: string }).value),

          rentStatus: unit.attributes.status,
          rentAmount: unit.attributes.rent_status.rent_charges,
          rentFrom: "",
          rentTo: unit.attributes.rent_status.contract_expiry,
        },
        ownerId: unit.attributes.owner?.id,
        residentId: unit.attributes.resident?.id,
        tenantId: unit.attributes.rent_status.tenant_id,
      },
      () => {
        if (this.state.ownerId || this.state.residentId) {
          this.getFamilyList(this.state.ownerId || this.state.residentId);
        }
      }
    );
  };

  getConfigurationList = async () => {
    this.GetConfigurationListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/unit_configuration`,
    });
  };

  handleConfigurationListResponse = (responseJson: { configuration: { id: number | string; title: string }[] }) => {
    if (responseJson && responseJson.configuration) {
      this.setState({
        configList: responseJson.configuration.map((config) => ({ label: config.title, value: config.id + "" })),
      });
    }
  };

  deleteFamilyMember = (familyId: number | string) => {
    this.setState({ loading: true }, async () => {
      this.DeleteFamilyMemberCallId = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_family/families/${familyId}`,
      });
    });
  };

  handleDeleteFamilyMemberResponse = () => {
    this.setState({ loading: false }, () => {
      this.getFamilyList(this.state.ownerId);
    });
  };

  getFamilyList = async (ownerId: string | number) => {
    this.GetFamilyListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_family/family_listing?owner_id=${ownerId}`,
    });
  };

  handleGetFamilyListResponse = (responseJson: { data: FamilyList[] }) => {
    this.setState({ familyList: responseJson.data });
  };

  createFamily = async (values: FamilyFormValue) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("relation_id", values.relation);
    formData.append("id_proof_id", values.idProof);
    formData.append("id_number", values.idNumber);
    formData.append("account_id", this.state.ownerId + "");

    this.CreateFamilyCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_family/families`,
      body: formData,
    });
  };

  handleCreateFamilyListResponse = () => {
    this.setState({ loading: false }, () => {
      this.getFamilyList(this.state.ownerId);
    });
  };

  getRelationList = async () => {
    this.GetRelationListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_family/relations`,
    });
  };

  handleGetRelationListResponse = (responseJson: { relaions: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.relaions) {
      this.setState({
        relationList: responseJson.relaions.map((relation) => ({ label: relation.name, value: relation.id + "" })),
      });
    }
  };

  getIDProofList = async () => {
    this.GetIDProofListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_family/id_proofs`,
    });
  };

  handleGetIDProofListResponse = (responseJson: { relaions: { id: number | string; name: string }[] }) => {
    if (responseJson && responseJson.relaions) {
      this.setState({
        idProofList: responseJson.relaions.map((relation) => ({ label: relation.name, value: relation.id + "" })),
      });
    }
  };

  getFindPersonList = async () => {
    const { id, email, passport, phone } = this.state.findPersonSearch;
    this.GetFindPersonListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/1/bx_block_contract/employee_contracts/find_person?id_number=${id}&phone_number=${phone}&email=${email}&passport=${passport}`,
    });
  };

  handleGetFindPersonListResponse = (responseJson: { data: UserList[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ userList: responseJson.data });
    }
  };

  getFindPersonDetail = (id: string | number) => {
    this.setState({ isUserDetailOpen: true }, async () => {
      this.GetFindPersonDetailCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `society_managements/1/bx_block_contract/employee_contracts/find_person_details?id=${id}`,
      });
    });
  };

  handleGetFindPersonDetailResponse = (responseJson: { data: UserList }) => {
    if (responseJson && responseJson.data) {
      const user = responseJson.data;
      this.setState({
        userDetail: {
          name: user.attributes.full_name,
          complex: "",
          idNumber: user.id,
          idUrl: "",
          email: user.attributes.email,
          address: "",
        },
      });
    }
  };

  handleOpenOwnerModal = (type: string) => {
    this.setState({
      ownerModal: true,
      selectedType: type,
      findPersonSearch: {
        id: "",
        passport: "",
        email: "",
        phone: "",
      },
      userList: [],
      userDetail: {
        name: "",
        complex: "",
        idNumber: "",
        idUrl: "",
        email: "",
        address: "",
      },
      isUserDetailOpen: false,
    });
  };

  handleCloseOwnerModal = () => {
    this.setState({ ownerModal: false });
  };

  handleSelectUser = () => {
    if (this.state.selectedType !== "") {
      const newState = {
        ownerModal: false,
        [this.state.selectedType]: this.state.userDetail.idNumber + "",
      };

      this.setState({ ...this.state, ...newState }, () => {
        if (this.state.selectedType === "ownerId") {
          this.getFamilyList(this.state.userDetail.idNumber);
        }
      });
    }
  };

  addNewUnit = async (values: FormValue) => {
    if ((this.state.ownerId === "" || this.state.residentId === "") && this.state.tenantId === "") {
      this.setState({ loading: false, showError: true, error: "please provide owner, resident and tenant detail" });
      return;
    }

    const formData = new FormData();
    formData.append("[data][building_management_id]", this.state.complexId);
    formData.append("[data][apartment_name]", values.unitNumber);
    formData.append("[data][tenant_id]", this.state.tenantId + "");
    formData.append("[data][rent_amount]", values.rentAmount);
    formData.append("[data][start_date]", values.rentTo);
    formData.append("[data][expires_on]", values.rentFrom);
    formData.append("[data][account_id]", this.state.ownerId + "");
    formData.append("[data][floor_number]", values.floorNumber);
    formData.append("[data][size]", values.size);
    formData.append("[data][purchase_price]", values.price);
    formData.append("[data][configuration]", values.config);
    formData.append("[data][purchase_date]", values.date);
    formData.append("[data][current_valuation]", values.valuation);
    formData.append("[data][unit_type]", values.unitStatus);
    formData.append("[data][monthly_renting_income]", values.rentAmount);
    values.images.forEach((image: FileType) => {
      formData.append("data[photos]", dataURLtoFile(image));
    });

    this.AddNewUnitCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_request_management/create_request`,
      body: formData,
    });
  };

  handleAddNewUnitResponse = () => {
    this.setState({ loading: false }, () => {
      toast.success("Unit added successfully");
    });
  };

  handleEditUnit = async (values: FormValue) => {
    if ((this.state.ownerId === "" || this.state.residentId === "") && this.state.tenantId === "") {
      this.setState({ loading: false, showError: true, error: "please provide owner, resident and tenant detail" });
      return;
    }

    const formData = new FormData();
    formData.append("apartment_management[apartment_name]", values.unitNumber);
    formData.append("apartment_management[floor_number]", values.floorNumber);
    formData.append("apartment_management[size]", values.size);
    formData.append("apartment_management[purchase_price]", values.price);
    formData.append("apartment_management[unit_type]", values.unitStatus);
    formData.append("apartment_management[configuration]", values.config);
    formData.append("apartment_management[purchase_date]", values.date);
    formData.append("apartment_management[current_valuation]", values.valuation);
    formData.append("apartment_management[owner_name]", "");
    formData.append("apartment_management[resident_name]", "");
    formData.append("apartment_management[status]", values.rentStatus);
    formData.append("apartment_management[monthly_renting_income]", values.rentAmount);
    formData.append("apartment_management[society_management_id]", this.state.complexId);
    formData.append("apartment_management[building_management_id]", "");
    values.images.forEach((image: FileType) => {
      formData.append("apartment_management[photos][]", dataURLtoFile(image));
    });

    this.EditUnitCallId = await apiCall({
      method: "PUT",
      endPoint: `bx_block_settings/apartment_managements/${this.state.unitId}`,
      body: formData,
    });
  };

  handleEditUnitResponse = () => {
    this.setState({ loading: false }, () => {
      toast.success("Unit updated successfully");
      this.handleCancel();
    });
  };

  handleCancel = () => {
    this.props.navigation.navigate("ComplexBuildingDetails", { id: this.props.companyId });
  };

  handleFamilyDialog = () => {
    this.setState({ isFamilyDialogOpen: !this.state.isFamilyDialogOpen });
  };

  handleSubmitForm = (values: FormValue, resetForm: () => void) => {
    if (this.state.unitId) {
      this.handleEditUnit(values);
    } else {
      this.addNewUnit(values);
      resetForm();
    }
  };

  editFamilyMemberValidation = Yup.object().shape({
    name: Yup.string().required("Required").matches(/\S/, "Required"),
    relation: Yup.string().required("Required").matches(/\S/, "Required"),
    idProof: Yup.string().required("Required").matches(/\S/, "Required"),
    idNumber: Yup.string().required("Required").matches(/\S/, "Required"),
  });

  validationSchema = Yup.object().shape({
    unitNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    floorNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    unitStatus: Yup.string().required("Required").matches(/\S/, "Required"),
    rentStatus: Yup.string().required("Required").matches(/\S/, "Required"),
    rentFrom: Yup.string().required("Required").matches(/\S/, "Required"),
    rentTo: Yup.string().required("Required").matches(/\S/, "Required").nullable(),
    config: Yup.string(),
    rentAmount: Yup.string().required("Required").matches(/^\d+$/, "Only digit allowed").nullable(),
    valuation: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    price: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    size: Yup.string().required("Required").matches(/^\d+$/, "Only digit allowed"),
  });
}
// Customizable Area End
