// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpComplexBlockStep1Controller, { Props } from "./CompEmpBuildingBlockStep1Controller.web";
import "../../assets/commonForm.css";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";

export default class CompEmpBuildingBlockStep1 extends CompEmpComplexBlockStep1Controller {
  constructor(props: Props) {
    super(props);
  }

  handleDetails = (t: (val: string) => string, label: string, value: string) => {
    return (
      <Grid item xs={4}>
        <Typography className="typographyLable">{t(label)}</Typography>
        <Box className="fontSizeBox">{value ? value : "(N/A)"}</Box>
      </Grid>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box className="topBarFormik">
          <Box
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            className="building_topbox_flex"
          >
            <Box className="building_topbox_heading">
              <Typography className="Basic bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
                {t("Jurisdiction")}
              </Typography>
            </Box>
            <Box className="building_topbox_heading">
              <Typography style={{ fontSize: "16px" }}>
                {t("Step")} {this.props.step + 1} {t("of 7")}
              </Typography>
            </Box>
          </Box>

          <Box style={{ marginTop: "20px" }}>
            <NormalSelect
              t={t}
              option={this.state.jurisdictionList}
              label="Jurisdiction"
              language={language}
              name="type"
              data-test-id="building-jurisdiction-select"
              onChange={this.changeJurisdiction}
              value={this.props.jurisdictionSelected}
            />
          </Box>

          <Box style={{ marginTop: "25px" }}>
            <Grid container spacing={2}>
              {this.handleDetails(t, "Currency", this.state.jurisdictionBuildingDetail.currency)}
              {this.handleDetails(t, "Timezone", this.state.jurisdictionBuildingDetail.timezone)}
              {this.handleDetails(t, "Unit of Measurement", this.state.jurisdictionBuildingDetail.measurement)}
              {this.handleDetails(
                t,
                "Management Fee Calculation Formula",
                this.state.jurisdictionBuildingDetail.formula
              )}
              {this.handleDetails(t, "Mobile number length", this.state.jurisdictionBuildingDetail.mobileLength)}
              {this.handleDetails(t, "Default Renting Contract", this.state.jurisdictionBuildingDetail.contract)}
            </Grid>
          </Box>
        </Box>
        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
          <Grid style={{ marginTop: "30px" }}>
            <Button
              data-test-id="jurisdiction-next"
              onClick={this.props.handleNext}
              style={webBuildingStyle.buttonSave}
              disabled={!this.props.jurisdictionSelected}
            >
              <span className="bold-text">{t("NEXT")}</span>
            </Button>
          </Grid>
        </Box>
      </>
    );
  }
}

const webBuildingStyle: any = {
  buttonSave: {
    justifyContent: "center",
    height: "56px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "0px 20.5px",
    width: "177px",
  },
};

// Customizable Area End
