//Customizable Area Start
import React from "react";
import { Box, Typography, Grid, styled, Breadcrumbs, Paper, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Popover, MenuItem, IconButton, Divider, } from "@material-ui/core";
import DashboardCompanyEmployeeController, {
    Props, DashboardTebleDataItem, TFunction, I18Interface
} from "./DashboardCompanyEmployeeController.web";
import MainScreen from "./MainScreen.web";
import { companiesIcon, complexIcon, ticketIcon, userIcon } from "../assets";
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { withTranslation } from "react-i18next";
import AlertError from "../../../../components/src/AlertError.web";
import Loader from "../../../../components/src/Loader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
class DashboardCompanyEmployee extends DashboardCompanyEmployeeController {
    constructor(props: Props) {
        super(props);
    }

    renderDashboardTable = () => {
        const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
        const language = i18n.language;
        const currentPage=this.state.pagination?.current_page as number
        return (
            <>
                <Paper>
                    <Box className="tableHeaderContainer">
                        <Typography dir={languageCondition(language, "rtl", "ltr")} className="tableHeading">{t("Subscription ending in 60 days")}</Typography>
                        <TextField
                            fullWidth
                            placeholder="Search complex/ building"
                            variant="outlined"
                            InputProps={{ startAdornment: <SearchIcon className="searchIcon" /> }}
                            className="searchInput"
                            onChange={this.handleSearchInput}
                            data-testId={"tableSearchInputTestId"}
                            value={this.state.searchInputText}
                        />
                    </Box>
                    <Box>
                        <TableContainer>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="tableCellHeading">#</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Complex/Building")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Chairman")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("City")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Ending on")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Ending In")}</TableCell>
                                        <TableCell align="left" className="tableCellHeading"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.dashboardTebleData.map((dashboardTableData: DashboardTebleDataItem, dashboardTableDataIndex: number) => (
                                        <TableRow >
                                            <TableCell align="left">{(currentPage - 1) * 10 + dashboardTableDataIndex + 1}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.complex === null ? "-": dashboardTableData.attributes.complex.name}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.chairman  === null ? "-": dashboardTableData.attributes.chairman}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.city  === null ? "-": dashboardTableData.attributes.city}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.account_manger  === null ? "-": dashboardTableData.attributes.account_manger}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.ending_in === null ? "-" : dashboardTableData.attributes.ending_on}</TableCell>
                                            <TableCell align="left">{dashboardTableData.attributes.ending_in  === null ? "-": dashboardTableData.attributes.ending_in}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={(event) => this.handleClick(event, dashboardTableDataIndex)} data-testId={`dashboardTableMenu${dashboardTableDataIndex}`}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Popover
                                                    open={this.state.openPopoverIndex === dashboardTableDataIndex}
                                                    anchorEl={this.state.anchorEl}
                                                    onClose={this.handleClose}
                                                    data-testId={`menuPopoverCloseTestId${dashboardTableDataIndex}`}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    elevation={1}
                                                >
                                                    <MenuItem data-testId={`pushEmailTableMenuOptionTestId${dashboardTableDataIndex}`} dir={languageCondition(language, "rtl", "ltr")}>
                                                        {t("Push Email Notification")}
                                                    </MenuItem>
                                                    <Divider className="dividerLine" />
                                                    <MenuItem dir={languageCondition(language, "rtl", "ltr")}>
                                                        {t("Go to Management Team")}
                                                    </MenuItem>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box className="tablePaginationContainer">
                        <PaginationModule pagination={this.state.pagination} handlePagination={this.handlePagination} page={this.state.pagination?.current_page} data-testId={"subscriptionListPaginationTestId"} />
                    </Box>
                </Paper>
            </>
        )
    };

    renderDashboard = () => {
        const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
        const language = i18n.language;
        return <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("MyTeamDashboard")} data-testId={"myTeamBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={companiesIcon} alt="" />
                        </Box>
                        <Typography className="cardText">
                            {this.state.userRole === "sales_manager" && t("Total Companies")}
                            {this.state.userRole === "account_manager" && t("Total Assigned Companies")}
                            {this.state.userRole === "coo" && t("Total Companies")}
                        </Typography>
                        <Typography className="cardText" data-testId={"totalCompaniesTextTestId"}>{this.state.dashboardData.data.Total_company}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCardComplexes" onClick={() => this.handleNavigation("TotalComplexesDashboard")} data-testId={"totalComplexBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={complexIcon} alt="" />
                        </Box>
                        <Typography className="cardText">
                            {this.state.userRole === "sales_manager" && t("Total Complexes")}
                            {this.state.userRole === "account_manager" && t("Total Assigned Complexes")}
                            {this.state.userRole === "coo" && t("Total Complexes")}
                        </Typography>
                        <Typography className="cardText">{this.state.dashboardData.data.Total_complex}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCardIndividualbuilding" onClick={() => this.handleNavigation("IndivisualBuildingDashboard")} data-testId={"totalAssignedBuildingBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={complexIcon} alt="" />
                        </Box>
                        <Typography className="cardText">
                            {this.state.userRole === "sales_manager" && t("Total Individual Buildings")}
                            {this.state.userRole === "account_manager" && t("Total Assigned Individual Buildings")}
                            {this.state.userRole === "coo" && t("Total Individual Buildings")}
                        </Typography>
                        <Typography className="cardText">{this.state.dashboardData.data.Total_individual_building}</Typography>
                    </Box>
                </Grid>
                {this.state.userRole !== "account_manager" &&
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box className="dashboardCard" onClick={() => this.handleNavigation("SalesManagerDashboard")} data-testId={"totalAssignedSalesManagerBoxTestId"}>
                            <Box className="dashboardCardIconContainer">
                                <img src={userIcon} alt="" />
                            </Box>
                            <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Assigned Sales Managers")}</Typography>
                            <Typography className="cardText">{this.state.dashboardData.data.Assigned_sales_manager}</Typography>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("AccountManagerDashboard")} data-testId={"assignedAccountManagerBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={userIcon} alt="" />
                        </Box>
                        <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Assigned Account Managers")}</Typography>
                        <Typography className="cardText">{this.state.dashboardData.data.Assigned_account_manager}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("OpenTicketsDashboard")} data-testId={"openTicketBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={ticketIcon} alt="" />
                        </Box>
                        <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Open Tickets")}</Typography>
                        <Typography className="cardText">{this.state.dashboardData.data.Total_open_tickets}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("RegisteredOwner")} data-testId={"registeredOwnerBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={userIcon} alt="" />
                        </Box>
                        <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Registered Owners")}</Typography>
                        <Typography className="cardText"><span className="orangeText">{this.state.dashboardData.data.Total_owner_account}</span> {t("of")} {this.state.dashboardData.data.Total_account}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("RegisteredResidents")} data-testId={"registredResidnetsBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={userIcon} alt="" />
                        </Box>
                        <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Registered Residents")}</Typography>
                        <Typography className="cardText"><span className="orangeText">{this.state.dashboardData.data.Total_resident_account}</span> {t("of")} {this.state.dashboardData.data.Total_account}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box className="dashboardCard" onClick={() => this.handleNavigation("PropertyManagerDashboard")} data-testId={"propertyManagerBoxTestId"}>
                        <Box className="dashboardCardIconContainer">
                            <img src={userIcon} alt="" />
                        </Box>
                        <Typography className="cardText" dir={languageCondition(language, "rtl", "ltr")}>{t("Registered Property Managers")}</Typography>
                        <Typography className="cardText"><span className="orangeText">{this.state.dashboardData.data.Total_manager_account}</span> {t("of")} {this.state.dashboardData.data.Total_manager_account}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    };

    render() {
        const { t, i18n }: { t: TFunction, i18n: I18Interface } = this.props;
        const language = i18n.language;
        return (
            <>
                <MainScreen>
                    <DashboardCompanyEmployeeContainer>
                        <Breadcrumbs className="issueNewLeaseHeading">
                            <Typography onClick={() => this.handleNavigation("LeaseManagment")} data-testId={"dashboardHeadingTestId"}  dir={languageCondition(language, "rtl", "ltr")}>
                                {t("My Dashboards")}
                            </Typography>
                            <Typography color="textPrimary" className="dashboardText" dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                        </Breadcrumbs>
                        <Box>
                            <Typography className="heading" dir={languageCondition(language, "rtl", "ltr")}>{t("Dashboard")}</Typography>
                        </Box>
                        {this.renderDashboard()}
                        {this.renderDashboardTable()}
                        <AlertError show={this.state.showErrorMessage} handleClose={this.handleCloseError} message={this.state.errorMessage} data-testId={"errorAlertTestId"} />
                        <Loader loading={this.state.loader}/>
                    </DashboardCompanyEmployeeContainer>
                </MainScreen>
            </>
        )
    }
}
export default withTranslation()(DashboardCompanyEmployee);
export {DashboardCompanyEmployee}

const DashboardCompanyEmployeeContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",
    "& .dashboardCard": {
        backgroundColor: "#fff",
        height: "180px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 2px #eee",
        padding: "20px",
        cursor: "pointer"
    },
    "& .dashboardCardComplexes":{
        height: "180px",
        backgroundColor: "#fff",
        flexDirection: "column",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "20px",
        boxShadow: "0px 0px 10px 2px #eee",
        borderRadius: "10px",
        cursor: "pointer"
    },
    "& .dashboardCardIndividualbuilding":{
        flexDirection: "column",
        height: "180px",
        alignItems: "flex-start",
        display: "flex",
        padding: "20px",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 2px #eee",
        cursor: "pointer"
    },
    "& .dashboardCardIconContainer": {
        border: "1px solid #F8FAFF",
        borderRadius: "50%",
        padding: "10px",
        width: "35px",
        height: "35px",
        display: "flex",
        justifyContent: "center",
    },
    "& .cardText": {
        color: "#181d25",
        fontWeight: 800,
        fontSize: "20px"
    },
    "& .orangeText": {
        color: "#fc8434"
    },
    "& .heading": {
        fontSize: "26px",
        fontWeight: 900,
        color: "#Dashboard",
        padding: "20px 0px"
    },
    "& .searchInput": {
        width: "350px",
        border: "1px solid #979797",
        borderRadius: "8px",
        "& .MuiOutlinedInput-inputAdornedStart": {
            paddingLeft: "50px"
        }
    },
    "& .searchIcon": {
        color: "#979797"
    },
    "& .tableHeaderContainer": {
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px"
    },
    "& .tableHeading": {
        fontSize: "20px",
        color: "#181d25",
        fontWeight: 900
    },
    "& .tableCellHeading": {
        fontWeight: 900,
    },
    "& .dashboardText": {
        color: "#537ad2"
    },
    "& .issueNewLeaseHeading": {
        color: "#181d25"
    },
    "& .tablePaginationContainer": {
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
});
//Customizable Area End


