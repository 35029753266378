// Customizable Area Start
import React from "react";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { IBlock } from "framework/src/IBlock";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  step: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  handleNext: any;
  handleBack: any;
  jurisdiction: any;

  complexID: any;
  updateComplexId: any;
}

interface IComplexForm {
  logo: any;
  complexName: string;
  totalArea: string;
  measurement: string;
  totalBuilding: string;
  totalUnit: string;
  totalFloors: string;
  totalUnits: string;
  aboutUs: string;
  currency: string;
  images: any[];
  accountManager: string;
  floorsPerUnits: string;

  rentalSelected: string | number;
}

interface S {
  loading: boolean;

  currencyList: any[];
  managerList: any[];
  leaseList: any[];
  measurementList: any[];

  complexValues: IComplexForm;

  showError: boolean;
  error: string;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCurrencyListCallId: string = "";
  GetAccountManagerListCallId: string = "";
  GetLeaseListCallId: string = "";
  AddEditComplexCallId: string = "";
  GetComplexDetailApiCallId: string = "";
  uploadImages: any;

  constructor(props: Props) {
    super(props);
    this.uploadImages = React.createRef();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      currencyList: [],
      managerList: [],
      leaseList: [],
      measurementList: [
        {
          label: "Sq ft",
          value: "Sqft",
        },
        {
          label: "Sq m",
          value: "Sq m",
        },
      ],

      complexValues: {
        logo: null,
        complexName: "",
        totalArea: "",
        measurement: "Sqft",
        images: [],
        totalBuilding: "",
        totalUnit: "",
        totalFloors: "",
        floorsPerUnits: "",
        totalUnits: "",
        aboutUs: "",
        currency: "",
        accountManager: "",

        rentalSelected: "",
      },

      showError: false,
      error: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCurrencyListCallId:
          this.handleCurrencyListResponse(responseJson);
          break;
        case this.GetAccountManagerListCallId:
          this.handleAccountManagerListResponse(responseJson);
          break;
        case this.GetLeaseListCallId:
          this.handleLeaseListResponse(responseJson);
          break;
        case this.AddEditComplexCallId:
          this.handleAddEditComplexResponse(responseJson);
          break;
        case this.GetComplexDetailApiCallId:
          this.handleComplexDetailResponse(responseJson);
          break;
        default:
          break;
      }

      this.setState({ loading: false });

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    await this.getCurrencyList();
    await this.getAccountManagerList();
    await this.getLeaseList();

    if (this.props.complexID) {
      this.getComplexDetails();
    }
  }

  getComplexDetails = () => {
    this.setState({ loading: true }, async () => {
      this.GetComplexDetailApiCallId = await apiCall({
        method: "GET",
        endPoint: `bx_block_society_management/society_managements/${this.props.complexID}`,
        contentType: "application/json",
      });
    });
  };

  handleComplexDetailResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ loading: true }, async () => {
        const complex = responseJson.data;
        let photos: any[] = [];
        if (complex.attributes.photos.length > 0) {
          photos = await handleImages(complex.attributes.photos);
        }
        let logo: any = null;
        if (complex.attributes.logo) {
          const logos = await handleImages([complex.attributes.logo]);
          logo = logos[0];
        }

        this.setState({
          loading: false,
          complexValues: {
            logo: logo,
            complexName: complex.attributes.name,
            totalArea: complex.attributes.complex_area,
            measurement: complex.attributes.measurement_unit,
            images: photos,
            totalBuilding: complex.attributes.total_buildings_custum,
            totalUnit: complex.attributes.total_units_custum,
            totalFloors: "",
            floorsPerUnits: "",
            totalUnits: complex.attributes.total_units_custum,
            aboutUs: complex.attributes.description,
            currency: complex.attributes.currency_id,
            accountManager: complex.attributes.account_manager_id,
            rentalSelected: complex.attributes.default_template_id,
          },
        });
      });
    }
  };

  getCurrencyList = async () => {
    this.GetCurrencyListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_posts/classifieds/currency_list`,
    });
  };

  handleCurrencyListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        currencyList: responseJson.data.map((item: any) => ({ label: item.attributes.currency, value: item.id })),
      });
    }
  };

  getAccountManagerList = async () => {
    this.GetAccountManagerListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/account_manager_list`,
    });
  };

  handleAccountManagerListResponse = (responseJson: { account_managers: any[] }) => {
    if (responseJson && responseJson.account_managers) {
      this.setState({
        managerList: responseJson.account_managers.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getLeaseList = async () => {
    this.GetLeaseListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contract/lease_templates`,
    });
  };

  handleLeaseListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ leaseList: responseJson.data }, () => {
        if (responseJson.data.length > 0) {
          this.setState({
            complexValues: {
              ...this.state.complexValues,
              rentalSelected: responseJson.data[0].id,
            },
          });
        }
      });
    }
  };

  handleSubmitComplex = async (values: IComplexForm) => {
    const data = new FormData();
    data.append("society_management[name]", values.complexName);
    data.append("society_management[description]", values.aboutUs);
    data.append("society_management[complex_area]", values.totalArea);
    data.append("society_management[total_building]", values.totalBuilding);
    data.append("society_management[total_unit]", values.totalUnit);
    data.append("society_management[account_manager_id]", values.accountManager);
    data.append("society_management[logo]", values.logo);
    data.append("jurisdiction_id", this.props.jurisdiction);
    data.append("society_management[currency_id]", values.currency);
    data.append("society_management[real_estate_company_id]", "");
    data.append("society_management[default_template_id]", values.rentalSelected + "");
    [...values.images].forEach((image: any) => {
      data.append("society_management[photos][]", image);
    });

    if (this.props.complexID) {
      this.AddEditComplexCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_society_management/company_employee/complexes/${this.props.complexID}`,
        body: data,
      });
    } else {
      this.AddEditComplexCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_society_management/company_employee/complexes`,
        body: data,
      });
    }
  };

  handleAddEditComplexResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.message) {
        this.setState({ showError: true, error: responseJson.message });
      }
      if (responseJson && responseJson.data) {
        this.props.updateComplexId(responseJson.data.id);
      }
    });
  };

  validationSchema = Yup.object().shape({
    complexName: Yup.string()
      .required(this.props.t("Complex Name is required"))
      .min(3, this.props.t("Company name must be at least 3 characters"))
      .max(50, this.props.t("Complex name must be at most 50 characters")),
    totalArea: Yup.string().required(this.props.t("Total Area is required")),
    logo: Yup.mixed().required(this.props.t("Please upload a logo")),
    aboutUs: Yup.string().required(this.props.t("About Us is required")),
    totalUnit: Yup.string().required(this.props.t("Total Units is required")),
    totalBuilding: Yup.string().required(this.props.t("Total Building is required")),
    currency: Yup.string().required(this.props.t("Currency is required")),
    images: Yup.array().nullable().min(1, this.props.t("At least one image is required")),
  });
}
