// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpComplexBuildingBlockStep1Controller, {
  Props,
  StyleObject,
} from "./CompEmpComplexBuildingBlockStep1Controller.web";
import { Formik } from "formik";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { withTranslation } from "react-i18next";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { CloseIcon, Upload_Icon } from "../assets";
import Loader from "../../../../components/src/Loader.web";

class CompEmpComplexBuildingBlockStep1 extends CompEmpComplexBuildingBlockStep1Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.state.buildingValues}
          validationSchema={this.validationSchema}
          onSubmit={(values) => {
            this.handleSubmitBuildingDetail(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
            return (
              <form className="commonForms complex_building_block_step1" onSubmit={handleSubmit}>
                <Box>
                  <Box className="complex complex_building_block_step1">
                    <Typography
                      className="complex_building_block_step1"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        paddingBottom: "25px",
                        borderBottom: "1px solid #F0F0F0",
                      }}
                    >
                      {t("Complex")}
                    </Typography>
                  </Box>
                  <Box
                    className="complex_building_block_step1"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "25px",
                    }}
                  >
                    <Box className="complex_building_block_step1">
                      <Typography
                        className="complex_building_block_step1 bold-text"
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                      >
                        {t("Basic Details")}
                      </Typography>
                    </Box>
                    <Box className="complex_building_block_step1">
                      <Typography className="complex_building_block_step1" style={{ fontSize: "16px" }}>
                        {t("Step")} {this.props.step + 1} {t("of")} 5
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="step2forms complex_building_block_step1"
                    style={{
                      marginTop: "28px",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {values.logo ? (
                      <img
                        data-testid="preview_img"
                        src={typeof values.logo === "string" ? values.logo : URL.createObjectURL(values.logo)}
                        className="complex_building_step1"
                        alt="Preview"
                        style={{
                          width: "66px",
                          height: "66px",
                          borderRadius: "50%",
                          background: "#dcdcdc",
                        }}
                      />
                    ) : (
                      <Box
                        className="complex_building_step1"
                        style={{
                          width: "66px",
                          height: "66px",
                          borderRadius: "50%",
                          background: "#dcdcdc",
                        }}
                      />
                    )}
                    <label htmlFor="logoChange" className="complex_building_step1">
                      <span
                        style={{
                          color: "#FC8434",
                        }}
                        className="bold-text complex_building_block_step1"
                      >
                        {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                      </span>
                    </label>
                <input
                  type="file"
                  accept="image/*"
                  id="logoChange"
                  className="complex_building_step1"
                  name="logo"
                  data-testid="logoUpload"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const files = event.target.files;
                    if (files) {
                      const file = files[0];
                      setFieldValue("logo", file);
                    }
                  }}
                  style={{ display: "none" }}
                />
                    {ErrorHandler(t, touched.logo, errors.logo)}
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <NormalTextField
                        value={values.buildingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="buildingName"
                        t={t}
                        language={language}
                        label={t("Building Name")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.buildingName, errors.buildingName)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        value={values.totalArea}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="totalArea"
                        t={t}
                        language={language}
                        label={t("Total Area")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                    </Grid>
                    <Grid item xs={12}>
                      <label htmlFor="uploadPhotos" style={{ marginBottom: "8px", color: "black", fontSize: "14px" }}>
                        {t("Upload Photos")}
                      </label>
                      <Grid container spacing={4}>
                    <Grid item md={3} style={{ marginBottom: "5px" }} className="edit-building">
                      <Box className="upload-photo" onClick={() => this.uploadImages.click()}>
                        <img src={Upload_Icon} alt="edit-complex-upload-icon" />
                      </Box>
                      <input
                        multiple
                        style={{ display: "none" }}
                        ref={(ref: unknown) => (this.uploadImages = ref)}
                        name="photos"
                        onBlur={handleBlur}
                        className="complex-details-image"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const imageFiles: any = e.target.files;
                          setFieldValue("images", [...values.images, ...imageFiles]);
                        }}
                        type="file"
                        accept="image/*"
                      />
                    </Grid>
                    {[...values.images].map((image: any, index: number) => {
                      return (
                        <Grid item md={3} key={index}>
                          <Box className="building-image" style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "35px",
                                cursor: "pointer",
                                right: "5px",
                                top: "5px",
                                height: "35px",
                                position: "absolute",
                              }}
                              src={CloseIcon}
                              className="delete-image"
                              onClick={() => {
                                const remainImage = [...values.images].filter(
                                  (img: File, idx: number) => idx !== index
                                );
                                setFieldValue("images", remainImage);
                              }}
                            />
                            <img
                              src={typeof image === "string" ? image : URL.createObjectURL(image)}
                              alt=""
                              style={{ height: "124px", width: "100%", borderRadius: "8px" }}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                      </Grid>
                      {ErrorHandler(t, touched.images, errors.images)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.totalFloors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="totalFloors"
                        t={t}
                        language={language}
                        label={t("Total Floors")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.totalFloors, errors.totalFloors)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.totalUnits}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="totalUnits"
                        t={t}
                        language={language}
                        label={t("Total Units")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.totalUnits, errors.totalUnits)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        value={values.locationLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="locationLink"
                        t={t}
                        language={language}
                        label={t("Location Link")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.locationLink, errors.locationLink)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextArea
                        value={values.aboutUs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="aboutUs"
                        t={t}
                        language={language}
                        label={t("About Us")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.aboutUs, errors.aboutUs)}
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}
                  className="complex_building_block_step1"
                >
                  <Box className="complex_building_block_step1">
                    <Grid style={{ marginTop: "30px" }} className="complex_building_block_step1">
                      <Button
                        className="complex_building_block_step1"
                        type="submit"
                        style={webStyle.nextButton}
                        data-test-id="handleNext"
                      >
                        <span className="bold-text">{t("Next")}</span>
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle: StyleObject = {
  errorMsg: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  nextButton: {
    width: "177px",
    height: "50px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "uppercase",
  },
};

export default withTranslation()(CompEmpComplexBuildingBlockStep1);

// Customizable Area End
