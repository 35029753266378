// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string;
  navigation:typeof navigation
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  templatesList:Array<TemplateItem>;
  viewTemplateModal:boolean;
  viewTemplateModalData:TemplateItem;
  selectedTemplate:number | null,
  loader:boolean,
  showError:boolean,
  createContractPayloadDataItem: CreateContractPayloadDataItem;
  error:string,
  templateSearchText: string;
  // Customizable Area End
}

// Customizable Area Start

export interface CreateContractPayloadDataItem {
  templateId: string;
  country: string;
  city: string;
  complex: string;
  buildingName: string;
  unitName: string;
  complexName: string;
  tenant_name: TenantNameInterface;
  landlord_name: string;
  tenant_id: string;
  society_management_id: string;
  building_management_id: string;
  expires_on: string;
  apartment_management_id: string;
  agreement_duration: string;
  start_date: string;
  term_ids: Array<number>;
  lease_template_id: string;
  rent_amount: string;
  custom_contract: boolean;
  currency: string;
  contract_template: string;
  condition_ids: Array<number>;
  ownerNumber: string | null;
  penanlty_late_payment: boolean;
  accountId: Account_id;
  ownerEmail: string | null;
  owner: string | null;
  status: string;
  contractLink: string;
};

type TenantNameInterface = string | number | null
type Account_id = string | number | null
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  contract: string
}

export interface ApiPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
export interface TemplateItem{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "title_ar": string,
      "description": null,
      "template_type_id": null,
      "template": string,
      "template_ar": string,
      "template_pdf": {
          "url": string
      }
  }
}
export interface TemplatesListApiSuccessResponse{
  "data": Array<TemplateItem>
}

export type TFunction = (value: string) => string;
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  templateListApiCallid:string="";
  searchtemplateListApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.TenantDetails)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      anchorEl: null,
      selectedId: null,
      templatesList:[],
      showError:false,
      error:"",
      viewTemplateModal:false,
      loader:false,
      templateSearchText: "",
      viewTemplateModalData: {
        "id": "1",
        "type": "lease_template",
        "attributes": {
            "id": 1,
            "title": "Lease Templete ",
            "title_ar": "قالب الإيجار",
            "description": null,
            "template_type_id": null,
            "template": "<p>Tenant name :-&nbsp;<strong>{{TENANT_NAME}}</strong></p><p>This agreement made on this&nbsp;<strong>{{START_DATE}}</strong>&nbsp;between&nbsp;<strong>{{LANDLORD_NAME}}</strong>, residing&nbsp;<strong>{{BUILDING_NAME}}</strong>,&nbsp;<strong>{{UNIT_NAME}}</strong>&nbsp;on duration of&nbsp;<strong>{{DURATION}}</strong>,&nbsp;<strong>{{END_DATE}}</strong>&nbsp;on rent amount&nbsp;<strong>{{AMOUNT}}</strong>.</p><p><br></p><p>WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to [Lease Property Address Line 1, Address Line 2, City, State, Pin Code] falling in the category, [Independent House / Apartment / Farm House / Residential Property] and comprising of with an extent of [ Square Feet] hereinafter referred to as the `said premises`;</p><p><br></p><p>AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of [Lease Term] commencing from [Lease Start Date] in the manner hereinafter appearing.</p><p><strong>{{COMPLEX_NAME}}</strong></p><p><strong>{{COMPLEX_ADDRESS}}</strong></p><p><br></p><p>Thanks,</p><p><strong>{{LANDLORD_NAME}}</strong></p>",
            "template_ar": "",
            "template_pdf": {
                "url": ""
            }
        }
    },
      selectedTemplate: null,
      createContractPayloadDataItem: {
        templateId: "",
        country: "",
        city: "",
        complex: "",
        buildingName: "",
        unitName: "",
        complexName: "",
        tenant_name: "",
        landlord_name: "",
        building_management_id: "",
        society_management_id: "",
        tenant_id: "",
        expires_on: "",
        apartment_management_id: "",
        agreement_duration: "",
        start_date: "",
        term_ids: [],
        lease_template_id: "",
        rent_amount: "",
        currency: "",
        condition_ids: [],
        custom_contract: false,
        penanlty_late_payment: false,
        contract_template: "",
        accountId: "",
        owner: "",
        ownerEmail: "",
        ownerNumber: "",
        status: "create",
        contractLink: ""
      }
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }
  
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.templatesListApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.templatesListApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractPayloadData = message.getData(getName(MessageEnum.CreateContractPayloadData));
      if (createContractPayloadData) {
        this.setState({ createContractPayloadDataItem: createContractPayloadData });
      }
      this.gettemplatesList();
    }
  }

  templatesListApiCall = async (data: ApiPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;

    const header = {
      "Content-Type": contentType,
      token: token
    };
    let templatesListrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    templatesListrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    templatesListrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    templatesListrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    templatesListrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    
    body && type !== "formData"
      ? templatesListrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : templatesListrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(templatesListrequestMessage.id, templatesListrequestMessage);
    return templatesListrequestMessage.messageId;
  };



  templatesListApiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: TemplatesListApiSuccessResponse) => {
    if (apiRequestCallId === this.templateListApiCallid) {
      this.templatesListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.searchtemplateListApiCallId) {
      this.searchTtemplatesListSucessCallBack(responseJson);
    } 
  };

  templatesListApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.templateListApiCallid) {
      this.templatesListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.searchtemplateListApiCallId) {
      this.searchTtemplatesListFailureCallBack(responseJson);
    }
  };

  gettemplatesList = async () => {
    this.setState({loader:true})
    this.templateListApiCallid = await this.templatesListApiCall({
      method: configJSON.templateListMethod,
      endPoint: configJSON.templateListEndPoint,
    });
  };

  templatesListSucessCallBack = (response: TemplatesListApiSuccessResponse) => {   
    this.setState({templatesList:response?.data,loader:false})
  };

  templatesListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract,loader:false
    })
  };

  getSearchTemplatesList = async () => {
    this.setState({ loader: true });
    this.searchtemplateListApiCallId = await this.templatesListApiCall({
      method: configJSON.templateListMethod,
      endPoint: `${configJSON.searchTemplateEndPoint}${this.state.templateSearchText}`,
    });
  };

  searchTtemplatesListSucessCallBack = (response: TemplatesListApiSuccessResponse) => {
    this.setState({ templatesList: response?.data, loader: false });
  };

  searchTtemplatesListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      showError: true, error: response.errors[0].contract, loader: false
    });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, selectedId: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: selectedId });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };

  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ templateSearchText: event.target.value },()=>{
      this.getSearchTemplatesList();
    });
  };

  viewTemplate = (templateId: string) => {
    let viewTemplateModalData = this.state.templatesList.filter((item, index) => {
      return item.id === templateId
    });
    this.setState({ viewTemplateModalData: viewTemplateModalData[0] }, () => {
      this.setState({ viewTemplateModal: !this.state.viewTemplateModal, })
      this.handleClose()
    });
  };

  selectTemplate = () => {
    let selectedTemplateId = this.state.viewTemplateModalData.attributes.id
    this.setState({ selectedTemplate: Number(selectedTemplateId),viewTemplateModal:false })
  };

  viewTemplateDetails = () => {
    let createContractPayloadData = this.state.createContractPayloadDataItem;
    createContractPayloadData.templateId = this.state.viewTemplateModalData.id;
    createContractPayloadData.contract_template = this.state.viewTemplateModalData.attributes.template;
    createContractPayloadData.lease_template_id = this.state.viewTemplateModalData.id;
    this.setState({ createContractPayloadDataItem: this.state.createContractPayloadDataItem }, () => {
      const msgData: Message = new Message(
        getName(MessageEnum.NavigationMessageSendData)
      );
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractTemplateDetails");
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadDataItem);
      this.send(msgData);
    });    
  };

  handleCloseAlertError = () => { this.setState({ showError: false, error: "" }) };

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadDataItem);
    this.send(msgData);
  };

  // Customizable Area End
}