
// Customizable Area Start
import React from "react";
import LeaseManagementReviewDraftController, { Props, TFunction } from "./LeaseManagementReviewDraftController.web";
import { Box, Breadcrumbs, Button, Divider, Grid, Link as RedirectTo, Paper, Typography, styled } from "@material-ui/core";
import { BuildingIcon, CalenderIcon, DownloadIcon, ProfileIcon, UnitIcon } from "./assets";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementReviewDraft extends LeaseManagementReviewDraftController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderReviewData = () => {
        const { t }: { t: TFunction } = this.props;
        return <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsComplexContainer">
                        <img
                            src={BuildingIcon}
                            className="RevieDraftContractDetailsComplexIcons"
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataComplexHeading">
                                {t("Complex")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsComplexSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.society_management.name)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsBuildingContainer">
                        <img
                            src={BuildingIcon}
                            className="RevieDraftContractDetailsBuildingIcons"
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataBuildingHeading">
                                {t("Building")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsBuildingSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.building_name)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsUnitContainer">
                        <img
                            className="RevieDraftContractDetailsUnitIcons"
                            src={UnitIcon}
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataUnitHeading">
                                {t("Unit")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsUnitSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.unit_name)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsOwnerContainer">
                        <img
                            className="RevieDraftContractDetailsOwnerIcons"
                            src={ProfileIcon}
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataOwnerHeading">
                                {t("Owner")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsOwnerSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.owner.full_name)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsTenantContainer">
                        <img
                            className="RevieDraftContractDetailsTenantIcons"
                            src={ProfileIcon}
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataTenantHeading">
                                {t("Tenant")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsTenantSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.tenant_name)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsContractDurationContainer">
                        <img
                            src={CalenderIcon}
                            className="RevieDraftContractDetailsCalenderIcons"
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataContractDurationHeading">
                                {t("Contract Duration")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsContractDurationSubHeading">
                                {t(this.state.reviewDraftDetails.contract.data.attributes.agreement_duration) + " months"}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ReviewDraftContractDetailsExpiryDateContainer">
                        <img
                            src={CalenderIcon}
                            className="RevieDraftContractDetailsExpirayDateIcons"
                        />
                        <Box>
                            <Typography className="ReviewDraftContractDetailsDataExpiryDateHeading">
                                {t("Expiry Date")}
                            </Typography>
                            <Typography className="reviewDraftContractDetailsExpiryDateSubHeading">
                                {this.state.reviewDraftDetails.contract.data.attributes.expires_on}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </>
    };

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <ReviewDraftContainer>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <RedirectTo 
                            className="ReviewDraftBreadcrumbsChild1" 
                            id={"LeaseManagementId"} 
                            onClick={()=>
                                this.handleExistRecordNavigation("LeaseManagment")
                            }
                            data-testId={"LeaseManagementTestId"}
                            >
                                {t("Lease Management")}
                            </RedirectTo>
                            <Typography className="ReviewDraftDetailsBreadcrumbsChild2">
                                {t("Review Draft")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="ReviewDraftDetailsHeading">
                            {t("Review Draft")}
                        </Typography>
                        <Paper className="reviewDraftHeadingContainer">
                            <Box className="reviewDraftHeadingBox">
                                <Typography className="revireDraftHeadingText" data-testId={"reviewDraftHeading"}>
                                    {t("Contract 1")}
                                </Typography>
                                <Box>
                                    <img
                                        className="reviewDraftDownloadIconStyled"
                                        src={DownloadIcon}
                                        alt={DownloadIcon}
                                        onClick={() => this.handleCommentFile(this.state.reviewDraftDetails.contract.data.attributes.contract_template_pdf.url)}
                                        data-testId={"downloadBtnTestId"}
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box className="reviewDraftContractDetailsContent">
                                {this.renderReviewData()}
                            </Box>

                            <Box className="ContractDetailsContentBox">
                                <Typography className="ContractDetailsContentHeading" >
                                    {t("Recident Rental Lease Agreement")}
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                
                                    <div dangerouslySetInnerHTML={{ __html: this.state.reviewDraftDetails.contract.data.attributes.lease_template_text }}></div>

                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box className="ContractDetailsButtonGroup">
                        <Button variant="text" className="ContractDetailsButton1" onClick={this.handleEditDocumentNavigation} data-testId={"editDocumentTestId"}>
                            {t("EDIT DOCUMENT")}
                        </Button>
                        <Button variant="text" className="ContractDetailsButton1" onClick={this.saveContract} data-testId={"saveContractBtnTestId"}>
                            {t("SAVE DOCUMENT")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2" onClick={this.generatContract} data-testId={"generateContractBtnTestId"}>
                            {t("generate contract")}
                        </Button>
                    </Box>
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorModalTestId"}/>
                    <AlertSuccess show={this.state.showSuccessMessage} handleClose={this.hanldeCloseSuccessAlert} message={this.state.successMessage} data-testId={"successModalTestId"} />
                </ReviewDraftContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementReviewDraft);
export { LeaseManagementReviewDraft };

const ReviewDraftContainer = styled(Box)({
    padding: 30,
    background: "#f4f7ff",
    overflow: "scroll",
    "& .ReviewDraftDetailsHeading": {
        fontSize: "30px",
        fontWeight: 800,
        paddingBottom: 20,
        marginTop: 10,
    },
    "& .reviewDraftHeadingContainer": {
        borderRadius: 10,
    },
    "& .ReviewDraftBreadcrumbsChild1": {
        color: "#676972",
        cursor: "pointer"
    },
    "& .ReviewDraftDetailsBreadcrumbsChild2": {
        color: "#537ad2",
        cursor: "pointer"
    },
    "& .reviewDraftHeadingBox": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingLeft: 20,
        paddingTop: 15,
        paddingBottom: 10,
    },
    "& .revireDraftHeadingText": {
        fontSize: "25px",
        fontWeight: 800,
    },
    "& .reviewDraftDownloadIconStyled": {
        height: "30px",
        width: "33px",
        marginRight: 15,
    },
    "& .ContractDetailsButton1": {
        color: "#3274ce",
        marginRight: 15,
        fontWeight: 800,
        paddingRight: 50,
        border: "1px solid #3274ce",
        paddingLeft: 50,
    },
    "& .ContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
    },

    "& .ContractDetailsContentHeadingText": {
        textAlign: "justify",
        color: "gray",
        marginTop: 10,
    },
    "& .ContractDetailsContentHeading": {
        fontWeight: 800,
    },
    "& .ContractDetailsButtonGroup": {
        paddingTop: 50,
        float: "right",
    },
    "& .RevieDraftContractDetailsIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsComplexIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsBuildingIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsUnitIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsOwnerIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsTenantIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsCalenderIcons": {
        height: 25,
        width: 25,
    },
    "& .RevieDraftContractDetailsExpirayDateIcons": {
        height: 25,
        width: 25,
    },

    "& .reviewDraftContractDetailsComplexSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsBuildingSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsUnitSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsOwnerSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsTenantSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsContractDurationSubHeading": {
        fontWeight: "bold",
    },
    "& .reviewDraftContractDetailsExpiryDateSubHeading": {
        fontWeight: "bold",
    },
    "& .ContractDetailPopover": {
        right: "700px",
        top: "300px",
        position: "absolute",
    },
    "& .reviewDraftContractDetailsContent": {
        display: "flex",
        padding: 20,
        flexWrap: "wrap",
    },
    "& .ReviewDraftContractDetailsDataComplexHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataBuildingHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataUnitHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataOwnerHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataTenantHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataContractDurationHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ReviewDraftContractDetailsDataExpiryDateHeading": {
        color: "#fcd2ab",
        fontWeight: "500",
    },
    "& .ContractDetailsContentBox": {
        padding: 20,
    },
    "& .ReviewDraftContractDetailsComplexContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsBuildingContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsUnitContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsOwnerContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsContractDurationContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsExpiryDateContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ReviewDraftContractDetailsTenantContainer": {
        gap: "10px",
        display: "flex",
    },
});
// Customizable Area End