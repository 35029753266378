//Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { RouteComponentProps } from 'react-router-dom';
import { t } from "i18next";
import { Message } from "../../../framework/src/Message";
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
export const configJSON = require("./config");
export interface LocationState {
  userRole?: string; // Define the type of userRole here
}
export interface Props extends RouteComponentProps<{}, {}, LocationState> {
  navigation: any;
  id: string;
  classes?: any;
}


interface S {
  accountType: string,
  openModel:boolean,
  responseMessage:string,
  errorMsg:string,
  openMenuIndex:any;
  ownerListOpen:boolean;
  viewOwnerObj:any;
  selectedOwner:any;
  viewOwnerDeatils:boolean;
  top:any;
  left:any;
  initialValues: any;
  subscriptionFilterList: any[],
  complexBuildingFilterList: any[],
  durationList: any[],
  countryList: any[],
  cityList: any[],
  companyList: any[],
  complexList: any[],
  ownerList:any[],
  ownerData:any[],
  planList: any[],
  PropertyManagerList:any[],
  numbersOnly: any,
  loading: boolean,
  currentSubscriptionRole: string
  currentComplexBuildingRole: string;
  ownerId:any;
  ownerEmailId:any;
  ownerPhone:any;
  ownerPassPortNumber:any;
  totalUnits:string
  propertyComplex:string,
  propertyBuilding:string,
  companyName:string,
  complexLabelname:string

}

interface SS {
  id: any;
}

export default class AddSubscriptionController extends CommonApiCallForBlockComponent<Props, S, SS> {

  GetCountryFilterList: any;
  GetCityFilterList: any;
  GetComplexFilterList: any;
  GetOwnerFilterList:any;
  GetPlanFilterList: any;
  GetCompanyFilterList: any;
  CreateSubscription: any;
  GetPropertyManagerList:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      loading: false,
      companyName:"",
      openModel:false,
      responseMessage:"",
      errorMsg:"",
      openMenuIndex:null,
      ownerListOpen:false,
      viewOwnerObj:"",
      selectedOwner:"",
      viewOwnerDeatils:false,
      PropertyManagerList:[],
      top:0,
      left:0,
      initialValues: {
        subscriptionRole: this.props?.location?.state?.userRole,
        countryFilter: '',
        cityFilter: '',
        complexFilter: '',
        companyFilter: '',
        planFilter: '',
        durationFilter: '',
        complexBuildingRole: '',
        propertyManager: '',
        totalComplex: "",
        totalBuilding: "",
        totalUnits: "",
        referenceNumber: "",
        startDateFilter: "",
        ownerName:"",
        individualBuilding:""
      },
      subscriptionFilterList: [
        {
          label: t("Property Developers"),
          value: 'property_developer'
        },
        {
          label: t("General Assemblies"),
          value: 'general_assembly'
        },
        {
          label: t("Owners"),
          value: 'owner'
        },
        {
          label: t("Property Managers"),
          value: 'property_manager'
        },
      ],
      complexBuildingFilterList: [
        {
          label: t("Complex"),
          value: 'complex'
        },
        {
          label: t("Indiviual Building"),
          value: 'indiviualBuilding'
        },
      ],
      durationList: [
        {
          value: 1,
          label: "1 Months",
        },
        {
          value: 2,
          label: "2 Months",
        },
        {
          value: 3,
          label: "3 Months",
        },
        {
          value: 4,
          label: "4 Months",
        },
        {
          value: 5,
          label: "5 Months",
        },
        {
          value: 6,
          label: "6 Months",
        },
        {
          value: 7,
          label: "7 Months",
        },
        {
          value: 8,
          label: "8 Months",
        },
        {
          value: 8,
          label: "9 Months",
        },
        {
          value: 10,
          label: "10 Months",
        },
        {
          value: 11,
          label: "11 Months",
        },
        {
          value: 12,
          label: "12 Months",
        },
      ],
      accountType: '',
      countryList: [],
      cityList: [],
      complexList: [],
      planList: [],
      ownerList:[],
      ownerData:[],
      companyList: [],
      numbersOnly: '',
      totalUnits:"",
      currentSubscriptionRole: '',
      currentComplexBuildingRole: '',
      ownerId:"",
      ownerEmailId:"",
      ownerPhone:"",
      ownerPassPortNumber:"",
      propertyComplex:"",
      complexLabelname:"Complex",
      propertyBuilding:""
    };/*  */
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    const accountRole = localStorage.getItem("role")

    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, '')
      this.setState({ accountType: accountRoleName })
    }
    this.getPropertyAllManagerList();
    this.getCompanyList("")
    this.getComplexList(this.state.initialValues.cityFilter,"",false)

    const getAllFilterForm = [
      {
        name: 'GetCountryFilterList',
        endPoint: configJSON.getCountryListEndPoint
      },
      {
        name: 'GetCityFilterList',
        endPoint: `bx_block_real_estate_companies/region_city_by_country?country=${this.state.initialValues.countryFilter}`,
      },
      {
        name: 'GetPlanFilterList',
        endPoint: '/bx_block_custom_user_subs/plans',
      },
    ]
    getAllFilterForm.forEach((filter) => {
      this.getFilterListTable(filter.name, filter.endPoint)
    })

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetCountryFilterList:
          this.handleCountryListResponse(responseJson);
          break;
        case this.GetCityFilterList:
          this.handleCityListResponse(responseJson)
          break;
        case this.GetComplexFilterList:
          this.handleFiltersList(responseJson, 'complexList', 'data')
          break;
        case this.GetPropertyManagerList:
          this.handleFiltersList(responseJson, 'PropertyManagerList', 'data')
          break;
        case this.GetOwnerFilterList:
            this.handleOwnerList(responseJson, 'data')
            break;
        case this.GetPlanFilterList:
          this.handleFiltersList(responseJson, 'planList', 'data')
          break;
        case this.GetCompanyFilterList:
          this.handleFiltersList(responseJson, 'companyList', 'data')
          break;
        case this.CreateSubscription:
          return this.handleCreate(responseJson)
        default:
          break;
      }
      this.setState({ loading: false })
    }
  }
  handleOwnerList=(responseJson:any,resParam:any)=>{
    if (responseJson[resParam]) {
      this.setState({ ...this.state, ownerData: responseJson[resParam], ownerList: responseJson[resParam] })
    }
  }

  handleCreate=(responseJson:any)=>{
    if(responseJson.message){
      this.setState({ responseMessage:responseJson.message, openModel:true})
    }else if (responseJson.data?.attributes?.errors) {
     if (responseJson.data.attributes.errors?.subscriber_id) {
          this.setState({ errorMsg: `${responseJson.data?.attributes?.errors?.subscriber_id?.[0]}` });
      }else{
          this.setState({ errorMsg: "Failed to create new subscription" });
      } 
    }else {
      this.setState({ errorMsg: "Failed to create new subscription" });
    }
    
  }
  getFilterListTable = async (method: string, endPoint: string) => {
    this.setState({ loading: true })
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  }

  getCityList = async (country: string) => {
    this.GetCityFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getCityListEndPoint+`?country=${country}`
    })
  }

  getCompanyList = async ( city?: string) => {
    this.GetCompanyFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:configJSON.getCompanyListEndPoint+`=${city}`
    })
  }

  getComplexList = async ( city?: string,company?:any,building?:boolean) => {
    let endPoint = "bx_block_address/housing_complex_list?"
    city && (endPoint += `city=${city}&`);
    this.GetComplexFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${endPoint}compny_id=${company}&is_building=${building}`
    })
  }

  getOwnerList= async () => {
    this.GetOwnerFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/1/bx_block_contract/employee_contracts/find_person?role=Owner&city=${this.state.initialValues.cityFilter}&id_number=${this.state.ownerId}&phone_number=${this.state.ownerPhone}&email=${this.state.ownerEmailId}&passport=${this.state.ownerPassPortNumber}`
    })
  }
  getPropertyAllManagerList = async () => {
    this.GetPropertyManagerList = await apiCall({
        contentType: configJSON.subscriptionApiContentType,
        method: configJSON.getSubscriptionAPiMethod,
        endPoint: `society_managements/1/bx_block_contract/employee_contracts/find_person?role=Property Manager&city=${this.state.initialValues.cityFilter}`,
    });
};

  handleFiltersList = (responseJson: any, stateParam: string, resParam: string) => {
    if (responseJson&&responseJson[resParam]) {
      this.setState({ ...this.state, [stateParam]: responseJson[resParam] })
    }
  }
  handleCountryListResponse=(responseJson: any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ 
        countryList: responseJson?.data?.countries.map((item: any) => 
        ({ label: item, value: item }))
      });
    }
  }
  handleCityListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
         cityList: responseJson?.data?.cities.map((item: any) => 
        ({ label: item, value: item }))
      });
    }
  };

  handleSubmit(values: any) {
   
    let data;
   
    switch(values.subscriptionRole) {
      case 'property_developer':{
        data = {
          "data": {
            "subscriber_id": parseInt(values?.companyFilter?.id),
            "subscriber_type": "BxBlockRealEstateCompanies::RealEstateCompany",
            "plan_id": parseInt(values?.planFilter),
            "start_date": this.formatDate(new Date()),
            "duration": parseInt(values?.durationFilter),
            "payment_reference_number": parseInt(values?.referenceNumber),
            "city": values?.cityFilter,
            "country": values?.countryFilter,
            "total_complexes": parseInt(this.state.propertyComplex), 
            "total_buildings": parseInt(this.state.propertyBuilding),
            "total_units":parseInt(this.state.totalUnits)
          }
        }
        
        // company
        break;
      }
      case 'general_assembly':{
        data = {
          "data": {
            "subscriber_id": parseInt(values?.complexFilter?.id),
            "subscriber_type": "BxBlockSocietyManagement::SocietyManagement",
            "plan_id": parseInt(values?.planFilter),
            "start_date": this.formatDate(values.startDateFilter),
            "duration": parseInt(values?.durationFilter),
            "payment_reference_number": parseInt(values?.referenceNumber),
            "city": values?.cityFilter,
            "country": values?.countryFilter,
            "total_units":parseInt(this.state.totalUnits)
          }
        }
        break;
      }
      case 'owner':{
        data = {
          "data": {
            "subscriber_id": this.state.selectedOwner.id,
            "subscriber_type": "AccountBlock::Account",
            "plan_id": parseInt(values?.planFilter),
            "start_date": this.formatDate(values.startDateFilter),
            "duration": parseInt(values?.durationFilter),
            "payment_reference_number": parseInt(values?.referenceNumber),
            "city": values?.cityFilter,
            "country": values?.countryFilter,
            "total_units":parseInt(this.state.totalUnits)
          }
        }
        break;
      }
      case 'property_manager':{
        data = {
          "data": {
            "subscriber_id": values?.propertyManager?.id,
            "subscriber_type": "AccountBlock::Account",
            "plan_id": parseInt(values?.planFilter),
            "start_date": this.formatDate(values.startDateFilter),
            "duration": parseInt(values?.durationFilter),
            "payment_reference_number": parseInt(values?.referenceNumber),
            "city": values?.cityFilter,
            "country": values?.countryFilter,
            "total_units":parseInt(this.state.totalUnits)
          }
        }
       
        break;
      }
      default: 
        break;
    }

    this.addSubscription(data)
  }
  
  addSubscription = async (data: any) => {
   
    this.setState({loading: true, errorMsg:""})
    this.CreateSubscription = await apiCall({
      method: "POST",
      body:JSON.stringify(data),
      contentType : "application/json",
      endPoint: `bx_block_custom_user_subs/subscriptions?user_role=${this.state.initialValues.subscriptionRole}`,
    });
  }
  handleClose = () => {
    this.setState({openMenuIndex:null,viewOwnerDeatils:false,ownerListOpen:false});
  }
  handleOpen= () => {
    this.setState({ownerListOpen:true})
  }
  handleAddOwner = (value:any) => {
    this.setState({selectedOwner:value,viewOwnerDeatils:false,totalUnits:value?.attributes?.total_unit});
  }
  handleClick = (event:any, index:any) => {
    this.setState({
      openMenuIndex:index,
      top: event?.currentTarget?.offsetTop,
      left: event?.currentTarget?.offsetLeft + event?.currentTarget?.offsetWidth
    });
  };

  formatDate = (date: any) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        return ''; // Return an empty string or handle this case appropriately
    }

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = formattedDate.split('/');
    return `${year}/${month}/${day}`;
}

  handleDateChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const date = event?.target?.value ? new Date(event.target.value) : null; // Extract the date value from the event
    this.setState({ initialValues: { ...this.state.initialValues, startDateFilter: date } });
  };

  handleCloseModel=()=>{
    this.setState({openModel:false});
    this.props.history.push("/CompanyEmployee/SubsciptionListing");
  }
  handleCountryChange = (countryName: string) => {
    this.setState({ initialValues: { ...this.state.initialValues, countryFilter:  countryName} })
    this.getCityList(countryName)
  }

  handleValueChange=(value:any, keyState:string)=>{
    if(keyState==="referenceNumber"){
      const numberValue=this.getNumValue(value)
    this.setState({ initialValues: { ...this.state.initialValues, [keyState]:  numberValue} })
    }
    if(keyState==="propertyManager"){
      this.setState({ initialValues: { ...this.state.initialValues, [keyState]:  value},
        totalUnits:value?.attributes?.total_unit
       })
     
    }
   
    if(keyState==="companyFilter"){
      this.setState({ initialValues: { ...this.state.initialValues, [keyState]:  value.id},
        totalUnits:value?.attributes?.totle_unit,
        propertyComplex:value?.attributes?.totle_complex,
        propertyBuilding:value?.attributes?.totle_building
       },()=>{this.getComplexList(this.state.initialValues.cityFilter,value.id,false)})
    }
    if(keyState==="individualBuilding"){
     
      this.handleindiviualbuilding(value)
    }
     if(keyState==="complexFilter"){
      this.setState({ initialValues: { ...this.state.initialValues, [keyState]:  value},
        totalUnits:value?.attributes?.total_units
       })
    }
    else{
      this.setState({ initialValues: { ...this.state.initialValues, [keyState]:  value} })
    }
  }
  handleindiviualbuilding=(value:string)=>{
   if(value==="indiviualBuilding"){
    this.setState({
      complexLabelname:"Individual Building"
    })
    this.getComplexList(this.state.initialValues.cityFilter,this.state.initialValues.companyFilter?.id,true)
   }else{
    this.setState({
      complexLabelname:"Complex"
    })
    this.getComplexList(this.state.initialValues.cityFilter,this.state.initialValues.companyFilter?.id,false)
   }
  }
  getNumValue=(value:string)=>{
    return value.split('').reduce((acc, char) => {
      if (/\d/.test(char)) {acc += char;}
      return acc;
    }, '');
  }
  handleFilterChange = (propertyName: keyof S, value: any) => {
    this.setState({ [propertyName]: value } as Pick<S, keyof S>);
  }


  handleViewOwner=(value:any)=>{
    this.setState({viewOwnerObj:value,ownerListOpen:false,viewOwnerDeatils:true,openMenuIndex:null})
  }
 
  handleCityChange = (cityName: string) => {
    this.setState({ initialValues: { ...this.state.initialValues, cityFilter:  cityName} },()=>{
     this.getOwnerList()
    this.getPropertyAllManagerList()
    })
    this.getCompanyList(cityName)
    this.getComplexList( cityName,this.state.initialValues.companyFilter,false)
  }

  handleSubscriptionRoleChange = (value: string) => {
    // this.setState({ initialValues: { subscriptionRole: value } })
    this.setState({ initialValues: { ...this.state.initialValues, subscriptionRole: value } })
  }

}
// Customizable Area End

