// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Tab,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  InputLabel,
  styled,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
} from "@material-ui/core";
import { buildingIcon, emailIcon, phoneIcon, userIcon } from "../assets";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { Formik, Field, Form } from "formik";
import CERequestManagementController, { Props } from "./CERequestManagementController.web";
import { withRouter, Link as NavLink } from "react-router-dom";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import SearchIcon from "@material-ui/icons/Search";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { ceBuildingIcon, ceRealEstateIcon } from "../assets";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AlertError from "../../../../components/src/AlertError.web";
// Customizable Area End

class CERequestManagement extends CERequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInviteMember = (t: any, language: any) => {
    return (
      <DialogActionsCss
        dir={languageCondition(language, "rtl", "ltr")}
        open={this.state.inviteMemberModelOpen}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogHeader t={t} title="Invite Member" onClose={this.handleClose} />
        <Formik
          initialValues={this.state.formValue}
          enableReinitialize={true}
          validationSchema={this.meetingValidation}
          data-test-id="invite-member-form"
          onSubmit={(values, { resetForm }) => {
            this.handleFormSubmit(values);
            resetForm();
          }}
        >
          {({ values, handleBlur, handleSubmit, setFieldValue, errors, touched }) => {
            return (
              <Form translate="yes" onSubmit={handleSubmit}>
                <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                  <Grid spacing={3} container>
                    <Grid xs={6} item>
                      <NormalSelect
                        t={t}
                        language={language}
                        icon={userIcon}
                        label="User Type"
                        name="type"
                        value={values.type}
                        option={this.state.userList}
                        data-testid="user-type"
                        onChange={(e: any) => {
                          setFieldValue("type", e.target.value);
                          this.handleDisableField(e.target.value, values.individualBuilding);
                        }}
                      />
                      {ErrorHandler(t, touched.type, errors.type)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        icon={userIcon}
                        isLabel={true}
                        value={values.fullName}
                        data-testid="member-full-name"
                        label="Member full name"
                        id="fullName"
                        onChange={(e: any) => {
                          setFieldValue("fullName", e.target.value);
                        }}
                      />
                      {ErrorHandler(t, touched.fullName, errors.fullName)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        icon={emailIcon}
                        isLabel={true}
                        value={values.emailID}
                        label="Email Address"
                        data-testid="email-address"
                        id="emailId"
                        onChange={(e: any) => {
                          setFieldValue("emailID", e.target.value);
                        }}
                      />
                      {ErrorHandler(t, touched.emailID, errors.emailID)}
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="form__phone_numbers" display="flex" alignItems="center" style={{ width: "77%" }}>
                        <PhoneInput
                          inputProps={{ name: "countryCode" }}
                          enableSearch
                          data-testid="country-code"
                          onChange={(value: any) => {
                            setFieldValue("countryCode", value);
                          }}
                          value={values.countryCode}
                        />
                        <Box className="input_icon__form_box">
                          <InputLabel>{t("Phone Number")}</InputLabel>
                          <Field
                            as={TextField}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            className="input_box"
                            data-testid="phoneNumber-input"
                            placeholder={t("Phone Number")}
                            onChange={(e: any) => {
                              setFieldValue("phoneNumber", e.target.value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={phoneIcon} alt="icon" />
                                </InputAdornment>
                              ),
                            }}
                            value={values.phoneNumber}
                          />
                        </Box>
                      </Box>
                      {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        value={values.country}
                        name="country"
                        data-testid="select-country"
                        label="Country"
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("country", value);
                          this.getCityList(value);
                        }}
                        option={[...this.state.countryList]}
                      />
                      {ErrorHandler(t, touched.country, errors.country)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        value={values.city}
                        label="City"
                        key="city"
                        data-testid="select-city"
                        option={this.state.cityList}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("city", value);
                          this.getComplexList(value);
                        }}
                      />
                      {ErrorHandler(t, touched.city, errors.city)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        value={values.individualBuilding}
                        label="Complex/Individual Buildings"
                        data-testid="individual-building"
                        option={this.state.complexBuildingFilterList}
                        id="selectedIndividualBuilding"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          this.setState({ isBuildingSelected: value !== "complex" }, () => {
                            setFieldValue("individualBuilding", value);
                            this.handleIsComplex(value);
                            this.handleDisableField(values.type, value);
                          });
                        }}
                        disabled={!values.type}
                      />
                      {ErrorHandler(t, touched.individualBuilding, errors.individualBuilding)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        icon={buildingIcon}
                        value={values.complex}
                        label={values.individualBuilding === "complex" ? "Complex" : "Individual Building"}
                        data-testid="select-complex"
                        option={this.state.filterComplexList}
                        id="selectedComplex"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("complex", value);
                          if (values.individualBuilding === "complex") {
                            this.getBuildingsList(value);
                          } else {
                            this.getUnitList(value, "");
                          }
                        }}
                      />
                      {ErrorHandler(t, touched.complex, errors.complex)}
                    </Grid>
                    {!this.state.disableFieldBuilding && (
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          value={values.building}
                          label="Building"
                          data-testid="select-building"
                          option={this.state.buildingsList}
                          id="selectedBuilding"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("building", value);
                            this.getUnitList(values.complex, value);
                          }}
                        />
                        {ErrorHandler(t, touched.building, errors.building)}
                      </Grid>
                    )}
                    {!this.state.disableField && (
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          label="Unit"
                          value={values.unit}
                          data-testid="select-unit"
                          option={this.state.unitList}
                          id="selectedUnit"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("unit", value);
                          }}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions
                  className="dialog__button_group"
                  style={{ justifyContent: !this.state.isAlready ? "space-between" : "flex-end" }}
                >
                  {!this.state.isAlready && (
                    <Button className="cancel-button" data-testid="cancel-button" onClick={this.handleClose}>
                      {t("Cancel")}
                    </Button>
                  )}
                  <Box style={{ display: "flex", gap: "10px" }}>
                    {this.state.isAlready ? (
                      <Button className="cancel-button" data-testid="cancel-button" onClick={this.handleClose}>
                        {t("Cancel")}
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "225px", border: "1px solid #fc8434", color: "#fc8434" }}
                        onClick={() => this.setState({ inviteMemberModelOpen: false }, () => handleSubmit())}
                        className="cancel-button"
                        data-testid="resend-button"
                      >
                        {t("Send and Invite another")}
                      </Button>
                    )}
                    <Button type="submit" className="add-button" data-testid="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogActionsCss>
    );
  };

  invitationDetailValue = (t: (val: string) => string, label: string, value: string, xs: any) => {
    return (
      <Grid item xs={xs}>
        <Typography variant="body2">{t(label)}</Typography>
        <Typography variant="body2" style={{ color: "grey" }}>
          {value}
        </Typography>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        {this.renderInviteMember(t, language)}

        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation">
              <Typography variant="body1" className="">
                <span>{t("Community Management")}</span> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Requests Management")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("Requests Management")}
                </Typography>
              </Box>
            </Box>

            <Box className="request-tab-box">
              <Tab
                label={t("Complex")}
                data-testId="Complex-tab"
                className={this.handleActive(this.state.tabComplex)}
                onClick={this.handleTab}
              />
              <Tab
                label={t("Individual Buildings")}
                data-testId="Buildings-tab"
                className={this.handleActive(!this.state.tabComplex)}
                onClick={this.handleTab}
              />
            </Box>

            <Box className="filter-head-box">
              <FilterSelect
                t={t}
                language={language}
                label="Select Country"
                value={this.state.filter.country}
                onChange={(e: any) => this.handleFilterChange(e, "country")}
                option={[{ label: "All", value: "all" }, ...this.state.countryList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Select City"
                value={this.state.filter.city}
                onChange={(e: any) => this.handleFilterChange(e, "city")}
                option={[{ label: "All", value: "all" }, ...this.state.cityList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Company Name"
                value={this.state.filter.company}
                option={[{ label: "All", value: "all" }, ...this.state.companyList]}
                onChange={(e: any) => this.handleFilterChange(e, "company")}
              />
              <FilterSelect
                t={t}
                value={this.state.filter.complex}
                language={language}
                label={this.state.tabComplex ? "Select Complex" : "Select Individual Building"}
                option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                onChange={(e: any) => this.handleFilterChange(e, "complex")}
              />
              <Button
                data-testId="search-btn"
                className="action-search-btn"
                startIcon={<SearchIcon />}
                onClick={this.getRequestsManagementList}
              >
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Box className="action-card">
                  {this.state.requestsManagementList.length === 0 && (
                    <Grid item xs={12}>
                      <Box className="no-data-found">{t("No request found")}</Box>
                    </Grid>
                  )}
                  {this.state.requestsManagementList.map((item: any, index: number) => {
                    return (
                      <Box className="action-info-box" key={index}>
                        <Box className="action-content-box">
                          <Box className="content-box">
                            <Box className="action-info">
                              <img src={ceRealEstateIcon} alt="" />
                              <Box>
                                <span>{t("Real Estate Company")}</span>
                                <p className="bold-text">{item?.attributes?.compnay_name?.company_name || "-"}</p>
                              </Box>
                            </Box>
                            <Box className="action-info">
                              <img src={ceBuildingIcon} alt="" />
                              <Box>
                                <span>{this.state.tabComplex ? t("Complex") : t("Building")}</span>
                                <p className="bold-text">{item?.attributes?.name}</p>
                              </Box>
                            </Box>
                            <Button
                              id="handleInviteMember"
                              data-testid="invite-member"
                              onClick={() => this.handleFindInviteMemberModal(item.id)}
                            >
                              {t("Invite a new member")}
                            </Button>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className="action-info-body">
                          <Box className="info-body-box">
                            <Box className="table-row-heading">
                              <span>{t("Title")}</span>
                              <span>{t("Count")}</span>
                            </Box>
                            <NavLink to={`/CompanyEmployee/PendingJoinRequest/${item.id}/${this.handleUrl()}`}>
                              <Box className="table-row-body">
                                <span>{t("Pending join requests")}</span>
                                <span>{item.attributes.pending_join_request}</span>
                              </Box>
                            </NavLink>
                            <NavLink to={`/CompanyEmployee/AwaitAccept/${item.id}/${this.handleUrl()}`}>
                              <Box className="table-row-body">
                                <span>{t("Sent invitations awaiting acceptance")}</span>
                                <span>{item.attributes.sent_invitaion_awiting_acceptance}</span>
                              </Box>
                            </NavLink>
                            <NavLink to={`/CompanyEmployee/TotalSentInvite/${item.id}/${this.handleUrl()}`}>
                              <Box className="table-row-body">
                                <span>{t("Total Sent Invitations")}</span>
                                <span>{item.attributes.totle_sent_invitaion}</span>
                              </Box>
                            </NavLink>
                            <NavLink
                              to={`/CompanyEmployee/CETotalAcceptRejectInvite/${item.id}/Accept/${this.handleUrl()}`}
                            >
                              <Box className="table-row-body">
                                <span>{t("Accepted Invitations by users")}</span>
                                <span>{item.attributes.accepted_invitaion_by_user}</span>
                              </Box>
                            </NavLink>
                            <NavLink
                              to={`/CompanyEmployee/CETotalAcceptRejectInvite/${item.id}/Reject/${this.handleUrl()}`}
                            >
                              <Box className="table-row-body">
                                <span>{t("Rejected Invitation by users")}</span>
                                <span>{item.attributes.reject_invitaion_by_user}</span>
                              </Box>
                            </NavLink>
                            <NavLink
                              to={`/CompanyEmployee/CETotalAcceptRejectInvite/${item.id}/Total/${this.handleUrl()}`}
                            >
                              <Box className="table-row-body">
                                <span>{t("Total received join requests")}</span>
                                <span>{item.attributes.totle_recevied_join_request}</span>
                              </Box>
                            </NavLink>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isInvitePersonOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title={t("Invite Member")} onClose={this.handleFindInviteMemberModal} />
          {!this.state.isUserSelected && (
            <Box style={webStyle.subContent}>
              <InputLabel>{"Fill one or more fields to find person"}</InputLabel>
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Name")}
                  isLabel
                  value={this.state.person.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, name: e.target.value } })
                  }
                  data-test-id="name-filter"
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Email ID")}
                  isLabel
                  value={this.state.person.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, email: e.target.value } })
                  }
                  data-test-id="email-filter"
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Phone Number")}
                  isLabel
                  value={this.state.person.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ person: { ...this.state.person, phone: e.target.value } })
                  }
                  data-test-id="phone-filter"
                />
                <CommonButton
                  onClick={this.getFindUserList}
                  t={t}
                  data-test-id="search-filter-btn"
                  label={t("Search")}
                  startIcon={<SearchIcon />}
                  color="blue"
                />
              </Box>
            </Box>
          )}
          {!this.state.isUserSelected && !this.state.isInviteUserListEmpty && (
            <>
              <Divider />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "28px",
                }}
              >
                <Typography style={{ marginBottom: "18px" }}>
                  {t("Click on below button in case member does not exist already.")}
                </Typography>
                <CommonButton
                  onClick={this.inviteNewMemberFun}
                  t={t}
                  data-test-id="search-filter-btn"
                  label={t("Invite New Member")}
                  color="orange"
                />
              </Box>
            </>
          )}
          {!this.state.isUserSelected && this.state.isInviteUserListEmpty && (
            <>
              <Divider />
              <Box style={webStyle.subContent} className="select-meeting">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bold-text">{t("Name")}</TableCell>
                        <TableCell className="bold-text">{t("ID Number")}s</TableCell>
                        <TableCell className="bold-text">{t("Phone Number")}</TableCell>
                        <TableCell className="bold-text">{t("Email")}</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.inviteUserList.length === 0 && (
                        <>
                          <TableRow>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={5} style={{ border: 0 }}>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  marginTop: "28px",
                                }}
                              >
                                <Typography style={{ marginBottom: "18px" }}>{t("No Data Found.")}</Typography>
                                <CommonButton
                                  onClick={this.inviteNewMemberFun}
                                  t={t}
                                  data-test-id="search-filter-btn"
                                  label={t("Invite New Member")}
                                  color="orange"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {this.state.inviteUserList.map((user: any) => {
                        return (
                          <TableRow key={user.id}>
                            <TableCell>{t(user.attributes.full_name)}</TableCell>
                            <TableCell>{t(user.id + "")}</TableCell>
                            <TableCell>{t(user.attributes.full_phone_number)}</TableCell>
                            <TableCell>{t(user.attributes.email)}</TableCell>
                            <TableCell className="unit-user-menu">
                              <Menu menuButton={<MoreVertIcon />}>
                                <MenuItem data-test-id="view-menu" onClick={() => this.getRMFindUserDetail(user.id)}>
                                  {t("View Detail")}
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
          {this.state.isUserSelected && (
            <Box style={webStyle.subContent} className="select-meeting">
              <Grid container spacing={2}>
                {this.invitationDetailValue(t, "Name", this.state.userDetail.name, 3)}
                {this.invitationDetailValue(t, "Complex", this.state.userDetail.complex, 3)}
                {this.invitationDetailValue(t, "ID Number", this.state.userDetail.idNumber + "", 3)}
                <Grid item xs={3}>
                  <Typography variant="body2">{t("Local ID")}</Typography>
                  <img src={this.state.userDetail.idUrl} />
                </Grid>
                {this.invitationDetailValue(t, "Email", this.state.userDetail.email, 3)}
                {this.invitationDetailValue(t, "Address", this.state.userDetail.address, 9)}
              </Grid>
            </Box>
          )}
          <DialogActions className="dialog__button_group">
            {(this.state.isUserSelected || this.state.inviteUserList.length > 0) && (
              <Button
                data-test-id="find-cancel-btn"
                className="cancel-button"
                onClick={() => this.handleFindInviteMemberModal("")}
              >
                {t("Cancel")}
              </Button>
            )}
            {this.state.isUserSelected && (
              <Button onClick={this.handleSelectUser} className="add-button">
                {t("Select")}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <AlertError
          show={this.state.showError}
          data-test-id="rm-alert-error"
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CERequestManagementStyle: any = {
  RequestManagement: {
    background: "rgb(244, 247, 255)",
    "& .navigation": {
      flexDirection: "column",
      alignItems: "flex-start",
      display: "flex",
      "& .sub-heading-box": {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
        "& h5": {
          fontSize: "28px",
          fontWeight: "600",
        },
      },
    },
    "& .request-user-filter": {
      alignItems: "center",
      width: "100%",
      flexWrap: "wrap",
      display: "flex",
      margin: "10px 0 15px 0",
      gap: "18px",
      "& .select-year": {
        padding: "12px 12px",
        background: "#fff",
        border: "1px solid lightgrey",
        fontSize: "14px",
        minWidth: "110px",
        borderRadius: 5,
        fontWeight: "bold",
      },
      "& button": {
        color: "white",
        borderRadius: "8px",
        fontWeight: "600",
        background: "#2B6FED",
        padding: "7px 18px",
        height: "40px",
        "& .MuiButton-startIcon": {
          marginLeft: "0",
        },
      },
    },
    "& .request-tab-box": {
      marginTop: "15px",
      marginBottom: "25px",
      alignItems: "center",
      display: "flex",
      gap: "10px",
      "& .MuiTab-root": {
        borderRadius: "25px",
        minWidth: "auto",
        minHeight: "auto",
        background: "#EDF0F9",
        textTransform: "capitalize",
        fontSize: "16px",
        marginRight: "10px",
        padding: "6px 20px",
        color: "gray",
      },
      "& .MuiTab-root.active": {
        color: "white",
        background: "#2C6DF3",
        fontWeight: "600",
        opacity: 1,
      },
      "& Button": {
        color: "white",
        textTransform: "capitalize",
        fontSize: "16px",
        background: "#FE8335",
        minHeight: "auto",
        padding: "6px 20px",
        minWidth: "auto",
        borderRadius: "25px",
        boxShadow: "none",
      },
    },
    "& .action-card": {
      background: "#fff",
      borderRadius: 8,
      boxShadow: "4px 0px 14px #e9e9e9",
      "& .action-info-box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        "& .action-info": {
          display: "flex",
          alignItems: "center",
          gap: "15px",
          "& span": {
            fontSize: "14px",
            color: "#FC8434",
          },
          "& p": {
            fontWeight: "600",
          },
        },
        "& button": {
          background: "#fc8434",
          color: "white",
          borderRadius: "8px",
          fontWeight: "600",
          padding: "8px 20px",
          fontSize: "14px",
          textTransform: "uppercase",
        },
        "& .action-content-box": {
          width: "100%",
          "& .content-box": {
            display: "flex",
            alignItems: "center",
            gap: "50px",
            padding: "20px",
            justifyContent: "space-between",
          },
        },
      },
      "& .no-data-found": {
        display: "flex",
        alignItems: "center",
        padding: "12px",
        justifyContent: "center",
      },
      "& hr": {
        width: "100%",
      },
      "& .action-info-body": {
        width: "100%",
        "& .info-body-box": {
          padding: "10px 20px",
        },
        "& .table-row-heading": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "15px",
          color: "#c7c7c7",
          padding: "10px 0",
          borderBottom: "1px solid #f9f9f9",
        },
        "& .table-row-body": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "16px",
          padding: "10px 0",
          borderBottom: "1px solid #f9f9f9",
          "& span:last-child": {
            fontWeight: "600",
          },
        },
        "& .table-row-body:last-child": {
          borderBottom: "0",
        },
      },
    },
  },
};

const DialogActionsCss = styled(Dialog)({
  "& .form__phone_numbers": {
    position: "relative",
    "& .react-tel-input": {
      position: "absolute",
      left: 0,
      top: "33px",
      zIndex: 9,
      width: "fit-content",
      borderRight: "1px solid #f0f0f0",
      "& .country-list": { position: "fixed" },
      "& .selected-flag": { background: "transparent" },
    },
    "& .input_icon__form_box .input_box": {
      paddingLeft: "120px",
      width: "91%",
    },
    "& .input_box": {
      borderRadius: "8px",
      padding: "10px 12px",
      outline: "none",
      fontSize: "16px",
    },
    "& .input_box::placeholder": {
      borderBottom: "none",
      textDecoration: "none !important",
    },
  },
});

const webStyle: any = {
  subContent: { padding: "10px 30px" },
};

export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CERequestManagement)));
// Customizable Area End
