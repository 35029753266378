// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button, InputAdornment, InputLabel } from "@material-ui/core";
import CompEmpBuildingBlockStep2Controller, { Props } from "./CompEmpBuildingBlockStep2Controller.web";
import { Formik, Form } from "formik";
import "../../assets/commonForm.css";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { del_image, desigenImg, uploadbw } from "../assets";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";

export default class CompEmpBuildingBlockStep2 extends CompEmpBuildingBlockStep2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Formik
          data-test-id="individual-building-form"
          initialValues={this.state.buildingFormValues}
          validationSchema={this.validationSchema}
          enableReinitialize
          onSubmit={(values) => {
            this.setState({ loading: true }, () => {
              this.handleSubmitIBuilding(values);
            });
          }}
        >
          {({ values, touched, errors, setFieldValue, handleChange, handleSubmit, handleBlur }) => (
            <Form onSubmit={handleSubmit} translate="yes">
              <Box className="topBarFormik">
                <Box
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  className="step2forms"
                >
                  <Typography className="step2forms bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
                    {t("Basic Details")}
                  </Typography>
                  <Typography className="Step step2forms" style={{ fontSize: "16px" }}>
                    {t("Step")} {this.props.step + 1} {t("of 7")}
                  </Typography>
                </Box>
                <Box className="step2forms step2Formik" style={webStyle.logContainer}>
                  {values.logo ? (
                    <img
                      src={typeof values.logo === "string" ? values.logo : URL.createObjectURL(values.logo)}
                      style={webStyle.logoDisplay}
                      className="complex_building_step1"
                    />
                  ) : (
                    <Box className="complex_building_step1" style={webStyle.logoDisplay} />
                  )}
                  <label htmlFor="building-logo" className="complex_building_step1">
                    <span style={{ color: "#FC8434" }} className="bold-text">
                      {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                    </span>
                  </label>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    data-test-id="logo-upload"
                    name="logo"
                    id="building-logo"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const files = event.target.files;
                      if (files) {
                        const file = files[0];
                        setFieldValue("logo", file);
                      }
                    }}
                  />
                  {ErrorHandler(t, touched.logo, errors.logo)}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <NormalTextField
                      value={values.buildingName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="buildingName"
                      t={t}
                      language={language}
                      label={t("Building Name")}
                      isLabel
                    />
                    {ErrorHandler(t, touched.buildingName, errors.buildingName)}
                  </Grid>
                  <Grid item xs={6}>
                    <NormalTextField
                      value={values.totalArea}
                      type="number"
                      t={t}
                      onChange={handleChange}
                      name="totalArea"
                      language={language}
                      isLabel
                      label={t("Total Area")}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end" className="measurement-adornment-select">
                          <NormalSelect
                            t={t}
                            onChange={handleChange}
                            value={values.measurement}
                            option={this.state.measurementList}
                            name="measurement"
                            onBlur={handleBlur}
                            language={language}
                          />
                        </InputAdornment>
                      }
                    />
                    {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor="uploadPhotos" style={{ marginBottom: "8px", color: "black", fontSize: "14px" }}>
                      {t("Upload Photos")}
                    </label>
                    <Grid container spacing={4}>
                      <Grid item xs={3} style={{ marginBottom: "5px" }} className="edit-building">
                        <label htmlFor="building-images" style={{ marginTop: "12px" }}>
                          <Box className="upload-photo">
                            <img src={uploadbw} alt="edit-complex-upload-icon" />
                          </Box>
                        </label>
                        <input
                          className="complex-details-image"
                          style={{ display: "none" }}
                          name="photos"
                          onBlur={handleBlur}
                          multiple
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const files = e.target.files;
                            if (files) {
                              setFieldValue("images", [...values.images, ...files]);
                            }
                          }}
                          id="building-images"
                          accept="image/*"
                          type="file"
                          data-test-id="image-upload"
                        />
                      </Grid>
                      {[...values.images].map((image: any, index: number) => {
                        return (
                          <Grid item xs={3} key={index}>
                            <Box className="building-image" style={{ position: "relative" }}>
                              <img
                                style={webStyle.deleteImage}
                                className="delete-image"
                                src={del_image}
                                onClick={() => {
                                  const remainImage = [...values.images].filter(
                                    (img: File, idx: number) => idx !== index
                                  );
                                  setFieldValue("images", remainImage);
                                }}
                              />
                              <img
                                src={typeof image === "string" ? image : URL.createObjectURL(image)}
                                style={{ height: "124px", width: "100%", borderRadius: "8px" }}
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {ErrorHandler(t, touched.images, errors.images)}
                  </Grid>
                  <Grid item xs={6}>
                    <NormalTextField
                      value={values.totalFloors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="totalFloors"
                      t={t}
                      language={language}
                      label={t("Total Floors")}
                      type="number"
                      isLabel
                    />
                    {ErrorHandler(t, touched.totalFloors, errors.totalFloors)}
                  </Grid>
                  <Grid item xs={6}>
                    <NormalTextField
                      value={values.totalUnits}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="totalUnits"
                      t={t}
                      language={language}
                      label={t("Total Units")}
                      type="number"
                      isLabel
                    />
                    {ErrorHandler(t, touched.totalUnits, errors.totalUnits)}
                  </Grid>
                  <Grid item xs={12}>
                    <NormalTextArea
                      name="aboutUs"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      t={t}
                      language={language}
                      label={t("About Us")}
                      value={values.aboutUs}
                      isLabel
                    />
                    {ErrorHandler(t, touched.aboutUs, errors.aboutUs)}
                  </Grid>
                  <Grid item xs={6}>
                    <NormalSelect
                      onBlur={handleBlur}
                      name="currency"
                      t={t}
                      label="Currency"
                      value={values.currency}
                      onChange={handleChange}
                      option={this.state.currencyList}
                      language={language}
                    />
                    {ErrorHandler(t, touched.currency, errors.currency)}
                  </Grid>
                  <Grid item xs={6}>
                    <NormalSelect
                      t={t}
                      value={values.accountManager}
                      label="Account Manager"
                      name="accountManager"
                      option={this.state.managerList}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      language={language}
                    />
                    {ErrorHandler(t, touched.accountManager, errors.accountManager)}
                  </Grid>
                </Grid>
                <Box style={{ marginTop: "20px" }}>
                  <Box style={{ width: "100%" }}>
                    <InputLabel>{t("Default Renting Contract:")}</InputLabel>
                    <Box className="leaseTemplate" style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                      {this.state.leaseList.map((lease: any, index: number) => {
                        return (
                          <Box
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => setFieldValue("rentalSelected", lease.id)}
                            data-test-id="select-rent-contract"
                          >
                            <Box
                              style={
                                Number(values.rentalSelected) !== Number(lease.id)
                                  ? webStyle.rentStyle
                                  : webStyle.rentSelected
                              }
                            >
                              <Box className="leaseTemplate" style={webStyle.rentImageContainer}>
                                <img className="leaseTemplate" src={desigenImg} />
                              </Box>
                              <Box
                                style={
                                  Number(values.rentalSelected) !== Number(lease.id)
                                    ? webStyle.rentTitleStyle
                                    : webStyle.rentSelectedTitle
                                }
                              >
                                {lease.attributes.title}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", marginTop: "30px" }}>
                <Button style={webStyle.buttonSave} type="submit">
                  <span className="bold-text">{t("NEXT")}</span>
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const webStyle: any = {
  buttonSave: {
    display: "flex",
    width: "177px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    fontWeight: "bold",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    padding: "0px 20.5px",
    height: "56px",
    textTransform: "capitalize",
  },
  logContainer: {
    margin: "28px 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoDisplay: {
    height: "66px",
    background: "#dcdcdc",
    width: "66px",
    borderRadius: "50%",
  },
  deleteImage: {
    position: "absolute",
    cursor: "pointer",
    right: "5px",
    top: "5px",
    width: "35px",
    height: "35px",
  },
  rentSelected: {
    marginRight: "10px",
    width: "168",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    height: "127",
    justifyContent: "center",
    border: "2px solid #2b6fed",
  },
  rentStyle: {
    border: "1px dashed #e4e4e4",
    marginRight: "10px",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    height: "127",
    display: "flex",
    width: "168",
    boxShadow: "0px 6px 14px rgba(43,111,237,0.12)",
  },
  rentSelectedTitle: {
    fontStyle: "normal",
    marginBottom: "10px",
    fontSize: "14px",
    color: "#2B6FED",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "center",
  },
  rentTitleStyle: {
    fontWeight: 700,
    color: "#181d25",
    fontStyle: "normal",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  rentImageContainer: {
    padding: "20px",
    marginBottom: "10px",
    border: "5px solid #f7f9fe",
    borderRadius: "100%",
    width: "max-content",
  },
};
// Customizable Area End
