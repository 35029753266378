// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpComplexBlockStep1Controller, { Props } from "./CompEmpComplexBlockStep1Controller.web";
import "../../assets/commonForm.css";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";

export default class CompEmpComplexBlockStep1 extends CompEmpComplexBlockStep1Controller {
  constructor(props: Props) {
    super(props);
  }

  handleDetails = (t: (val: string) => string, label: string, value: string) => {
    return (
      <Grid item xs={4}>
        <Typography className="typographyLable">{t(label)}</Typography>
        <Box className="fontSizeBox">{value ? value : "(N/A)"}</Box>
      </Grid>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box className="topBarFormik">
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="Basic bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Jurisdiction")}
            </Typography>
            <Typography style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of")} 7
            </Typography>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <NormalSelect
              t={t}
              language={language}
              label="Jurisdiction"
              value={this.props.jurisdictionSelected}
              name="type"
              onChange={this.changeJurisdiction}
              option={this.state.jurisdictionList}
              data-test-id="jurisdiction-select"
            />
          </Box>
          <Box style={{ marginTop: "25px" }}>
            <Grid container spacing={2}>
              {this.handleDetails(t, "Currency", this.state.jurisdictionDetail.currency)}
              {this.handleDetails(t, "Timezone", this.state.jurisdictionDetail.timezone)}
              {this.handleDetails(t, "Unit of Measurement", this.state.jurisdictionDetail.measurement)}
              {this.handleDetails(t, "Management Fee Calculation Formula", this.state.jurisdictionDetail.formula)}
              {this.handleDetails(t, "Mobile number length", this.state.jurisdictionDetail.mobileLength)}
              {this.handleDetails(t, "Default Renting Contract", this.state.jurisdictionDetail.contract)}
            </Grid>
          </Box>
        </Box>
        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
          <Grid className="step1Grid" style={{ marginTop: "30px" }}>
            <Button
              className="step1Next"
              style={webStyle.buttonSave}
              disabled={!this.props.jurisdictionSelected}
              data-test-id="jurisdiction-next"
              onClick={this.props.handleNext}
            >
              <span className="bold-text">{t("NEXT")}</span>
            </Button>
          </Grid>
        </Box>
      </>
    );
  }
}

const webStyle: any = {
  buttonSave: {
    width: "177px",
    height: "56px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
  },
};

// Customizable Area End
