// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import * as Yup from "yup";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  step: number;
  handleNext: any;
  handleBack: any;
  jurisdiction: string | number;
  complexID: string | number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  isSharedFacilityOpen: boolean;

  facilityList: any[];

  editId: string;

  facilityFormValues: IFacilityFormValue;
}

interface IFacilityFormValue {
  name: string;
  images: any[];
  about: string;
  area: string;
  fees: string;
  document: any;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep6Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexSharedFacilityListCallId: string = "";
  DeleteComplexSharedApiCallId: string = "";
  AddEditSharedFacilityAPICallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isSharedFacilityOpen: false,

      facilityList: [],

      editId: "",
      facilityFormValues: {
        name: "",
        images: [],
        about: "",
        area: "",
        fees: "",
        document: null,
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetComplexSharedFacilityListCallId:
          return this.handleComplexSharedFacilityListResponse(responseJsonBlock);
        case this.DeleteComplexSharedApiCallId:
          return this.handleDeleteFacilityResponse();
        case this.AddEditSharedFacilityAPICallId:
          return this.handleSharedFacilityResponse();
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getComplexSharedFacilityList();
    });
  }

  getComplexSharedFacilityList = async () => {
    this.GetComplexSharedFacilityListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/common_area_list?society_management_id=${
        this.props.complexID
      }`,
    });
  };

  handleComplexSharedFacilityListResponse = (response: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (response && response.data) {
        this.setState({ facilityList: response.data });
      }
    });
  };

  handleComplexDeleteFacility = (id: string | number) => {
    this.setState({ loading: true }, async () => {
      this.DeleteComplexSharedApiCallId = await apiCall({
        method: "DELETE",
        endPoint: `bx_block_society_management/common_areas/${id}`,
      });
    });
  };

  handleDeleteFacilityResponse = () => {
    this.setState({ loading: true }, () => {
      this.getComplexSharedFacilityList();
    });
  };

  handleEditSharedArea = async (item: any) => {
    let photos: any[] = [];
    if (item.attributes.photos.length > 0) {
      photos = await handleImages(item.attributes.photos);
    }

    let floor: any = null;
    if (item.attributes.floor_plan) {
      const floors = await handleImages([item.attributes.floor_plan]);
      floor = floors[0];
    }

    this.setState({
      editId: item.id,
      isSharedFacilityOpen: true,
      facilityFormValues: {
        name: item.attributes.name,
        images: photos,
        about: item.attributes.details,
        area: item.attributes.total_area,
        fees: item.attributes.reservation_fee,
        document: floor,
      },
    });
  };

  handleSharedFacilityModal = () => {
    this.setState({
      isSharedFacilityOpen: !this.state.isSharedFacilityOpen,
      editId: "",
      facilityFormValues: {
        name: "",
        images: [],
        about: "",
        area: "",
        fees: "",
        document: null,
      },
    });
  };

  addSharedFacility = async (values: IFacilityFormValue) => {
    const formData = new FormData();
    formData.append(`common_area[name]`, values.name);
    formData.append("common_area[society_management_id]", this.props.complexID + "");
    formData.append(`common_area[details]`, values.about);
    formData.append(`common_area[total_area]`, values.area);
    formData.append(`common_area[reservation_fee]`, values.fees);
    formData.append(`common_area[floor_plan]`, values.document || "");

    [...values.images].forEach((photo: any) => {
      formData.append(`common_area[photos][]`, photo);
    });

    this.AddEditSharedFacilityAPICallId = await apiCall({
      body: formData,
      method: "POST",
      endPoint: `bx_block_society_management/common_areas`,
    });
  };

  editSharedFacility = async (values: IFacilityFormValue) => {
    const data = new FormData();
    data.append(`common_area[reservation_fee]`, values.fees);
    data.append(`common_area[total_area]`, values.area);
    data.append(`common_area[floor_plan]`, values.document || "");
    data.append(`common_area[details]`, values.about);

    [...values.images].forEach((photo: any) => {
      data.append(`common_area[photos][]`, photo);
    });

    this.AddEditSharedFacilityAPICallId = await apiCall({
      body: data,
      method: "PATCH",
      endPoint: `bx_block_society_management/common_areas/${this.state.editId}`,
    });
  };

  handleSharedFacilityResponse = () => {
    this.setState({ loading: false }, () => {
      this.getComplexSharedFacilityList();
    });
  };

  handleSubmitForm = (values: IFacilityFormValue, resetForm: any) => {
    this.setState({ loading: true, isSharedFacilityOpen: false }, () => {
      if (this.state.editId) {
        this.editSharedFacility(values);
      } else {
        this.addSharedFacility(values);
      }
      resetForm();
    });
  };

  validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Facility name must be at least 3 characters")
      .max(50, "Facility name must be at most 50 characters")
      .required("Facility Name is required"),
    images: Yup.array()
      .min(1, "At least one image is required")
      .max(3, "Maximum of 3 images allowed")
      .required("Please upload images")
      .nullable(),
    about: Yup.string().required("Details are required"),
    area: Yup.string().required("Total Area is required"),
    fees: Yup.string().required("Reservation fees is required"),
    document: Yup.mixed().required("Please upload a Floor Document File"),
  });
}

// Customizable Area End
