// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpBuildingBlockController, { Props } from "./CompEmpBuildingBlockController.web";
import { withTranslation } from "react-i18next";
import "../../assets/commonForm.css";
import CompEmpBuildingBlockStep1 from "./CompEmpBuildingBlockStep1.web";
import CompEmpBuildingBlockStep2 from "./CompEmpBuildingBlockStep2.web";
import CompEmpBuildingBlockStep3 from "./CompEmpBuildingBlockStep3.web";
import CompEmpBuildingBlockStep4 from "./CompEmpBuildingBlockStep4.web";
import CompEmpBuildingBlockStep5 from "./CompEmpBuildingBlockStep5.web";
import CompEmpBuildingBlockStep6 from "./CompEmpBuildingBlockStep6.web";
import CompEmpBuildingBlockStep7 from "./CompEmpBuildingBlockStep7.web";

const multiPleButtonComplex = [
  "Jurisdiction",
  "Basic Details",
  "Documents",
  "Location",
  "Units",
  "Shared Area",
  "Core Members",
];

class CompEmpBuildingBlock extends CompEmpBuildingBlockController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <Box style={{ padding: "0 40px 40px", backgroundColor: "rgb(244, 247, 255)" }}>
        <Typography style={{ fontSize: "16px", display: "flex" }}>
          {t("Real Estate Companies")} / {t("Company Details")} /{" "}
          <p style={{ color: "#2B6FED" }}>{this.handleHeading(t)}</p>
        </Typography>
        <Typography style={{ fontSize: "32px", marginTop: "6px" }} className="bold-text">
          {this.handleHeading(t)}
        </Typography>
        <Box style={{ marginTop: "12px", display: "flex", flexWrap: "wrap" }}>
          {multiPleButtonComplex.map((val: any, index: number) => {
            return (
              <Box
                key={index}
                data-test-id="building-tab-container"
                onClick={() => this.handleStepClick(index)}
                style={{
                  ...webBuildingStyle.tabContainer,
                  backgroundColor: this.state.stepBuilding == index ? "#2B6FED" : "#C8C8C81F",
                }}
              >
                {this.state.stepBuilding === index ? (
                  <Button className="bold-text" style={{ color: "#FFFFFF", ...webBuildingStyle.tabBtn }}>
                    {t(val)}
                  </Button>
                ) : (
                  <Button
                    style={{ ...webBuildingStyle.tabBtn, color: this.state.stepBuilding < index ? "grey" : "#2B6FED" }}
                  >
                    {t(val)}
                  </Button>
                )}
              </Box>
            );
          })}
        </Box>
        <Grid>
          {this.state.stepBuilding === 0 && (
            <CompEmpBuildingBlockStep1
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              handleChangeJurisdiction={this.handleChangeJurisdiction}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 1 && (
            <CompEmpBuildingBlockStep2
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              updateComplexBuildingId={this.handleUpdateComplexBuildingId}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 2 && (
            <CompEmpBuildingBlockStep3
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 3 && (
            <CompEmpBuildingBlockStep4
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 4 && (
            <CompEmpBuildingBlockStep5
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 5 && (
            <CompEmpBuildingBlockStep6
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 6 && (
            <CompEmpBuildingBlockStep7
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
              navigation={this.props.navigation}
            />
          )}
        </Grid>
      </Box>
    );
  }
}

const webBuildingStyle: any = {
  tabContainer: {
    width: "max-content",
    display: "flex",
    height: "43px",
    borderRadius: "21.5px",
    justifyContent: "center",
    marginRight: "15px",
    textAlign: "center",
    marginTop: "8px",
  },
  tabBtn: {
    padding: "0px 20.5px",
    fontWeight: 800,
    textTransform: "capitalize",
  },
};

export default withTranslation()(CompEmpBuildingBlock);
// Customizable Area End
