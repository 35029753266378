// Customizable Area Start
import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";

const FilterDateField = ({ t, language, label, option, value, ...props }: any) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        className="input__filter_input select-year date__filter_input"
        variant="inline"
        placeholder={t(label)}
        format="DD/MM/YYYY"
        fullWidth
        value={value}
        renderInput={(params: any) => <TextField {...params} />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FilterDateField;
// Customizable Area End
