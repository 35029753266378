import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  isRegisterTenantOpen: boolean;
  loading: boolean;
  complexList: any[];
  buildingList: any[];
  unitList: any[];
  idTypeList: any[];
  isNowContract: boolean;


  registerTenantForm: TenantForm;

  contract: any;
  contractPageCount: string;

  tenantId: string;
  tenantDetails: any;
  isBuilding: boolean;
  isTenant: any;

  error: string;
  showError: boolean;

}

interface TenantForm {
  tenantName: string;
  tenantType: string;
  tenantCountryCode: string;
  tenantMobile: string;
  complex: string;
  building: string;
  tenantEmail: string;
  unit: string;
  idType: string;
  idNumber: string;
  idDate: string;
  idCard: any[];
  otherDocument: any[];
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RegisterTenantController extends BlockComponent<Props, S, SS> {
  GetComplexListCallId: any;
  GetBuildingListCallId: any;
  GetUnitListCallId: any;
  GetIDTypeListCallId: any;
  CreateTenantCallId: any;
  GetTenantDetailsCallId: any;
  CreateContractCallId: any;
  GetTenantDetailsForEditCallId: any;
  EditTenantCallId: any;
  CreateTenantForContractCallId: any;
  IsTenantExistCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isRegisterTenantOpen: true,
      isNowContract: true,

      complexList: [],
      buildingList: [],
      unitList: [],
      idTypeList: [],
      registerTenantForm: {
        tenantType: "",
        tenantName: "",
        tenantCountryCode: "+971",
        tenantMobile: "",
        tenantEmail: "",
        complex: "",
        building: "",
        unit: "",
        idType: "",
        idNumber: "",
        idDate: "",
        idCard: [],
        otherDocument: [],
      },

      contract: null,
      contractPageCount: "",

      tenantId: "",
      tenantDetails: null,

      isTenant: null,

      error: "",
      showError: false,

      isBuilding: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.getComplexList();
    this.getIdTypeList();
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetComplexListCallId:
          this.handleGetComplexListResponse(responseJson);
          break;
        case this.GetBuildingListCallId:
          this.handleGetBuildingListResponse(responseJson);
          break;
        case this.GetUnitListCallId:
          this.handleGetUnitListResponse(responseJson);
          break;
        case this.GetIDTypeListCallId:
          this.handleGetIdTypeListResponse(responseJson);
          break;
        case this.CreateTenantCallId:
          this.handleSubmitRegisterTenantResponse(responseJson);
          break;
        case this.CreateTenantForContractCallId:
          this.handleSubmitTenantForContractResponse(responseJson);
          break;
        case this.GetTenantDetailsCallId:
          this.handleGetTenantDetailsResponse(responseJson);
          break;
        case this.CreateContractCallId:
          this.handleSubmitTenantContractResponse(responseJson);
          break;
        case this.GetTenantDetailsForEditCallId:
          this.handleGetTenantDetailsForEditResponse(responseJson);
          break;
        case this.EditTenantCallId:
          this.handleEditTenantResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  

  uploadIDCard: any;
  uploadOtherDocument: any;
  uploadContract: any;

  getComplexList = async () => {
    const user_type = localStorage.getItem("userType");

    this.GetComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_type}`,
    });
  };

  handleGetComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complex) {
      this.setState({ complexList: responseJson.complex });
    }
  };

  handleRegisterTenantComplex = (complex: any, setFieldValue: any) => {
    const complexId = complex.id;
    setFieldValue("complex", complexId);
    if (complex.is_building) {
      this.setState({ isBuilding: false }, () => {
        const buildingId = complex.is_building_list[0].id;
        setFieldValue("building", buildingId);
        this.getUnitList(complexId, buildingId);
      });
    } else {
      this.setState({ isBuilding: true }, () => {
        this.getBuildingList(complexId);
        setFieldValue("building", "");
      });
    }
  };

  getBuildingList = (complex: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetBuildingListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${complex}/bx_block_meeting/find_building`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingList: responseJson.buildings });
    }
  };

  getUnitList = (society_id: any, building: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetUnitListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/tenant_unit_list?society_management_id=${society_id}&building_management_id=${building}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.apartments) {
      this.setState({ unitList: responseJson.data.apartments });
    }
  };

  getIdTypeList = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetIDTypeListCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_family/id_proofs`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetIdTypeListResponse = (responseJson: any) => {
    if (responseJson && responseJson.relaions) {
      this.setState({ idTypeList: responseJson.relaions });
    }
  };

  handleSubmitRegisterTenant = (values: TenantForm, isNowContract: boolean) => {
    this.setState({ loading: true, isNowContract: isNowContract });
    const otherDocument: any = [...values.otherDocument];
    let data = new FormData();
    const role = localStorage.getItem("userType") || "";
    data.append("data[role]", role);
    data.append("[tenant_resquest][name]", values.tenantName);
    data.append("[tenant_resquest][phone_number]", values.tenantCountryCode + "-" + values.tenantMobile);
    data.append("[tenant_resquest][email]", values.tenantEmail);
    data.append("[tenant_resquest][building_management_id]", values.building);
    data.append("[tenant_resquest][apartment_management_id]", values.unit);
    data.append("[tenant_resquest][id_proof_id]", values.idType);
    data.append("[tenant_resquest][id_number]", values.idNumber);
    data.append("[tenant_resquest][id_expectation_date]", values.idDate);
    data.append("[tenant_resquest][tenant_type]", values.tenantType);
    if(values.idCard[0]) {
      data.append("[tenant_resquest][tenant_id_copy]", values.idCard[0]);
    }

    window.sessionStorage.setItem("isBuildingAvailable", `${this.state.isBuilding}`);
    window.sessionStorage.setItem("complexId", values.complex);
    window.sessionStorage.setItem("buildingId", values.building);
    window.sessionStorage.setItem("unitId", values.unit);

    otherDocument.map((document: any) => {
      data.append("[tenant_resquest][tenant_documents][]", document);
    });

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateTenantCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_contract/tenant_resquests`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleSubmitRegisterTenantResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        toast.success("Tenant created successfully");
        localStorage.removeItem("isComingFromContract");
        if (this.state.isNowContract) {
          this.props.navigation.navigate("IssueLease");
        } else {
          this.props.navigation.navigate("TenantList");
        }
      }
    });
  };

  handleEditTenant = (values: TenantForm) => {
    this.setState({ loading: true });
    const otherDocument: any = [...values.otherDocument];

    let data = new FormData();
    data.append("[tenant_resquest][name]", values.tenantName);
    data.append("[tenant_resquest][phone_number]", values.tenantCountryCode + "-" + values.tenantMobile);
    data.append("[tenant_resquest][id_proof_id]", values.idType);
    data.append("[tenant_resquest][id_number]", values.idNumber);
    data.append("[tenant_resquest][id_expectation_date]", values.idDate);
    data.append("[tenant_resquest][tenant_type]", values.tenantType);
    if(values.idCard[0]) {
      data.append("[tenant_resquest][tenant_id_copy]", values.idCard[0]);
    }
    otherDocument.map((document: any) => {
      data.append("[tenant_resquest][tenant_documents][]", document);
    });

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      token: localStorage.getItem("userToken"),
    };

    this.EditTenantCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/tenant_resquests/${this.state.tenantId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleEditTenantResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        toast.success("Tenant updated successfully");
        this.props.navigation.navigate("TenantDetails", { id: responseJson.data.id });
      }
    });
  };

  getTenantDetails = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetTenantDetailsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/tenant_resquests/${this.state.tenantId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetTenantDetailsResponse = (responseJson: any) => {
    if (responseJson.code === 200) {
      this.setState({ tenantDetails: responseJson.tenant.data }, () => {
        this.handleSubmitTenantContract();
      });
    }
  };

  getTenantDetailsForEdit = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetTenantDetailsForEditCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/tenant_resquests/${this.state.tenantId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetTenantDetailsForEditResponse = async (responseJson: any) => {
    if (responseJson.code === 200) {
      const tenant = responseJson.tenant.data;

      let IdCardFile: any = [];
      if(tenant.attributes.tenant_id_copy) {
      const IdCardCopy: any[] = [tenant.attributes.tenant_id_copy];

      const IdCardCopyUrlPromise: any[] = IdCardCopy.map(async (file: any) => {
        return new Promise(async (resolve, reject) => {
          let blobString = await this.fileUrlToDataURL(file.url);
          resolve(blobString);
        });
      });

      let IdCardCopyFilesPromise = await Promise.allSettled(IdCardCopyUrlPromise);
      let IdCardCopyFiles = IdCardCopyFilesPromise.map((file: any) => file.value);

      IdCardFile = IdCardCopyFiles.map((blobString: any, index: number) => {
        return this.dataURLtoFileObject(blobString, IdCardCopy[index].file_name);
      });
      }

      let otherDocumentFile: any = [];
      if(tenant.attributes?.tenant_documents) {
      const otherDocumentUrlPromise: any[] = tenant.attributes.tenant_documents.map(async (file: any) => {
        return new Promise(async (resolve, reject) => {
          let blobString = await this.fileUrlToDataURL(file.url);
          resolve(blobString);
        });
      });

      let otherDocumentFilesPromise = await Promise.allSettled(otherDocumentUrlPromise);
      let otherDocumentFiles = otherDocumentFilesPromise.map((file: any) => file.value);

      otherDocumentFile = otherDocumentFiles.map((blobString: any, index: number) => {
        return this.dataURLtoFileObject(blobString, tenant.attributes.tenant_documents[index].file_name);
      });
      }

      this.setState({
        loading: false,
        registerTenantForm: {
          tenantType: tenant.attributes.tenant_type,
          tenantName: tenant.attributes.name,
          tenantCountryCode: tenant.attributes.phone_number.split("-")[0],
          tenantMobile: tenant.attributes.phone_number.split("-")[1],
          tenantEmail: tenant.attributes.email,
          complex: tenant.attributes.society_management.name,
          building: tenant.attributes.building_management.name,
          unit: tenant.attributes.apartment_management.apartment_name,
          idType: tenant.attributes.id_proof.id,
          idNumber: tenant.attributes.id_number,
          idDate: tenant.attributes.id_expectation_date,
          idCard: IdCardFile,
          otherDocument: otherDocumentFile,
        },
      });
    }
  };

  // Create Tenant For Contract - API
  handleSubmitTenantForContract = (values: TenantForm) => {
    this.setState({ loading: true });
    const otherDocument: any = [...values.otherDocument];
    let data = new FormData();
    data.append("[tenant_resquest][name]", values.tenantName);
    data.append("[tenant_resquest][phone_number]", values.tenantCountryCode + "-" + values.tenantMobile);
    data.append("[tenant_resquest][email]", values.tenantEmail);
    data.append("[tenant_resquest][building_management_id]", values.building);
    data.append("[tenant_resquest][apartment_management_id]", values.unit);
    data.append("[tenant_resquest][id_proof_id]", values.idType);
    data.append("[tenant_resquest][id_number]", values.idNumber);
    data.append("[tenant_resquest][id_expectation_date]", values.idDate);
    data.append("[tenant_resquest][tenant_type]", values.tenantType);
    if(values.idCard[0]) {
      data.append("[tenant_resquest][tenant_id_copy]", values.idCard[0]);
    }
    otherDocument.map((document: any) => {
      data.append("[tenant_resquest][tenant_documents][]", document);
    });

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateTenantForContractCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_contract/tenant_resquests`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleSubmitTenantForContractResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ tenantId: responseJson.data.id }, () => {
          this.getTenantDetails();
        });
      }
    });
  };

  handleSubmitTenantContract = () => {
    var data = new FormData();
    data.append("[contract][apartment_management_id]", this.state.tenantDetails.attributes.apartment_management_id);
    data.append("[contract][account_id]", this.state.tenantDetails.attributes.account.id);
    data.append("[contract][tenant_id]", this.state.tenantDetails.attributes.tenant.id);
    data.append("[contract][building_management_id]", this.state.tenantDetails.attributes.building_management.id);
    data.append(
      "[contract][society_management_id]",
      this.state.tenantDetails.attributes.building_management.society_management_id
    );
    data.append("[contract][landlord_name]", this.state.tenantDetails.attributes.account.full_name);
    data.append("[contract][tenant_name]", this.state.tenantDetails.attributes.tenant.full_name);
    data.append("[contract][custom_contract_image]", this.state.contract);
    data.append("[contract][custom_contract]", "true");

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateContractCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_contract/contracts`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleSubmitTenantContractResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson.code === 200) {
        toast.success("Tenant created successfully");
        this.props.navigation.navigate("TenantList");
      } else {
        this.props.navigation.navigate("TenantList");
      }
    });
  };

  validationRegisterTenantFormSchema = Yup.object().shape({
    tenantType: Yup.string().required("Required").matches(/\S/, "Required"),
    tenantName: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .trim().matches(/^[A-Za-z\s\u0600-\u06FF]*$/,"Only characters are allowed in name"),
    tenantMobile: Yup.number()
      .required("Required")
      .positive("Negative numbers are not allowed.")
      .typeError("Only numbers are allowed.")
      .integer("Number can't contain a decimal."),
    building: Yup.string().required("Required").matches(/\S/, "Required"),
    unit: Yup.string().required("Required").matches(/\S/, "Required"),
    idType: Yup.string().required("Required").matches(/\S/, "Required"),
    idNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    idDate: Yup.string().required("Required").matches(/\S/, "Required"),
  });

  niceBytes = (x: any) => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0;
    let n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  fileUrlToDataURL = (url: any) =>
    fetch(url)
      .then((response: any) => response.blob())
      .then(
        (blob: any) =>
          new Promise((resolve: any, reject: any) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  dataURLtoFileObject = (dataurl: any, fileName: any) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  handleChangePage = () => {
    this.setState({
      isRegisterTenantOpen: !this.state.isRegisterTenantOpen,
    });
  };

  checkPhoneValidation = (value: any) => {
    let mobileRegex = /^5\d+$/;

    if (value.tenantMobile.includes("+")) {
      this.setState({ error: "Please enter valid mobile number", showError: true });
      return false;
    } else if (value.tenantCountryCode == "+966" || value.tenantCountryCode == "+971") {
      if (!mobileRegex.test(value.tenantMobile)) {
        this.setState({ error: "Please enter valid mobile number", showError: true });
        return false;
      } else {
        if (value.tenantMobile.length === 9) {
          return true;
        } else {
          this.setState({ error: "Please enter valid mobile number", showError: true });
          return false;
        }
      }
    } else {
      if (value.tenantMobile.length === 10) {
        return true;
      } else {
        this.setState({ error: "Please enter valid mobile number", showError: true });
        return false;
      }
    }
  };

  handleCompressFileValidation = (e: any, existFile: any) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];

    let totalFile = [...existFile, ...e.target.files];
    [...totalFile].forEach((file: any) => {
      if (!allowedTypes.includes(file.type)) {
        this.setState({ showError: true, error: "Please enter valid file!" });
        e.target.value = null;
        return false;
      }
    });

    let size = 0;
    [...totalFile].forEach((file: any) => {
      size += file.size;
    });
    size = size / (1024 * 1024);
    if (size > 30) {
      this.setState({ showError: true, error: "More then 30MB of files size not allowed!" });
      e.target.value = null;
      return false;
    }
    return true;
  };
  

  handleSubmitAddTenant = (values: any) => {
    this.setState(
      {
        registerTenantForm: {
          tenantType: values.tenantType,
          tenantName: values.tenantName,
          tenantCountryCode: values.tenantCountryCode,
          tenantMobile: values.tenantMobile,
          tenantEmail: values.tenantEmail,
          complex: values.complex,
          building: values.building,
          unit: values.unit,
          idType: values.idType,
          idNumber: values.idNumber,
          idDate: values.idDate,
          idCard: values.idCard,
          otherDocument: values.otherDocument,
        },
      },
      () => {
        if (this.checkPhoneValidation(values)) {
          this.handleChangePage();
        }
      }
    );
  };

  handleMobileSelectClass = (value: any, is_mobile: any) => {
    if (!value && is_mobile) {
      return "select-input input placeHolderForDate";
    } else {
      return "select-input input";
    }
  };
  // Customizable Area End
}
