// Customizable Area Start
import React, { useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { languageCondition } from "../ConditionManager.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const FilterSelect = ({ t, language, label, option, value, ...props }: any) => {
  const [title, setTitle] = useState(label);

  return (
    <Select
      value={value || ""}
      displayEmpty
      IconComponent={() => <ExpandMoreIcon className="select-icon" />}
      className="select-year"
      title={title}
      style={value !== "" ? { color: "black" } : {}}
      {...props}
    >
      <MenuItem disabled className={languageCondition(language, "select-arabic-menu", "")} value="">
        {t(label)}
      </MenuItem>
      {option &&
        option.map((item: any) => {
          return (
            <MenuItem
              className={languageCondition(language, "select-arabic-menu", "")}
              value={item.value}
              key={item.value}
              onClick={() => setTitle(item.label)}
            >
              {t(item.label)}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default FilterSelect;
// Customizable Area End
