// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Dialog,
  DialogContent,
  InputLabel,
  DialogActions,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { Formik } from "formik";
import CompEmpComplexBuildingBlockStep3Controller, {
  Props,
  StyleObject,
  UnitDetails,
} from "./CompEmpComplexBuildingBlockStep3Controller.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { withTranslation } from "react-i18next";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import SearchIcon from "@material-ui/icons/Search";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";

class CompEmpComplexBuildingBlockStep3 extends CompEmpComplexBuildingBlockStep3Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box>
          <Box className="complex complex_building_block_step3">
            <Typography className="complex_building_block_step3" style={webStyle.heading}>
              {t("Complex")}
            </Typography>
          </Box>
          <Box>
            <Box
              className="complex_building_block_step3"
              style={{ justifyContent: "space-between", display: "flex", alignItems: "center", paddingTop: "25px" }}
            >
              <Box className="complex_building_block_step3">
                <Typography className="Units bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
                  {t("Units")}
                </Typography>
              </Box>
              <Box className="complex_building_block_step3">
                <Typography className="stepUnits complex_building_block_step3" style={{ fontSize: "16px" }}>
                  {("Step")} {this.props.step + 1} {("of")} 5
                </Typography>
              </Box>
            </Box>
            <Box style={webStyle.tableContainer} className="complex_building_block_step3">
              <TableContainer className="complex_building_block_step3 table-box-card" style={{ boxShadow: "none" }}>
                <Table>
                  <TableHead>
                    <TableRow className="complex_building_block_step3">
                      <TableCell className="# complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("#")}
                      </TableCell>
                      <TableCell className="Unit complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("Unit Number")}
                      </TableCell>
                      <TableCell className="Floor complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("Floor Number")}
                      </TableCell>
                      <TableCell className="Config complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("Configuration")}
                      </TableCell>
                      <TableCell className="Resident complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("Resident Name")}
                      </TableCell>
                      <TableCell className="Owner complex_building_block_step3" style={{ fontWeight: "bold" }}>
                        {t("Owner")}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.allUnitList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={7}>{t("No unit found")}</TableCell>
                      </TableRow>
                    )}
                    {this.state.allUnitList.map((unit: UnitDetails, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="# step3Formik">{index + 1}</TableCell>
                          <TableCell className="Unit step3Formik">{unit.attributes.apartment_name}</TableCell>
                          <TableCell className="Floor step3Formik">{unit.attributes.floor_number}</TableCell>
                          <TableCell className="Config step3Formik">{unit.attributes.configuration}</TableCell>
                          <TableCell className="Resident step3Formik">{unit.attributes.resident?.full_name}</TableCell>
                          <TableCell className="Owner step3Formik">{unit.attributes.owner?.full_name}</TableCell>
                          <TableCell className="complex-unit-menu">
                            <Menu menuButton={<MoreVertIcon />}>
                              <MenuItem className="view-unit-menu" onClick={() => this.handleViewUnit(unit)}>
                                {t("View Detail")}
                              </MenuItem>
                              <MenuItem className="edit-unit-menu" onClick={() => this.handleEditUnit(unit)}>
                                {t("Edit Detail")}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Divider />
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{this.state.allUnitList.length}</span> {t("of")}{" "}
                    <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span>{" "}
                    {t("results")}
                  </p>
                  {this.state.pagination && (
                    <Pagination
                      onChange={(event: any, value: any) => this.setState({ page: Number(value) })}
                      count={this.state.pagination.total_pages}
                      page={this.state.pagination.current_page}
                      siblingCount={2}
                      variant="outlined"
                      shape="rounded"
                    />
                  )}
                </Box>
              </TableContainer>
              <Box
                style={{ borderRadius: "8px", marginTop: "20px", border: "1px solid #2B6FED" }}
                className="complex_building_block_step3"
              >
                <Box
                  style={{
                    color: "#2B6FED",
                    padding: "10px 25px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  className="step-3-add-unit-button bold-text"
                  onClick={this.handleCloseFormModal}
                >
                  {t("+ ADD NEW UNIT")}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "10px" }}>
          <Grid className="step-three-prev-btn" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("Prev")}</span>
            </Button>
          </Grid>
          <Grid className="step-three-next-btn" style={{ marginTop: "30px" }}>
            <Button style={webStyle.buttonSave} onClick={this.props.handleNext}>
              <span className="bold-text">{t("Next")}</span>
            </Button>
          </Grid>
        </Box>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isUnitFormOpen}
          scroll="paper"
          fullWidth
          className="add-family-dialog add-unit-form-dialog"
        >
          <DialogHeader t={t} title="Add New Unit" onClose={this.handleCloseFormModal} />
          <Formik
            enableReinitialize
            initialValues={this.state.formValue}
            validationSchema={this.validationSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({ isUnitFormOpen: !this.state.isUnitFormOpen }, () => {
                this.addUnits(values);
                resetForm();
              });
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="commonForms">
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        label={t("Unit Number")}
                        name="UnitNumber"
                        isLabel={true}
                        value={values.UnitNumber}
                        t={t}
                        onChange={handleChange}
                        type="text"
                        onBlur={handleBlur}
                      />
                      {ErrorHandler(t, touched.UnitNumber, errors.UnitNumber)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        language={language}
                        label={t("Floor Number")}
                        name="FloorNumber"
                        isLabel={true}
                        value={values.FloorNumber}
                        t={t}
                        onChange={handleChange}
                        type="text"
                        onBlur={handleBlur}
                      />
                      {ErrorHandler(t, touched.FloorNumber, errors.FloorNumber)}
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="input_icon__form_box">
                        <InputLabel>{t("Owner Name")}</InputLabel>
                        <Box
                          onClick={() => this.handleOpenOwnerModal("OwnerName")}
                          className="add-owner-dialog-btn"
                          style={webStyle.modalField}
                        >
                          <Typography style={webStyle.modalFieldTitle}>
                            {t("+ Add Owner")} {this.state.OwnerName && `(${this.state.OwnerName})`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="input_icon__form_box">
                        <InputLabel>{t("Resident Name (Optional)")}</InputLabel>
                        <Box
                          onClick={() => this.handleOpenOwnerModal("ResidentName")}
                          className="add-resident-dialog-btn"
                          style={webStyle.modalField}
                        >
                          <Typography style={webStyle.modalFieldTitle}>
                            {t("+ Add Resident")} {this.state.ResidentName && `(${this.state.ResidentName})`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        value={values.Configuration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="Configuration"
                        t={t}
                        language={language}
                        label={t("Configuration")}
                        option={this.state.configList}
                      />
                      {ErrorHandler(t, touched.Configuration, errors.Configuration)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.Size}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="Size"
                        t={t}
                        language={language}
                        label={t("Size")}
                        isLabel
                        endAdornment={
                          <InputAdornment position="end">
                            <Typography className="bold-text" style={webStyle.unitText}>
                              {t("Sqft")}
                            </Typography>
                          </InputAdornment>
                        }
                      />
                      {ErrorHandler(t, touched.Size, errors.Size)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.PurchasePrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="PurchasePrice"
                        t={t}
                        language={language}
                        label={t("Purchase Price (optional)")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.PurchasePrice, errors.PurchasePrice)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.PurchaseDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="PurchaseDate"
                        t={t}
                        language={language}
                        label={t("Purchase Date (optional)")}
                        onFocus={(e: React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                        isLabel
                        type="text"
                      />
                      {ErrorHandler(t, touched.PurchaseDate, errors.PurchaseDate)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label={t("Current Valuation (optional)")}
                        value={values.CurrentValuation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="CurrentValuation"
                        isLabel
                      />
                      {ErrorHandler(t, touched.CurrentValuation, errors.CurrentValuation)}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className="buttonCancel bold-text addAnotherBtn"
                        onClick={() =>
                          this.setState({ isUnitFormOpen: !this.state.isUnitFormOpen }, () => handleSubmit())
                        }
                      >
                        {t("+ Create Another Unit")}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={this.handleCloseFormModal}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("SAVE")}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isFindPersonOpen}
          scroll="paper"
          fullWidth
          className="find-person-building-dialog"
        >
          <DialogHeader t={t} title={t("Find Person")} onClose={this.handleClosePersonModal} />
          {!this.state.isUserDetailOpen && (
            <Box style={webStyle.subContent}>
              <Box style={{ display: "flex", alignItems: "flex-end", gap: "10px", justifyContent: "space-between" }}>
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("ID Number")}
                  isLabel
                  value={this.state.personSearch.id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ personSearch: { ...this.state.personSearch, id: e.target.value } })
                  }
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Passport Number")}
                  isLabel
                  value={this.state.personSearch.passport}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ personSearch: { ...this.state.personSearch, passport: e.target.value } })
                  }
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Email ID")}
                  isLabel
                  value={this.state.personSearch.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ personSearch: { ...this.state.personSearch, email: e.target.value } })
                  }
                />
                <NormalTextField
                  t={t}
                  language={language}
                  label={t("Phone Number")}
                  isLabel
                  value={this.state.personSearch.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ personSearch: { ...this.state.personSearch, phone: e.target.value } })
                  }
                />
                <CommonButton
                  onClick={this.getFindPersonList}
                  t={t}
                  label={t("Search")}
                  startIcon={<SearchIcon />}
                  color="blue"
                />
              </Box>
            </Box>
          )}
          {!this.state.isUserDetailOpen && this.state.userList.length > 0 && (
            <>
              <Divider />
              <Box style={webStyle.subContent} className="select-meeting">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Name")}</TableCell>
                        <TableCell>{t("ID Number")}s</TableCell>
                        <TableCell>{t("Phone Number")}</TableCell>
                        <TableCell>{t("Email")}</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.userList.map((user) => {
                        return (
                          <TableRow key={user.id}>
                            <TableCell>{t(user.attributes.full_name)}</TableCell>
                            <TableCell>{t(user.id + "")}</TableCell>
                            <TableCell>{t(user.attributes.full_phone_number)}</TableCell>
                            <TableCell>{t(user.attributes.email)}</TableCell>
                            <TableCell>
                              <Menu menuButton={<MoreVertIcon />}>
                                <MenuItem onClick={() => this.getFindPersonDetail(user.id)}>
                                  {t("View Detail")}
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
          {this.state.isUserDetailOpen && (
            <Box style={webStyle.subContent} className="select-meeting">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body2">{t("Name")}</Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {this.state.userDetail.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t("Complex")}</Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {this.state.userDetail.complex}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t("ID Number")}</Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {this.state.userDetail.idNumber}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t("Local ID")}</Typography>
                  <img src={this.state.userDetail.idUrl} />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{t("Email")}</Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {this.state.userDetail.email}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{t("Address")}</Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {this.state.userDetail.address}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <DialogActions className="dialog__button_group">
            <Button className="cancel-button" onClick={this.handleClosePersonModal}>
              {t("Cancel")}
            </Button>
            {this.state.isUserDetailOpen && (
              <Button onClick={this.handleSelectUser} className="add-button">
                {t("Select")}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          open={this.state.isViewUnitOpen}
          scroll="paper"
          fullWidth
          className="add-family-dialog view-unit-dialog"
        >
          <DialogHeader t={t} title="View Unit" onClose={this.handleCloseViewUnitModal} />
          <Box style={webStyle.subContent} className="select-meeting">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Unit Number")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.UnitNumber}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Floor Number")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.FloorNumber}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Size")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.Size}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Configuration")}</Typography>
                <img src={this.state.unitDetails.Configuration} />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Purchase Price")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.PurchasePrice}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Purchase Date")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.PurchaseDate}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{t("Current Valuation")}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {this.state.unitDetails.CurrentValuation}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(CompEmpComplexBuildingBlockStep3);

const webStyle: StyleObject = {
  heading: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "20px",
    paddingBottom: "25px",
    fontWeight: "bold",
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  subContent: { padding: "20px 30px" },
  unitText: { color: "rgb(140, 140, 140)" },
  modalField: {
    border: "1px solid rgb(252, 132, 52)",
    padding: "12px 15px",
    marginTop: "8px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  buttonSave: {
    width: "177px",
    textAlign: "center",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    height: "50px",
    textTransform: "uppercase",
    padding: "0px 20.5px",
  },
  prevButton: {
    color: "#2B6FED",
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: "0px 20.5px",
    height: "50px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    width: "177px",
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  modalFieldTitle: { color: "rgb(252, 132, 52)", fontSize: "14px !important" },
};
// Customizable Area End
