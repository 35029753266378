// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import * as Yup from "yup";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";

export interface Props {
  complexBuildingId: any;
  buildingId: any;
  handleBack: any;
  handleNext: any;
  jurisdictionSelected: any;
  step: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  isViewIBuildingUnitOpen: boolean;
  isIBuildingUnitFormOpen: boolean;
  isIBuildingFindPersonOpen: boolean;
  isUserSelected: boolean;

  IBuildingUnitList: any[];
  pagination: any;
  page: number;

  unitDetails: FormValue;

  addEditFormValue: FormValue;
  configList: any[];
  ownerName: string;
  residentName: string;
  selectedType: string;
  ownerId: string;
  residentId: string;

  person: IPerson;
  ownerResidentList: any[];
  userDetail: IUserDetail;

  isEditUnitFormOpen: boolean;
  unitId: string;
}

interface IUserDetail {
  idUrl: string;
  complex: string;
  idNumber: string | number;
  email: string;
  name: string;
  address: string;
}

interface IPerson {
  id: string;
  passport: string;
  email: string;
  phone: string;
}

interface FormValue {
  unit: string;
  floor: string;
  config: string;
  size: string;
  purchasePrice: string;
  purchaseDate: string;
  valuation: string;
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockStep5Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  AddIBuildingUnitsApiCallId: string = "";
  EditIBuildingUnitsApiCallId: string = "";
  GetIBuildingUserListCallId: string = "";
  GetFindUserDetailCallId: string = "";
  GetIBuildingConfigListCallId: string = "";
  GetIBuildingUnitListCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isViewIBuildingUnitOpen: false,
      isIBuildingUnitFormOpen: false,
      isIBuildingFindPersonOpen: false,
      isUserSelected: false,

      IBuildingUnitList: [],
      pagination: null,
      page: 1,

      unitDetails: {
        unit: "",
        floor: "",
        config: "",
        size: "",
        purchasePrice: "",
        purchaseDate: "",
        valuation: "",
      },

      addEditFormValue: {
        unit: "",
        floor: "",
        config: "",
        size: "",
        purchasePrice: "",
        purchaseDate: "",
        valuation: "",
      },

      configList: [],
      ownerName: "",
      residentName: "",
      ownerId: "",
      residentId: "",
      selectedType: "",

      person: {
        id: "",
        passport: "",
        email: "",
        phone: "",
      },
      ownerResidentList: [],
      userDetail: {
        idUrl: "",
        name: "",
        idNumber: "",
        email: "",
        address: "",
        complex: "",
      },

      isEditUnitFormOpen: false,
      unitId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.EditIBuildingUnitsApiCallId:
        case this.AddIBuildingUnitsApiCallId:
          return this.handleAddEditUnitResponse(responseJsonBlock);
        case this.GetIBuildingUserListCallId:
          return this.handleGetFindUserListResponse(responseJsonBlock);
        case this.GetFindUserDetailCallId:
          return this.handleGetFindUserDetailResponse(responseJsonBlock);
        case this.GetIBuildingConfigListCallId:
          return this.handleIBuildingConfigListResponse(responseJsonBlock);
        case this.GetIBuildingUnitListCallId:
          return this.handleIBuildingUnitListResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.getIBuildingConfigList();
    this.getIBuildingUnitList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getIBuildingUnitList();
    }
  }

  getIBuildingConfigList = async () => {
    this.GetIBuildingConfigListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/unit_configuration`,
    });
  };

  handleIBuildingConfigListResponse = (responseJson: { configuration: any[] }) => {
    if (responseJson && responseJson.configuration) {
      this.setState({
        configList: responseJson.configuration.map((config) => ({ label: config.title, value: config.id + "" })),
      });
    }
  };

  getIBuildingUnitList = async () => {
    this.GetIBuildingUnitListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/apartment_list?building_management_id=${this.props.buildingId}&page=${this.state.page}`,
    });
  };

  handleIBuildingUnitListResponse = (responseJson: {
    apartment_managements: { data: any[] };
    meta: { pagination: null };
  }) => {
    if (responseJson && responseJson.apartment_managements) {
      this.setState({
        IBuildingUnitList: responseJson.apartment_managements.data,
        pagination: responseJson.meta.pagination,
      });
    }
  };

  handleViewUnitModal = () => {
    this.setState({ isViewIBuildingUnitOpen: !this.state.isViewIBuildingUnitOpen });
  };

  handleCloseAddEditFormModal = () => {
    this.setState({ isIBuildingUnitFormOpen: !this.state.isIBuildingUnitFormOpen });
  };

  handleViewUnitOpenModal = (unitDetail: any) => {
    this.setState({
      isViewIBuildingUnitOpen: true,
      unitDetails: {
        unit: unitDetail.attributes.apartment_name,
        floor: unitDetail.attributes.floor_number,
        config: unitDetail.attributes.configuration,
        size: unitDetail.attributes.size,
        purchasePrice: unitDetail.attributes.purchase_price,
        purchaseDate: unitDetail.attributes.purchase_date,
        valuation: unitDetail.attributes.current_valuation,
      },
    });
  };

  validationSchema = Yup.object().shape({
    unit: Yup.string().required("Unit Number is required"),
    floor: Yup.string().required("Floor Number is required"),
    config: Yup.string().required("Configuration is required"),
    size: Yup.string().required("Size is required"),
  });

  handleOpenOwnerResidentModal = (type: string) => {
    this.setState(
      {
        selectedType: type,
        isUserSelected: false,
        person: {
          email: "",
          passport: "",
          id: "",
          phone: "",
        },
        ownerResidentList: [],
      },
      () => {
        this.handleFindPersonModal();
      }
    );
  };

  handleFindPersonModal = () => {
    this.setState({ isIBuildingFindPersonOpen: !this.state.isIBuildingFindPersonOpen });
  };

  getFindUserList = async () => {
    const { id, email, passport, phone } = this.state.person;
    const role = this.state.selectedType === "ownerName" ? "Owner" : "Tenant";
    this.GetIBuildingUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${this.props.complexBuildingId}/bx_block_contract/employee_contracts/find_person?role=${role}&id_number=${id}&phone_number=${phone}&email=${email}&passport=${passport}`,
    });
  };

  handleGetFindUserListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ownerResidentList: responseJson.data });
    }
  };

  getFindUserDetail = (id: string | number) => {
    this.setState({ isUserSelected: true }, async () => {
      this.GetFindUserDetailCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `society_managements/${this.props.complexBuildingId}/bx_block_contract/employee_contracts/find_person_details?id=${id}`,
      });
    });
  };

  handleGetFindUserDetailResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const user = responseJson.data;
      this.setState({
        userDetail: {
          idUrl: "",
          name: user.attributes.full_name,
          idNumber: user.id,
          email: user.attributes.email,
          address: user.attributes.address?.address || "",
          complex: user.attributes.complex?.name || "",
        },
      });
    }
  };

  handleSelectUser = () => {
    if (this.state.selectedType !== "") {
      const newState = { isIBuildingFindPersonOpen: false, [this.state.selectedType]: this.state.userDetail.name + "" };
      if (this.state.selectedType === "ownerName") {
        newState["ownerId"] = this.state.userDetail.idNumber + "";
      } else {
        newState["residentId"] = this.state.userDetail.idNumber + "";
      }
      this.setState({ ...this.state, ...newState });
    }
  };

  addEditUnitFormSubmit = async (unit: FormValue) => {
    const formData = new FormData();
    formData.append(`apartment_management[floor_number]`, unit.floor);
    formData.append(`apartment_management[configuration]`, unit.config);
    formData.append(`apartment_management[size]`, unit.size);
    formData.append(`apartment_management[purchase_price]`, unit.purchasePrice);
    formData.append(`apartment_management[purchase_date]`, unit.purchaseDate);
    formData.append(`apartment_management[current_valuation]`, unit.valuation);
    formData.append("apartment_management[resident_id]", this.state.residentId + "");
    formData.append("apartment_management[owner_id]", this.state.ownerId);
    formData.append("apartment_management[resident_name]", this.state.residentName + "");
    formData.append("apartment_management[owner_name]", this.state.ownerName);
    formData.append("apartment_management[apartment_name]", unit.unit);
    formData.append("apartment_management[building_management_id]", this.props.buildingId + "");
    formData.append("apartment_management[society_management_id]", this.props.complexBuildingId + "");

    if (this.state.isEditUnitFormOpen) {
      this.EditIBuildingUnitsApiCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_settings/apartment_managements/${this.state.unitId}`,
        body: formData,
      });
    } else {
      this.AddIBuildingUnitsApiCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_settings/apartment_managements`,
        body: formData,
      });
    }
  };

  handleAddEditUnitResponse = (responseJson: any) => {
    this.setState(
      {
        isEditUnitFormOpen: false,
        isUserSelected: false,
        loading: false,
        person: { id: "", passport: "", email: "", phone: "" },
        ownerResidentList: [],
        selectedType: "",
        ownerName: "",
        residentName: "",
        ownerId: "",
        residentId: "",
      },
      () => {
        if (responseJson && responseJson.data) {
          this.getIBuildingUnitList();
        }
      }
    );
  };

  handleEditUnitOpenModal = (unitDetail: any) => {
    this.setState({
      isIBuildingUnitFormOpen: true,
      isEditUnitFormOpen: true,
      unitId: unitDetail.id + "",
      ownerId: unitDetail.attributes.owner?.id + "",
      residentId: unitDetail.attributes.resident?.id + "",
      ownerName: unitDetail.attributes.owner?.full_name + "",
      residentName: unitDetail.attributes.resident + "",
      addEditFormValue: {
        unit: unitDetail.attributes.apartment_name,
        floor: unitDetail.attributes.floor_number,
        config: unitDetail.attributes.configuration,
        size: unitDetail.attributes.size,
        purchasePrice: unitDetail.attributes.purchase_price,
        purchaseDate: unitDetail.attributes.purchase_date,
        valuation: unitDetail.attributes.current_valuation,
      },
    });
  };

  handlePagination = (event: any, value: any) => this.setState({ page: Number(value) });
}

// Customizable Area End
