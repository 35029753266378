// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography, Dialog, DialogContent, DialogActions, InputAdornment } from "@material-ui/core";
import CompEmpComplexBlockStep5Controller, { Props } from "./CompEmpComplexBlockStep5Controller.web";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import "../../assets/commonForm.css";
import Loader from "../../../../components/src/Loader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { CheckIcon, del_image, upload } from "../assets";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

export default class CompEmpComplexBlockStep5 extends CompEmpComplexBlockStep5Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box className="topBarFormik">
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="Buildings bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Buildings")}
            </Typography>
            <Typography className="stepBuildings" style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of 7")}
            </Typography>
          </Box>
          <Grid container spacing={4} style={{ marginTop: "15px" }}>
            {this.state.buildingList.map((building: any) => {
              return (
                <Grid item xs={4} key={building.id}>
                  <Box style={webStyle.buildingBox}>
                    <span
                      onClick={() => this.setState({ loading: true }, () => this.handleEditBuildingOpen(building))}
                      className="bold-text"
                      style={webStyle.buildingTitle}
                      data-test-id="complex-building-name"
                    >
                      {building.attributes.name}
                    </span>
                    <CloseIcon
                      onClick={() => this.deleteBuilding(building.id)}
                      data-test-id="delete-building"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box style={webStyle.addBuildingContainer}>
            <Box style={webStyle.addBuildingBox}>
              <Box
                style={webStyle.addBuildingBtn}
                data-test-id="add-building-btn"
                onClick={this.handleBuildingModal}
                className="bold-text"
              >
                {t("+ ADD NEW BUILDING")}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="step5BtnBox" style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
          <Grid className="step5Grid" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button className="step5Prev" style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("PREV")}</span>
            </Button>
          </Grid>
          <Box>
            <Grid className="step5Grid" style={{ marginTop: "30px" }}>
              <Button className="step5Next" style={webStyle.nextButton} onClick={this.props.handleNext}>
                <span className="bold-text">{t("NEXT")}</span>
              </Button>
            </Grid>
          </Box>
        </Box>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isAddBuildingModalOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title="Add New Building" onClose={this.handleBuildingModal} />
          <Formik
            initialValues={this.state.buildingFormValue}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            data-test-id="create-building-form"
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isAddBuildingModalOpen: false }, () => {
                this.handleSubmitBuilding(values);
                resetForm();
              });
            }}
          >
            {({ values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form translate="yes" onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Box style={webStyle.logoBox}>
                      {values.logo ? (
                        <img
                          src={typeof values.logo === "string" ? values.logo : URL.createObjectURL(values.logo)}
                          alt="Preview"
                          style={webStyle.logoDisplay}
                        />
                      ) : (
                        <Box style={webStyle.logoDisplay} />
                      )}
                      <label htmlFor="logoChange" style={{ marginTop: "12px" }}>
                        <span style={{ color: "#FC8434" }} className="bold-text">
                          {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                        </span>
                      </label>
                      <input
                        name="logo"
                        type="file"
                        id="logoChange"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const files = event.target.files;
                          if (files) {
                            const file = files[0];
                            setFieldValue("logo", file);
                          }
                        }}
                        accept="image/*"
                        data-test-id="logo-upload"
                        style={{ display: "none" }}
                      />
                      {ErrorHandler(t, touched.logo, errors.logo)}
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label htmlFor="uploadPhotos" style={{ marginBottom: "8px", color: "black", fontSize: "14px" }}>
                          {t("Upload Photos")}
                        </label>
                        <Grid container spacing={4}>
                          <Grid item xs={3} style={{ marginBottom: "5px" }} className="edit-building">
                            <label htmlFor="building-images" style={{ marginTop: "12px" }}>
                              <Box className="upload-photo">
                                <img src={upload} alt="edit-complex-upload-icon" />
                              </Box>
                            </label>
                            <input
                              id="building-images"
                              multiple
                              style={{ display: "none" }}
                              className="building-details-image"
                              name="photos"
                              onBlur={handleBlur}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files = e.target.files;
                                if (files) {
                                  setFieldValue("images", [...values.images, ...files]);
                                }
                              }}
                              data-test-id="building-upload"
                              type="file"
                              accept="image/*"
                            />
                          </Grid>
                          {[...values.images].map((image: any, index: number) => {
                            return (
                              <Grid item xs={3} key={index}>
                                <Box className="building-image" style={{ position: "relative" }}>
                                  <img
                                    style={webStyle.imageBox}
                                    className="delete-image"
                                    src={del_image}
                                    onClick={() => {
                                      const remainImage = [...values.images].filter(
                                        (img: File, idx: number) => idx !== index
                                      );
                                      setFieldValue("images", remainImage);
                                    }}
                                    data-test-id="remove-building-upload"
                                  />
                                  <img
                                    src={typeof image === "string" ? image : URL.createObjectURL(image)}
                                    style={{ height: "124px", width: "100%", borderRadius: "8px" }}
                                  />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {ErrorHandler(t, touched.images, errors.images)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextArea
                          name="about"
                          t={t}
                          isLabel={true}
                          onBlur={handleBlur}
                          label={t("About the building")}
                          value={values.about}
                          onChange={handleChange}
                          language={language}
                        />
                        {ErrorHandler(t, touched.about, errors.about)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          name="name"
                          language={language}
                          value={values.name}
                          t={t}
                          isLabel
                          label={t("Building Name")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          label="Country"
                          value={values.country}
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          option={this.state.countriesList}
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          name="area"
                          language={language}
                          value={values.area}
                          t={t}
                          isLabel
                          label={t("Building Area")}
                          onChange={handleChange}
                          type="number"
                          onBlur={handleBlur}
                          endAdornment={
                            <InputAdornment position="end">
                              <Typography className="bold-text" style={webStyle.unitText}>
                                {t("Sqft")}
                              </Typography>
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.area, errors.area)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          name="floor"
                          language={language}
                          value={values.floor}
                          t={t}
                          isLabel
                          label={t("Total Floors")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                        />
                        {ErrorHandler(t, touched.floor, errors.floor)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextField
                          name="location"
                          language={language}
                          value={values.location}
                          t={t}
                          isLabel
                          label={t("Google Map Location")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {ErrorHandler(t, touched.location, errors.location)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button onClick={this.handleBuildingModal} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" style={{ margin: "0" }} className="add-button">
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertDialog
          IsOpen={this.state.isDetailModalOpen}
          Image={CheckIcon}
          Header={t("New Building Added")}
          Content={`<p>${t(
            "New building with basic information has been added to the complex. Do you want to complete the details of the building now, or later?"
          )}</p>`}
          DeclineText={t("Now")}
          AcceptText={t("Later")}
          DeclineFun={this.handleBuildingNow}
          AcceptFun={this.handleDetailsModalClose}
          language={language}
          data-test-id="new-building-modal"
        />
      </>
    );
  }
}

const webStyle: any = {
  prevButton: {
    color: "#2B6FED",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
    width: "177px",
    height: "56px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  unitText: { color: "rgb(140, 140, 140)" },
  nextButton: {
    width: "177px",
    height: "56px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
  },
  buildingTitle: {
    color: "#FC8434",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  buildingBox: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E4E4",
    borderRadius: "12px",
    padding: "15px",
  },
  logoDisplay: {
    width: "66px",
    height: "66px",
    borderRadius: "50%",
    background: "#dcdcdc",
  },
  addBuildingBtn: {
    color: "#2B6FED",
    fontSize: "15px",
    fontWeight: 400,
    padding: "10px 25px",
    cursor: "pointer",
  },
  addBuildingBox: {
    marginTop: "20px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
  },
  addBuildingContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
  },
  logoBox: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  imageBox: {
    position: "absolute",
    cursor: "pointer",
    right: "5px",
    top: "5px",
    width: "35px",
    height: "35px",
  },
};

// Customizable Area End
