import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18nInterface;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    salesManager: string;
    sortBy: string;
    searchText: string;
    salesManagerDataSave: Array<SalesManagerListItem>;
    anchorEl: HTMLButtonElement | null;
    paginationData: PaginationData | null;
    page: string;
    showError: boolean;
    showErrorMessage: string;
    salesManagerId: string;
    loader: boolean;
    // Customizable Area End
}
// Customizable Area Start
export interface SalesManagerListItem {
    id: string;
    type: string;
    attributes: {
        id: number;
        sales_manager_name: string;
        account_manager: string | null;
        assigend_complexes: number;
        assigend_buildings: number;
        assigend_owners: number;
        assigend_property_developers: number;
    }
};

export type TFunction = (value: string) => string;

export interface I18nInterface {
    language: string;
}

export interface PaginationData {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface AssingedSalesManagerResponse {
    data: Array<SalesManagerListItem>;
    meta: {
        pagination: PaginationData | null;
    }
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>;
};

export interface ApiFailureResponseError {
    contract: string;
};

// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SalesManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    salesManagerListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            salesManagerDataSave: [],
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },
            anchorEl: null,
            salesManager: "",
            sortBy: "",
            searchText: "",
            page: "",
            showError: false,
            showErrorMessage: "",
            salesManagerId: "",
            loader: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.salesManagerApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.salesManagerApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getAssignedSalesManagerList("1");
    };

    myTeamApiCall = async (apiPayloadType: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = apiPayloadType;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        body && type !== "formData"
            ? contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    salesManagerApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: AssingedSalesManagerResponse) => {
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListSucessCallBack(responseJson);
        }
    };

    salesManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
    };

    getAssignedSalesManagerList = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.assignedSalesManagerEndPoint}page=${page}`;
        let { salesManagerId, searchText, sortBy } = this.state;
        if (salesManagerId) {
            endPoint += `&sales_member_id=${salesManagerId}`;
        }
        if (searchText) {
            endPoint += `&q=${searchText}`;
        }
        if (sortBy) {
            endPoint += `&sort_by=${sortBy}`;
        }
        this.salesManagerListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    salesManagerListSucessCallBack = (response: AssingedSalesManagerResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ salesManagerDataSave: response.data, paginationData: response.meta.pagination, loader: false });
        } else {
            this.setState({
                salesManagerDataSave: response.data,
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0,
                },
                loader: false
            });
        }
    };

    salesManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    handleSalesManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.salesManagerDataSave.find((salesManager) => { return salesManager.attributes.sales_manager_name === event.target.value });
        if (salesManager) {
            this.setState({ salesManager: event.target.value as string, salesManagerId: salesManager.id });
        }
    };

    handleSortByChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string }, () => {
            this.getAssignedSalesManagerList("1")
        });
    };

    handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getAssignedSalesManagerList("1")
        });
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleNavigation = (path: string, salesManagerId: string) => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        msgData.addData(getName(MessageEnum.salesManagerId), salesManagerId);
        this.send(msgData);
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getAssignedSalesManagerList(String(value));
    };

    handleAlertError = () => {
        this.setState({ showError: false });
    };
    // Customizable Area End
}
