// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import * as Yup from "yup";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  step: number;
  complexId: null | string | undefined;
  buildingId: string | number | null;
  handleBack: () => void;
  handleNext: () => void;
}

export type StyleObject = {
  [key: string]: {
    [key: string]: string;
  };
};

interface FacilityValue {
  sharedFacility: string;
  Details: string;
  totalArea: string;
  fees: string;
  images: any;
  floorDocument: any;
}

export interface SharedDetails {
  id: string;
  attributes: {
    name: string;
  };
}

interface S {
  isFacilityOpen: boolean;

  facilityList: SharedDetails[];

  facilityValues: FacilityValue;
  editID: string;
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockStep6Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  uploadFile: any;
  uploadImage: any;
  AddSharedApiCallId: string = "";
  DeleteSharedApiCallId: string = "";
  GetSharedFacilityListCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      isFacilityOpen: false,

      facilityList: [],

      facilityValues: {
        sharedFacility: "",
        Details: "",
        totalArea: "",
        fees: "",
        images: [],
        floorDocument: null,
      },
      editID: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.AddSharedApiCallId:
          return this.handleSharedFacilitySuccess();
        case this.GetSharedFacilityListCallId:
          return this.handleSharedFacilityListResponse(responseJsonBlock);
        case this.DeleteSharedApiCallId:
          return this.handleDeleteFacilityResponse();
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.getSharedFacilityList();
  }

  getSharedFacilityList = async () => {
    this.GetSharedFacilityListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/common_area_list?society_management_id=${this.props.complexId}`,
    });
  };

  handleSharedFacilityListResponse = (response: { data: SharedDetails[] }) => {
    if (response && response.data) {
      this.setState({ facilityList: response.data });
    }
  };

  handleDeleteFacility = async (id: string) => {
    this.DeleteSharedApiCallId = await apiCall({
      method: "DELETE",
      endPoint: `bx_block_society_management/common_areas/${id}`,
    });
  };

  handleDeleteFacilityResponse = () => {
    this.getSharedFacilityList();
  };

  handleSubmitForm = (values: FacilityValue, resetForm: () => void) => {
    this.setState({ isFacilityOpen: false }, () => {
      if (this.state.editID) {
        this.editSharedFacility(values);
      } else {
        this.addSharedFacility(values);
      }
      resetForm();
    });
  }

  addSharedFacility = async (values: FacilityValue) => {
    const formData = new FormData();
    formData.append(`common_area[name]`, values.sharedFacility);
    formData.append("common_area[society_management_id]", this.props.complexId + "");
    formData.append(`common_area[details]`, values.Details);
    formData.append(`common_area[total_area]`, values.totalArea);
    formData.append(`common_area[reservation_fee]`, values.fees);
    formData.append(`common_area[floor_plan]`, values.floorDocument || "");

    [...values.images].forEach((photo: any) => {
      formData.append(`common_area[photos][]`, photo);
    });

    this.AddSharedApiCallId = await apiCall({
      body: formData,
      method: "POST",
      endPoint: `bx_block_society_management/common_areas`,
    });
  };

  handleEditSharedArea = async (facility: any) => {
    let photos: any[] = [];
    if (facility.attributes.photos.length > 0) {
      photos = await handleImages(facility.attributes.photos);
    }

    let floor: any = null;
    if (facility.attributes.floor_plan) {
      const floors = await handleImages([facility.attributes.floor_plan]);
      floor = floors[0];
    }

    this.setState({
      editID: facility.id,
      isFacilityOpen: true,
      facilityValues: {
        sharedFacility: facility.attributes.name,
        Details: facility.attributes.details,
        totalArea: facility.attributes.total_area,
        fees: facility.attributes.reservation_fee,
        images: photos,
        floorDocument: floor,
      },
    });
  };

  editSharedFacility = async (values: FacilityValue) => {
    const data = new FormData();
    data.append(`common_area[reservation_fee]`, values.fees);
    data.append(`common_area[total_area]`, values.totalArea);
    data.append(`common_area[floor_plan]`, values.floorDocument || "");
    data.append(`common_area[details]`, values.Details);

    [...values.images].forEach((photo: any) => {
      data.append(`common_area[photos][]`, photo);
    });

    this.AddSharedApiCallId = await apiCall({
      body: data,
      method: "PATCH",
      endPoint: `bx_block_society_management/common_areas/${this.state.editID}`,
    });
  }

  handleSharedFacilitySuccess = () => {
    this.getSharedFacilityList();
  };

  handleSharedFacilityDialog = () => {
    this.setState({ isFacilityOpen: !this.state.isFacilityOpen, editID: "" });
  };

  validationSchema = Yup.object().shape({
    Details: Yup.string().required("Details are required"),
    sharedFacility: Yup.string()
      .min(3, "Facility name must be at least 3 characters")
      .max(50, "Facility name must be at most 50 characters")
      .required("Facility Name is required"),
    totalArea: Yup.string().required("Total Area is required"),
    fees: Yup.string().required("Reservation fees is required"),
    floorDocument: Yup.mixed().required("Please upload a Floor Document File"),
    images: Yup.array().min(1, "At least one image is required").max(6, "Maximum of 6 images allowed").nullable(),
  });
}

// Customizable Area End
