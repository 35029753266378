// Customizable Area Start
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React from "react";
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography, Collapse } from "@material-ui/core";
import CESalesManSidebarController, {
  Props,
} from "./CESalesManSidebarController.web"

import ExpandMore from '@material-ui/icons/ExpandMore';
import { DashboardImageComponent } from "./CMSidebarImages/DashboardImage.web";
import { LeaseImageComponent } from "./CMSidebarImages/CELeaseImage.web";
import { CommunityImageComponent } from "./CMSidebarImages/CECommunityImage.web";
import { DocumentImageComponent } from "./CMSidebarImages/CEDocumentsImage.web";
import { HelpImageComponent } from "./CMSidebarImages/ManageFAQImage.web";
import { PollImageComponent } from "./CMSidebarImages/PollImage.web";
import { ManageSubImageComponent } from "./CMSidebarImages/ManageSubImage.web";
import { ClientTicketImageComponent } from "./CMSidebarImages/ClientTicketImage.web";
import { MyTeamImageComponent } from "./CMSidebarImages/CEMyTeamImage.web";
import { ComplexImageComponent } from "./CMSidebarImages/CEComplexImage.web";
import { NominationsImageComponent } from "./CMSidebarImages/NominationsImage.web";
import { ActivitiesImageComponent } from "./CMSidebarImages/ActivitiesImage.web";
import { Link } from 'react-router-dom';

export default class CESalesManSidebar extends CESalesManSidebarController {
  constructor(props: Props) {
    super(props);
  }

  checkColorSM = (key:any) => {
    return this.getTabData(key) ? "CESingleLinkSize" : "blackCESingleLinkSize"
  }
  render() {

    const colorMapp = {
      color: this.getColor2(this.getTabData('dashboard')),
      teamcolor: this.getColor2(this.getTabData('team')),
      Complexcolor2: this.getTabData('complex') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      Communitycolor2: this.state.opencommunityManagement ? "CESingleLinkSize" : "blackCESingleLinkSize",
      documentcolor2: this.getTabData('document') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      manageFAQcolor2: this.getTabData('faq') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      Pollcolor: this.getTabData('realEstate') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      nominationscolor: this.checkColorSM('nomination'),
      activitiescolor: this.getColor2(this.getTabData('activities')),
      leasecolor: this.getColor2(this.state.openleasemanagement),
      managesubcolor: this.getColor2(this.getTabData('subscription')),
      clientticketcolor: this.getColor2(this.state.openclientTicket),
      // ... Define other properties in a similar way
    };

    const getTypography2 = (text: any, isOpen: any) => (
      <Typography
        style={{
          ...webStyle.dashboard_sidebar,
          color: isOpen ? '#2B6FED' : 'inherit',
        }}
        className="login-h1 bold-text"
      >
        {text}
      </Typography>
    );

    const getIconStyle = (paths: string[]) => ({ color: this.isActiveMenu(paths) ? '#2b6fed' : '' })

    const communityItems1 = [
      ['User Profile','communityManagementProfile'],
      ['Request Management', 'RequestManagement', "PendingJoinRequest", "AwaitAccept"],

    ];
    const getTypographyClassName1 = (selected: boolean) => `b ${selected ? 'selected' : ''}`;
    const activityItems1 = [
      ['Incident Management', 'cooincidentListing'],
      ['Poll/Survey', "CEPoll", "CEPollSurvey", 'CEPollSurveyList', 'CEPollSurveyDetails', 'CEPollSurveyReports'],
      ['Scheduled Meetings', "CEScheduleMeeting"],
      ['Meeting Minutes', "CEMeetingMinutes"],
      ['Announcements', 'CompEmpAnnouncement']
    ];
    const dashboards1 = [
      ['General Dashboard', 'dashboard', "RegisteredOwner", "RegisteredResidents", "MyTeamDashboard", "OpenTicketsDashboard", "IndivisualBuildingDashboard", "SalesManagerDashboard", "PropertyManagerDashboard", "AccountManagerDashboard", "SalesManagerProfileDashboard", "TotalComplexesDashboard", "ManagedTeamDashboard", "RequiredActivity"],
      ['Actions Assigned to Me', "ActionAssignMe"],
      ['Actions Assigned to Team', "ActionAssignTeam"],
      ['Analysis Dashboard', 'CEDashboardAnalytics'],
      ['Approval Dashboard'],
      ['Granted Permissions', "DashboardsGranted"],
    ];
    const documentItems1 = [
      ['Document', 'CompEmpDocument'],
      ['Report','ReportsStatistics','LoginReports','InvitationReport', 'PropertyManagerReport', 'PropertyManagerReportDetails','SubscriptionReports']
      // Add more items here if needed
    ];

    return (
      <Box style={webStyle.container} >

        <List component="nav" disablePadding style={{ paddingLeft: '15px',marginTop:"25px" }}>

          <ListItem className="b" button onClick={this.handleeDashboardClick} data-test-id="listItem" style={webStyle.ListSpacing}>
            <ListItemIcon>

              <DashboardImageComponent opendashboard={colorMapp.color} />

            </ListItemIcon>
            <ListItemText primary={getTypography2("My Dashboards", this.getTabData('dashboard'))} />
            <p style={webStyle.RightPadding2} className="a">{this.state.opendashboard ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['Dashboard','jurisdication', 'ViewJurisdiction'])} />}</p>
          </ListItem>
          <Collapse className="a" in={this.state.opendashboard} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {dashboards1.map((dashboard, index) => (
                 <Link to={`/CompanyEmployee/${dashboard[1]}`}>
                  <ListItem
                    key={index}
                    data-test-id="collapse"
                    button
                    onClick={() => this.handleeItemClick(index)}
                  >
                    <ListItemText primary={<Typography style={{ fontWeight: 'bold',fontSize:'0.875rem' }} className={getTypographyClassName1(this.isActiveMenu(dashboard))}>{dashboard[0]}</Typography>} />
                  </ListItem>
                </Link>
              ))}

            </List>
          </Collapse>
          <Link to="/CompanyEmployee/CEMyTeam">
          <ListItem className="b" button onClick={this.handleeteamMember} data-test-id="listItem" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <MyTeamImageComponent openteammember={colorMapp.teamcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography2("Team Members", this.getTabData('team'))} />

          </ListItem>
          </Link>
          <Link to="/CompanyEmployee/complexlisting">
            <ListItem className="b" button onClick={this.handleecomplexBuilding} data-test-id="sidebar" style={webStyle.ListSpacing}>
              <ListItemIcon >
                <ComplexImageComponent opencomplexBuilding={colorMapp.Complexcolor2} />
              </ListItemIcon>
              <ListItemText primary={getTypography2("Complex/Building", this.getTabData('complex'))} />
            </ListItem>
          </Link>
          <Link to={'/CompanyEmployee/realestate-companies'}>
            <ListItem className="b" button onClick={this.handleerealEstateCompany} data-test-id="realEstateCompanies" style={webStyle.ListSpacing}>
              <ListItemIcon>
                <PollImageComponent openrealEstateCompanies={colorMapp.Pollcolor} />
              </ListItemIcon>
              <ListItemText primary={getTypography2("Real Estate Companies", this.getTabData('realEstate'))} />
            </ListItem>
          </Link>
          <ListItem className="b" button onClick={this.handleecommunity}
            data-test-id="communityManagement" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <CommunityImageComponent opencommunityManagement={colorMapp.Communitycolor2} />
            </ListItemIcon>
            <ListItemText primary={getTypography2("Community Management", this.state.opencommunityManagement)} />
            <p style={webStyle.RightPadding2} className="a">{this.state.opencommunityManagement ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle([])} />}</p>
          </ListItem>
          <Collapse className="a" in={this.state.opencommunityManagement} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {communityItems1.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`}>
                <ListItem
                  data-test-id="collapse"
                  key={index}
                  onClick={() => this.handleeItemClick(index)}
                  button
                >
                  <ListItemText primary={<Typography style={{ fontWeight: 'bold',fontSize:'0.875rem' }} className={getTypographyClassName1(this.isActiveMenu(dashboard))}>{dashboard[0]}</Typography>} />
                </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <ListItem className="b" button onClick={this.handleeactivities} data-test-id="activities" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ActivitiesImageComponent openactivities={colorMapp.activitiescolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography2("Activities", this.getTabData('activities'))} />
            <p style={webStyle.RightPadding2} className="a">{this.state.openactivities ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['cooincidentListing','CompEmpAnnouncement', 'cooincident-details', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList','CEPollSurveyDetails', 'CEPollSurveyReports'])} />}</p>
          </ListItem>
          <Collapse className="a" in={this.state.openactivities} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {activityItems1.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`} >
                  <ListItem
                    key={index}
                    button
                    onClick={() => this.handleeItemClick(index)}
                    data-test-id="collapse"
                  >
                    <ListItemText primary={<Typography className={getTypographyClassName1(this.isActiveMenu(dashboard))} style={{ fontWeight: 'bold' }}>{dashboard[0]}</Typography>} />
                  </ListItem>
                </Link>
              ))}

            </List>
          </Collapse>
          <ListItem className="b" button onClick={this.handleedocumentReport} data-test-id="documents" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <DocumentImageComponent opendocuments={colorMapp.documentcolor2} />
            </ListItemIcon>
            <ListItemText primary={getTypography2("Documents & Reports", this.getTabData('document'))} />
            <p style={webStyle.RightPadding2} className="a">{this.state.opendocuments ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['CompEmpDocument'])} />}</p>
          </ListItem>
          <Collapse className="a" in={this.state.opendocuments} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {documentItems1.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`}>
                  <ListItem
                    data-test-id="collapse"
                    button
                    onClick={() => this.handleeItemClick(index)}
                    key={index}
                  >
                    <ListItemText primary={<Typography style={{ fontWeight: 'bold',fontSize:'0.875rem' }} className={getTypographyClassName1(this.isActiveMenu(dashboard))}>{dashboard[0]}</Typography>} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <Link to="CompEmpFaq">
            <ListItem className="b" button onClick={this.handleemanageFAQ} data-test-id="manageFAQ" style={webStyle.ListSpacing}>
              <ListItemIcon>
                <HelpImageComponent openmanageFAQ={colorMapp.manageFAQcolor2} />
              </ListItemIcon>
              <ListItemText primary={getTypography2("Manage FAQ", this.getTabData('faq'))} />
            </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/CENomination"}>
            <ListItem className="b" button onClick={this.handleenominations} data-test-id="nominations" style={webStyle.ListSpacing}>
              <ListItemIcon>
                <NominationsImageComponent opennominations={colorMapp.nominationscolor} />
              </ListItemIcon>
              <ListItemText primary={getTypography2("Nominations", this.state.opennominations)} />
            </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/LeaseManagment"}>
          <ListItem className="b" button onClick={this.handleelease} data-test-id="leasemanagement" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <LeaseImageComponent openleasemanagement={colorMapp.leasecolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography2(" Lease Management", this.state.openleasemanagement)} />
          </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/SubsciptionListing"}>
          <ListItem className="b" button onClick={this.handleemanagesub} data-test-id="manageSub" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ManageSubImageComponent openmanageSub={colorMapp.managesubcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography2(" Manage Subscriptions", this.getTabData('subscription'))} />
          </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/ClientTicket"}>
          <ListItem className="b" button onClick={this.handleeclient} style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ClientTicketImageComponent openclientTicket={colorMapp.clientticketcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography2("Client Tickets", this.state.openclientTicket)} />
          </ListItem>
          </Link>
        </List>
      </Box>

    );
  }
}

const webStyle = {
  container: {
    width: "100%", height: "100%",
    overflow: 'hidden',
    backgroundColor:"#fcfcfc"
  },
  dashboard_sidebar: {
    fontSize: "16px",

  },

  ListSpacing: {
    marginBottom: '10px'
  },
  RightPadding2: {
    paddingRight: '25px'
  }
};
// Customizable Area End


